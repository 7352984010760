const cartPage = {
  page_title: 'My Download Basket - WerbeNet',
  title: 'Download basket',
  select: 'Select',
  deselect: 'Deselect',
  zoom_image: 'Enlarge image',
  tabel: {
    selectAll: 'Select All',
    select: 'Select',
    assetNumber: 'AS-Number',
    name: 'Name',
    keyWords: 'Key Words',
    endofUsePeriod: 'End of use period',
    noAssets: 'No assets for the selected category',
    detailsPage: 'Details page',
  },
  overview: {
    partnernet: {
      id: 'partnernet',
      label: 'Partnernet',
    },
    magenta_smart: {
      id: 'magenta_smart',
      label: 'Magenta SmartHome',
    },
  },
  links: {
    delete: {
      link: '#link1',
      label: 'Delete from download basket',
    },
    download_link: {
      link: '#link2',
      label: 'Send download link via email',
    },
    download_low_resolution: {
      link: '#link3',
      label: 'Download in low resolution',
    },
  },
  download: 'Download',
  no_assets: 'There are no assets in the shopping cart',
  progressBar: {
    download_is_finished: 'Download is finished',
    download_failed: 'Download Failed',
  },
} as const;

export default cartPage;
