import React, { useEffect, useState } from 'react';
import { client } from 'Config';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import Trans from 'Components/Trans';
import { useT } from './translations';
import { BreadCrumb, Page, Spinner } from 'Components';
import { Helmet } from 'react-helmet';
import tw from 'twin.macro';
// import { formatISO } from 'date-fns';
import { useQueryParams } from 'Helpers';

export default function ApproveUserPage({ isLoggedIn = true }) {
  const t = useT();
  const toastManager = useToasts();
  const uuid = useQueryParams().get('request_uuid');
  const [loading, setLoading] = useState(true);
  const [sendingReply, setSendingReply] = useState(false);
  const [approveRequest, setApproveRequest] = useState<PublicationRoleRequests>();

  useEffect(() => {
    if (uuid) {
      setLoading(true);
      client
        .getApproveRequest(uuid)
        .then((response) => {
          setApproveRequest({ ...response.data });
        })
        .catch((error) => {
          toastManager.addToast(error?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const onRespondApprovalRequest = (newStatus: 'approved' | 'denied') => {
    if (uuid && approveRequest) {
      setSendingReply(true);
      client
        .respondApprovalRequest(uuid, newStatus)
        .then(() => {
          addToastMessage(false);
          setApproveRequest({ ...approveRequest, status: newStatus });
        })
        .catch((error) => {
          addToastMessage(true);
        })
        .finally(() => {
          setSendingReply(false);
        });
    }
  };

  const addToastMessage = (error: boolean) => {
    error
      ? toastManager.addToast(
          <div role="alert">
            <strong>{t.page_title}</strong>
            <div>
              <Trans id={`approval-request-error`} {...{ value: t.messages.error, userName: approveRequest?.user.email }} />
            </div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      : toastManager.addToast(
          <div role="alert">
            <strong>{t.page_title}</strong>
            <div>
              <Trans id={`approval-request-success`} {...{ value: t.messages.success, userName: approveRequest?.user.email }} />
            </div>
          </div>,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
  };

  return (
    <Page key={'page-apple-approval'}>
      <Helmet defer={false}>
        <title>{t.page_title}</title>
      </Helmet>
      <BreadCrumb
        style={{ width: 'var(--page-width)', margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: isLoggedIn ? 'Home' : 'Werbenet Login',
              link: '/',
            },
            {
              title: t.page_title,
              link: `/approve?request_uuid=${uuid}`,
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      <section className="flex flex-col gap-y-6">
        <h2 className="text-2xl font-extrabold">{t.page_title}</h2>
        <div className="flex flex-col gap-y-3.5 text-base">
          <h3 className="font-bold">{t.allow_access}</h3>
          <p className="whitespace-pre-line text-primary-500">{t.infos}</p>
        </div>

        <div className="flex flex-col gap-y-6 text-base max-w-[940px]">
          {loading ? (
            <Spinner className="pt-8 pb-4 text-primary-500" size={60} />
          ) : (
            <>
              {approveRequest ? (
                <>
                  <div className="grid grid-cols-1 items-center sm:grid-cols-[155px_minmax(0,_1fr)]">
                    <FieldTitle>{`${t.fields.date}`}</FieldTitle>
                    <FieldValue>{'-'}</FieldValue>
                    <Line />
                    <FieldTitle>{`${t.fields.name}`}</FieldTitle>
                    <FieldValue>{[approveRequest.user.first_name || ' ', approveRequest.user.last_name || ' '].join(' ')}</FieldValue>
                    <Line />
                    <FieldTitle>{`${t.fields.user_name}`}</FieldTitle>
                    <FieldValue>{approveRequest?.user.email}</FieldValue>
                    <Line />
                    <FieldTitle>{`${t.fields.status}`}</FieldTitle>
                    <FieldValue>{approveRequest.status}</FieldValue>
                    <Line />
                    <FieldTitle>{`${t.fields.role}`}</FieldTitle>
                    <FieldValue>{approveRequest.publication_role.name}</FieldValue>
                    <Line />
                    <FieldTitle>{`${t.fields.message}`}</FieldTitle>
                    <FieldValue>{approveRequest.notes || '-'}</FieldValue>
                  </div>

                  <div className="flex flex-row gap-x-12 gap-y-8">
                    {sendingReply ? (
                      <Spinner className="pt-8 pb-4 text-primary-500" size={30} />
                    ) : (
                      <>
                        <button
                          className="py-2 px-4 min-w-40 text-base font-bold text-primary-500  bg-white border-[1px] border-primary-500 rounded disabled:border-primary-300 disabled:text-primary-300 disabled:cursor-default"
                          onClick={() => onRespondApprovalRequest('denied')}
                          disabled={approveRequest.status === 'denied'}
                        >
                          {t.reject}
                        </button>
                        <button
                          className="py-2 px-4 min-w-40 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded disabled:border-primary-300 disabled:bg-primary-300 disabled:cursor-default"
                          onClick={() => onRespondApprovalRequest('approved')}
                          disabled={approveRequest.status === 'approved'}
                        >
                          {t.release}
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <h4>{t.no_request}</h4>
              )}
            </>
          )}
        </div>
      </section>
    </Page>
  );
}

const FieldTitle = styled.div`
  ${tw`font-bold pb-2 sm:font-normal sm:pb-0`}
`;

const FieldValue = styled.div`
  ${tw``}
`;

const Line = styled.div`
  ${tw`border-gray-30 border-b-[1px] my-3 sm:col-span-2`}
`;
