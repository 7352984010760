export default function IconFileGraph({ size = 24, color = 'currentColor', select = false }) {
  return (
    <svg height={size} width={size} viewBox="0 0 14 14" fill={color} stroke={color} strokeWidth={'0'} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_268_8676)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.37521 11.2321C4.50545 11.2321 4.63568 11.167 4.73335 11.0694L6.65428 8.82284L8.60777 10.4507L11.5054 7.16237C11.6682 6.96702 11.6682 6.64144 11.4729 6.47865C11.2775 6.31586 10.952 6.31586 10.7892 6.51121L8.54266 9.0833L6.58917 7.4554L4.01708 10.4182C3.85428 10.6135 3.85428 10.9391 4.08219 11.1019C4.14731 11.1996 4.27754 11.2321 4.37521 11.2321Z"
          strokeWidth={select ? '0.5' : '0'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.35865 10.2953L1.43213 10.2627C1.17818 10.2627 0.930739 10.4776 0.956785 10.7576C0.924227 10.8683 0.982832 10.992 1.04795 11.0636C1.1326 11.1613 1.26934 11.2199 1.40609 11.2329H4.36237"
          strokeWidth={select ? '0.5' : '0'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.80322 0V7.81395H2.77997V0.976744H12.873V12.0465C12.873 12.5805 12.4302 13.0233 11.8962 13.0233H1.80322C1.80322 13.5637 2.2395 14 2.77997 14H11.8962C12.9772 14 13.8497 13.1274 13.8497 12.0465V0H1.80322Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_268_8676">
          <rect width="12.8995" height="14" fill="white" transform="translate(0.950195)" />
        </clipPath>
      </defs>
    </svg>
  );
}
