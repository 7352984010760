const eyeCatcherOverView = {
  helmetTitle: 'Störer-Generator',
  breadcrumbTitle: 'Störer-Generator Übersicht',
  title: 'Eye-Catcher Übersicht',
  generateEyeCatcher: 'Neuen Störer generieren',
  preview: 'Vorschau',
  noData: 'Es gibt keine Daten zu sehen!',
  tabs: {
    myEyeCatcher: 'Meine Störer',
    usersEyeCatchers: 'Störer von anderen Benutzern',
  },
  state: {
    title: 'Anzeigen',
    all: 'Alle',
    favorites: 'Favoriten',
    draft: 'Entwurf',
    published: 'Veröffentlicht',
    templates: 'Vorlagen',
    deleted: 'Gelöscht',
    requireApproval: 'Freigabe angefragt',
    approved: 'Genehmigt',
  },
  search: 'Suche',
  dropdownActions: {
    publish: 'Veröffentlichen',
    duplicate: 'Duplizieren',
    saveAsTemplate: 'Als Vorlage speichern',
    generateNewVersion: 'Neue Version generieren',
    unpublish: 'Unplublish(Als Entwurf speichern)',
    requireApproval: 'Genehmigung angefragt',
  },
  buttons: {
    edit: 'Bearbeiten',
    delete: 'Löschen',
    view: 'Anzeigen',
  },
} as const;

export default eyeCatcherOverView;
