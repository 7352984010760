const homePage = {
  learn_more: 'Mehr erfahren',
  image: 'Bild',
  last_edited: 'Zuletzt geändert',
  last_added: 'Zuletzt hinzugefügt',
  most_used: 'Meist heruntergeladen',
  page: 'Seite',
  active_page: 'Aktive Seite',
  pagination_label: 'Paginierung der Folien ',
  slider: 'Slider',
  status_batch: {
    publication_stopped: 'X Gestoppt',
    publication_expired: 'X Abgelaufen',
    not_published: 'Nicht öffentlich',
    archived: 'X Archiviert',
    draft: 'Entwurf',
    require_approval: 'Genehmigung angefragt',
  },
} as const;

export default homePage;
