const approveUser = {
  page_title: 'WerbeNet approval request',
  allow_access: 'Allow access to Apple templates in WerbeNet for a user',
  infos: 'The user is an Apple Authorized Marketer.\nHere you can enable access to Apple templates.',
  no_request: 'There is no request with the specified id',
  fields: {
    date: 'Date',
    name: 'Name',
    user_name: 'User name',
    status: 'Status',
    role: 'Role',
    target_group: 'Target group',
    subgroup: 'Subgroup',
    message: 'Message',
  },
  reject: 'Reject',
  release: 'Release',
  messages: {
    success: 'The email was successfully sent to %{userName}',
    error: 'Email could not be sent to  %{userName}',
  },
} as const;

export default approveUser;
