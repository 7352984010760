import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface CartState {
  assets: Array<Asset>;
  total: number;
  selectedAssets: Array<Asset>;
  byId: Record<string, Asset>;
}

export const initialState: CartState = {
  assets: [],
  total: 0,
  selectedAssets: [],
  byId: {},
};

function mutateStateToMapIds(state: CartState) {
  state.byId = {};
  state.total = state.assets.length;
  state.assets.forEach((asset) => {
    state.byId[asset.id] = asset;
  });
}

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setSelectedAssets: (state, action: PayloadAction<Asset[]>) => {
      state.selectedAssets = action.payload;
    },
    set: (state, action: PayloadAction<Asset[]>) => {
      state.assets = action.payload;
      mutateStateToMapIds(state);
    },
    addAssets: (state, action: PayloadAction<Asset[]>) => {
      var ids = state.assets.map((d) => d.id);
      state.assets = [...state.assets, ...action.payload.filter((d) => !ids.includes(d.id))];
      mutateStateToMapIds(state);
    },
    removeAssets: (state, action: PayloadAction<string[]>) => {
      state.selectedAssets = [];
      state.assets = state.assets.filter((a) => !action.payload.includes(a.id));
      mutateStateToMapIds(state);
    },
  },
});

export default slice;

export const { actions, reducer, name } = slice;
