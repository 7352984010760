/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'Config';
import styled from 'styled-components';
import { breakpoint } from 'Helpers';
import { cart } from './lib/index';
import { email } from 'Features/EmailPage/lib';
import { categoryStore } from 'Features/Categories/lib';
import { useLocation } from 'react-router-dom';
import { CustomTabs, CustomLink, CustomDropDown, PrimaryButton, BreadCrumb, Spinner, Page, ProgressBarProps, DownloadProgress } from 'Components';
import { useT } from './translations';
import { AssetsTable } from './components/AssetsTable';
import { getAssetCustomDimensionsDownload } from 'Components/MatomoGateway/customDimensions';
import { Helmet } from 'react-helmet';
import { BACKEND_BASE_URL } from 'Env';

export default function DownloadBasketPage() {
  const t = useT();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const categories = useAppSelector(categoryStore.selectAppNavItems);
  const [categorySelected, toggleCategory] = useState<Category | undefined>(undefined);
  const assets = useAppSelector(cart.assets);
  const [assetsDisplay, setAssetsDisplay] = useState<Asset[]>([]);
  const assetsSelected = useAppSelector(email.selectedAssets) || [];
  const [subcategoriesWithAssets, setSubcategoriesWithAssets] = useState<Category[]>([]);
  const categoryCartIds = assets.map((asset) => asset.category_id.toString());
  const [tabIndex, setTabIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [loadedSize, setLoadedSize] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [progressBarProps, setProgressBarProps] = useState<ProgressBarProps>();

  useEffect(() => {
    setLoading(true);
    if (!categories) {
      dispatch(categoryStore.fetch());
    }
    dispatch(email.resetAssets());
    dispatch(cart.fetch()).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!categorySelected && Array.isArray(categories)) {
      const mainCategoriesWithAssets = categories.filter((category) => !!getSubcategoriesWithAssets(category).length);
      toggleCategory(mainCategoriesWithAssets[0]);
    }
  }, [categories]);

  useEffect(() => {
    setSubcategoriesWithAssets(getSubcategoriesWithAssets(categorySelected));
  }, [categorySelected, assets]);

  useEffect(() => {
    tabIndex === 0 ? tabChange() : setTabIndex(0);
  }, [subcategoriesWithAssets]);

  useEffect(() => {
    tabChange();
  }, [tabIndex]);

  const getAllChildrenIds = (category: Category) => {
    if (!category.hasOwnProperty('children')) {
      return [category.id.toString()];
    }
    return category.children?.reduce(
      (a: Array<string>, c) => {
        return a.concat(getAllChildrenIds(c));
      },
      [category.id.toString()]
    );
  };

  const getSubcategoriesWithAssets = (category) => {
    return (
      category?.children?.reduce((previousValue: Category[], subCategory: Category) => {
        const ids: Array<string> = getAllChildrenIds(subCategory);
        return ids.some((r) => categoryCartIds.includes(r)) ? previousValue.concat(subCategory) : previousValue;
      }, []) || []
    );
  };

  const tabChange = () => {
    if (subcategoriesWithAssets[tabIndex]) {
      const allIds: Array<string> = getAllChildrenIds(subcategoriesWithAssets[tabIndex]);
      setAssetsDisplay(assets.filter((asset) => allIds.includes(asset.category_id.toString())));
    } else {
      setAssetsDisplay([]);
    }
    dispatch(email.resetAssets());
  };

  const deleteSelectedAssets = () => {
    const ids = assetsSelected.map((asset) => asset.id);
    if (ids.length) dispatch(cart.removeItems(ids));
  };

  const onDownloadProgress = useCallback(
    (progressEvent) => {
      progressEvent.loaded && setLoadedSize(progressEvent.loaded);
    },
    [setLoadedSize]
  );

  const downloadSelectedAssets = (lowResolution: boolean = false) => {
    const ids = assetsSelected.map((asset) => asset.id);
    if (ids.length) {
      setShowProgress(true);
      setLoadedSize(0);
      setProgressBarProps({ ...progressBarProps, inProgress: true, complete: false, error: false, message: undefined });
      dispatch(cart.downloadItems(ids, lowResolution, onDownloadProgress))
        .then(({ data }) => {
          setProgressBarProps({ ...progressBarProps, inProgress: false, complete: true, message: t.progressBar.download_is_finished });
          const downloadUrl = data.download_link;
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `werbenet-downloadkorb-${Math.floor(Date.now() / 10000)}.zip`); // any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          var _paq = (window._paq = window._paq || []);
          (data.assets ?? []).forEach((asset) => {
            const customDimensions = getAssetCustomDimensionsDownload(asset.as_number, asset.name, asset.category_name, asset.fields);
            let dimensions = {};
            customDimensions.forEach((dim) => {
              dimensions[`dimension${dim.id}`] = dim.value;
            });
            _paq.push([
              'trackEvent',
              ['Download-cart', lowResolution ? 'low-res' : 'high-res'].join(' '),
              `${BACKEND_BASE_URL}/admin/assets/${asset.id}`,
              asset.name,
              1,
              { ...dimensions },
            ]);
          });
        })
        .catch(() => {
          setProgressBarProps({ ...progressBarProps, inProgress: false, error: true, message: t.progressBar.download_failed });
        });
    }
  };

  const customTabsItems =
    getSubcategoriesWithAssets(categorySelected).map((subCategory, index) => ({
      id: subCategory.id.toString(),
      label: subCategory.title,
      tabContent: (
        <>
          {tabIndex !== index ? (
            <></>
          ) : loading ? (
            <Spinner size={60} />
          ) : (
            <AssetsTable
              assets={assetsDisplay}
              selected={assetsSelected}
              setAssetsSelected={(assets) => {
                dispatch(email.setSelectedAssets(assets));
              }}
            />
          )}
        </>
      ),
    })) || [];

  return (
    <Page key={'page-cart'}>
      <Helmet defer={false}>
        <title>{t.page_title}</title>
      </Helmet>
      <BreadCrumb
        style={{ margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: 'Home',
              link: '/',
            },
            {
              title: t.title,
              link: '/cart',
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      <HeaderContainer>
        <h2>{t.title}</h2>
        <CustomDropDown
          id={'subcategories-dropdown'}
          classes="px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 
          hover:bg-gray-200 hover:border-primary 
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
          label={categorySelected?.title || t.title}
          disabled={!categories.filter((category) => !!getSubcategoriesWithAssets(category).length && category.id !== categorySelected?.id).length}
          items={categories
            .filter((category) => !!getSubcategoriesWithAssets(category).length && category.id !== categorySelected?.id)
            .map((category) => ({
              label: `${category.title}`,
              onSelect: () => {
                toggleCategory(category);
              },
              props: { className: 'min-w-[288px]' },
            }))}
        />
      </HeaderContainer>
      <CartLinksContainer className="flex items-center gap-y-4 gap-x-8">
        <CustomLink disabled={!assetsSelected.length} onClick={deleteSelectedAssets} label={t.links.delete.label} />
        {!categorySelected?.children?.[tabIndex].terms_of_use && (
          <>
            <CustomLink
              disabled={!assetsSelected.length}
              href={{ pathname: '/email/asset' }}
              label={t.links.download_link.label}
              state={{ prevPath: location.pathname }}
            />
            <CustomLink
              disabled={!assetsSelected.length}
              onClick={() => downloadSelectedAssets(true)}
              label={t.links.download_low_resolution.label}
            />
            <PrimaryButton
              id={'download-assets'}
              disabled={!assetsSelected.length}
              title={t.download}
              aria-label={t.download}
              onClick={() => downloadSelectedAssets()}
            >
              {t.download}
            </PrimaryButton>
          </>
        )}
      </CartLinksContainer>

      <DownloadProgress
        loadedSize={loadedSize}
        progressBarProps={progressBarProps}
        showProgress={showProgress}
        hideDownloadProgress={() => setShowProgress(false)}
      ></DownloadProgress>

      {!!customTabsItems.length ? (
        <CustomTabs key={categorySelected?.id.toString()} tabIndex={tabIndex} handleTabsChange={setTabIndex} items={customTabsItems} />
      ) : (
        <div className="font-bold">
          <p>{t.no_assets}</p>
        </div>
      )}
    </Page>
  );
}

const CartLinksContainer = styled.div`
  #download-assets {
    margin-left: auto;
  }

  @media (max-width: ${breakpoint.size.med}) {
    flex-flow: wrap;

    a,
    button {
      flex: 1 1 100%;
    }
    #download-assets {
      flex: 1 1 100%;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }
  > button {
    width: 288px;
    margin: auto 0px;
  }

  @media (max-width: ${breakpoint.size.med}) {
    flex-flow: wrap;

    h2 {
      flex: 1 1 100%;
    }
    > button {
      flex: 1 1 100%;
    }
  }
`;
