import { RootState } from 'Config/store';

import { name, initialState } from './reducer';

export const slice = (state: RootState) => state[name] || initialState;

export const assets = (state: RootState) => {
  return slice(state).assets;
};

export const total = (state: RootState): number => {
  return slice(state).total;
};

export const selectAssetInCartById = (id: string) => (state: RootState) => {
  const asset = slice(state).byId[id];
  return asset || null;
};

export const selectAssetIdsInCart = (state: RootState) => {
  return new Set(Object.keys(slice(state).byId || {}).map(String));
};
