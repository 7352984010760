const appHeader = {
  go_home_page: 'Go to the home page',
  claim: 'Life is for sharing.',
  dropDownOptions: {
    profile: 'Profile',
    admin: 'Admin',
    logOut: 'Logout from SSO',
  },
  serviceCategory: {
    selfService: 'Self-Service',
    faqs: 'FAQs',
    documents: 'Documents',
    help: 'Help',
    apply_for_apple: 'Apply for Apple-Vermarkter',
  },
  eyeCatcher: 'Eye-Catcher Generator',
  search: 'Search',
  shopping_cart: 'Download basket',
  shopping_cart_contains: 'Download basket (contains %{count} advertising material)',
  user_dropdown: 'User Dropdown',
} as const;

export default appHeader;
