/* eslint-disable react-hooks/exhaustive-deps */
import { CardSkeleton } from 'Components';
import { BreadCrumb } from 'Components/BreadCrumb';
import { client, useAppSelector } from 'Config';
import { categoryStore } from 'Features/Categories';
import { useQueryParams, formattCategoryNameRoute } from 'Helpers';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { AssetDetails } from './AssetDetails';
import AssetsGrid, { superAdminFilter } from './AssetsGrid';
import Filters from './components/Filters';

const hidden = process.env.REACT_APP_HIDDEN_FILTER
  ? new Set(process.env.REACT_APP_HIDDEN_FILTER.split(','))
  : new Set(['DT_Source', 'DT_Asset_Meta_Promotions', 'DT_Guidelines', 'Sma_Quelle', 'Sma_Thema', 'Sma_Promotions', 'DT_Rechtstexte']);

export interface CategoryField extends FieldType {
  multiple: boolean;
  required: boolean;
  options: FieldOption[];
}

export interface FilterParams {
  page?: number;
  per_page?: number;
  sort?: string;
  query?: string;
  as_number?: string;
  category_id?: string;
  usable_from?: string;
  usable_to?: string;
  fields?: number[];
  states?: string[];
}

export default function CartPage() {
  const navigate = useNavigate();
  const assetId = useQueryParams().get('id');
  const queryParams = new URLSearchParams(window.location.search);

  const params = useParams<{ itemid: string; parentid?: string }>();
  const categoriesById = useAppSelector(categoryStore.selectCategoriesById);
  const categoriesByName = useAppSelector(categoryStore.selectCategoriesByName);
  const category = categoriesByName[params.itemid ?? ''];

  const categoryId = String(category?.id);
  const allCategoryFields = useAppSelector(categoryStore.selectCategoryFilters(categoryId || ''));
  const parentCategory = useAppSelector(categoryStore.selectCategory(String(category?.parent_id) || 'never'));

  const [updateFiltersFromUrl, setUpdateFiltersFromUrl] = useState(false);
  const [categoryFields, setCategoryFields] = useState<Array<CategoryField>>([]);
  const [categoryFieldsLoaded, setCategoryFieldsLoaded] = useState(false);
  const [count, setCount] = useState<Record<string, Record<string, number>>>({});
  const [loadCount, setLoadCount] = useState<Record<string, boolean>>({});

  const [filterParams, setFilterParams] = useState<FilterParams>({});
  const [assets, setAssets] = useState<AssetsResponse>();
  const [selected, setSelectedAsset] = useState<Asset | { category_id: string; id: string; _loading: true } | null>();
  const [selectedFromQuery, toggleSelectedFromQuery] = useState(false);

  const [sort, setSort] = useState<[keyof Asset | '', 'ASC' | 'DESC', string]>(['created_at', 'DESC', 'Date']);
  const [adminFilter, setAdminFilter] = useState<keyof typeof superAdminFilter>('Alle');

  const [applyNewFilter, toggleApplyNewFilter] = useState(false);
  const [resetCount, toggleResetCount] = useState(false);

  //Update Filters From Url
  useEffect(() => {
    const newfilterParams: FilterParams = { category_id: filterParams.category_id || categoryId };

    queryParams.forEach((value, key) => {
      switch (key) {
        case 'fields':
          if (!newfilterParams.fields) {
            newfilterParams.fields = [];
          }
          if (!newfilterParams.fields.includes(+value)) {
            newfilterParams.fields.push(+value);
          }
          break;
        case 'sort':
          newfilterParams.sort = value;
          setSort([value.toString().includes('created_at') ? 'created_at' : 'name', value.toString().startsWith('-') ? 'DESC' : 'ASC', 'Date']);
          break;
        case 'page':
        case 'per_page':
          newfilterParams[key] = +value;
          break;
        case 'query':
        case 'as_number':
        case 'category_id':
        case 'usable_from':
        case 'usable_to':
          newfilterParams[key] = value;
          break;
        case 'states':
          if (!newfilterParams.states) {
            newfilterParams.states = [];
          }
          if (!newfilterParams.states.includes(value)) {
            newfilterParams.states.push(value);
          }
          break;
        default:
      }
    });

    const newAdminFilter = Object.entries(superAdminFilter).find(
      ([key, value]) => value && newfilterParams.states && value?.sort().join(',') === newfilterParams.states.sort().join(',')
    );
    if (newAdminFilter === undefined) {
      setAdminFilter('Alle');
    } else {
      setAdminFilter(newAdminFilter[0] as keyof typeof superAdminFilter);
    }

    setFilterParams(newfilterParams);
    setUpdateFiltersFromUrl(true);
  }, []);

  //Load Fields Category && Options for each catergory
  useEffect(() => {
    if (!updateFiltersFromUrl) return;
    if (!allCategoryFields || allCategoryFields.length === 0) return;

    setCategoryFieldsLoaded(false);
    client
      .GETfields(allCategoryFields?.map((r) => r.key))
      .then(({ data }) => {
        const newOptions = data.reduce((a: Record<string, FieldOption[]>, c) => {
          const optionValues: FieldOption[] = a[c.field_type] ? a[c.field_type].concat(c) : [c];
          return {
            ...a,
            [c.field_type]: optionValues.sort((a, b) => {
              if (a.sorting_order === b.sorting_order) return a.title.localeCompare(b.title);
              if (a.sorting_order === 0 || a.sorting_order === undefined) return 1;
              if (b.sorting_order === 0 || b.sorting_order === undefined) return -1;
              return a.sorting_order - b.sorting_order;
            }),
          };
        }, {});
        let newCategoryFields: Array<CategoryField> = [];
        Object.entries(newOptions).forEach(([optionKey, optionValue]) => {
          const categoryFields = allCategoryFields.find((category) => category.key === optionKey);
          if (!hidden.has(optionKey) && optionValue.length > 0 && categoryFields !== undefined) {
            newCategoryFields.push({ ...categoryFields, options: optionValue });
          }
        });

        if (filterParams.category_id === categoryId) {
          setFilterParams({ ...filterParams });
        } else {
          setSort(['created_at', 'DESC', 'Date']);
          setAdminFilter('Alle');
          setFilterParams({ category_id: categoryId });
        }

        setCategoryFields(newCategoryFields);
      })
      .finally(() => setCategoryFieldsLoaded(true));
  }, [categoryId, updateFiltersFromUrl]);

  // Assets && Counts
  useEffect(() => {
    if (categoryFieldsLoaded) {
      fetchAllCounts();
      fetchAssets();
    }
  }, [categoryFieldsLoaded, categoryFields]);

  useEffect(() => {
    if (assetId) {
      const item = assets?.assets.find((row) => `${row.id}` === `${assetId}`);
      item
        ? setSelectedAsset(item)
        : setSelectedAsset({
            category_id: categoriesByName[params.itemid ?? ''].id.toString(),
            id: assetId,
            _loading: true,
          });
    } else {
      setSelectedAsset(undefined);
    }
  }, [assetId, assets?.assets]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (selected) {
      updateUrlPath();
    }
  }, [selected?.id.toString()]);

  useEffect(() => {
    if (applyNewFilter) {
      fetchAssets();
    }
  }, [applyNewFilter]);

  useEffect(() => {
    if (resetCount) {
      fetchAllCounts();
    }
  }, [resetCount]);

  const fetchAllCounts = () => {
    categoryFields.forEach((categoryField) => {
      setLoadCount((prev) => ({ ...prev, [categoryField.key]: true }));
    });
    getAssetsCount(categoryFields);

    toggleResetCount(false);
  };

  const getAssetsCount = (categoryFields: CategoryField[]) => {
    let params = {};
    categoryFields.forEach((categoryField) => {
      const categoryOptions = categoryField.options.map((op) => op.id.toString());
      params = {
        ...filterParams,
        fields: filterParams.fields?.filter((categoryId) => !categoryOptions.includes(categoryId.toString())),
        category_id: categoryId,
        field_types: categoryFields.map((category) => category.key.toString()),
      };
    });
    return client
      .GETcount(params)
      .then((response) => {
        let newCount = response.data.reduce((carry, item) => {
          if (!carry[item.field_type_key]) carry[item.field_type_key] = {};
          carry[item.field_type_key][item.id] = item.assets_count;
          return carry;
        }, {});

        setCount(newCount);
      })
      .finally(() => {
        categoryFields.forEach((categoryField) => {
          setLoadCount((prev) => ({ ...prev, [categoryField.key]: false }));
        });
      });
  };

  const getAssets = useCallback(
    (filter) => {
      client
        .GETassets(filter)
        .then((response) => {
          const assetCategory = {
            ...response.data,
            assets: response.data.assets.map((asset) =>
              asset.category ? asset : Object.assign({}, asset, { category: categoriesById[asset.category_id] })
            ),
          };
          setAssets(assetCategory);
        })
        .finally(() => toggleApplyNewFilter(false));
    },
    [categoriesById]
  );

  const fetchAssets = useCallback(async () => {
    const filter = { category_id: categoryId };
    if (filterParams.query && filterParams.query.length > 0) {
      if (filterParams.query.length === 6 && filterParams.query.match(/^[0-9]{6}$/)) {
        // try to get single asset;
        const isAsset = await client
          .GETbyID(filterParams.query)
          .then((response) => {
            if (response.data && String(response.data.id)) {
              setSelectedAsset(response.data);
              return true;
            }
          })
          .catch(() => {
            return false;
          });
        if (isAsset) {
          toggleApplyNewFilter(false);
          toggleSelectedFromQuery(true);
          return;
        }
      }
      filter['query'] = filterParams.query;
    }

    if (filterParams.as_number && filterParams.as_number.replace(/^0+/, '').length > 0) {
      filter['as_number'] = filterParams.as_number.replace(/^0+/, '');
    }

    if (filterParams.sort) {
      filter['sort'] = filterParams.sort;
    }

    if (filterParams.page && filterParams.page !== -1) {
      filter['page'] = filterParams.page;
    } else {
      filter['page'] = 1;
    }

    filter[`fields`] = filterParams.fields;
    if (filterParams.usable_from) filter['usable_from'] = filterParams.usable_from;
    if (filterParams.usable_to) filter['usable_to'] = filterParams.usable_to;

    if (filterParams.states) {
      filter['states'] = filterParams.states;
    }
    setAssets(undefined);
    getAssets(filter);
    updateUrlPath();
  }, [sort, adminFilter, getAssets, categoryId, filterParams]);

  const updateUrlPath = () => {
    const searchParams = new URLSearchParams();

    let queryParams = '';
    if (selected?.id) {
      searchParams.append('id', selected.id.toString());
    }
    Object.entries(filterParams).forEach(([key, value]) => {
      if (value === undefined || value.length === 0) {
        return;
      } else if (Array.isArray(value)) {
        value.forEach((v) => {
          searchParams.append(key, v.toString());
        });
      } else if (key === 'category_id') {
        searchParams.append('category_id', value.toString());
        queryParams = formattCategoryNameRoute(categoriesById[value.toString()].title);
      } else {
        searchParams.append(key, value.toString());
      }
    });

    navigate({
      pathname: `/shop/${queryParams}`,
      search: searchParams.toString(),
    });
  };

  const setNewSelectedAsset = (asset: Asset | null | undefined) => {
    setSelectedAsset(asset);
    if (!asset) {
      queryParams.delete('id');
      navigate({
        search: queryParams.toString(),
      });
      if (selectedFromQuery) {
        setNewFilterParams({ ...filterParams, query: '' });
        toggleSelectedFromQuery(false);
      }
    }
  };

  const setNewFilterParams = (newFilterParams: FilterParams) => {
    setFilterParams(newFilterParams);
    toggleApplyNewFilter(true);
    toggleResetCount(true);
  };

  const setPage = (newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilterParams({ ...filterParams, page: newPage });
    toggleApplyNewFilter(true);
  };

  const setNewSort = (newSort: ['' | keyof Asset, 'DESC' | 'ASC', string]) => {
    setSort(newSort);
    setFilterParams({ ...filterParams, sort: newSort[0] ? `${newSort[1] === 'DESC' ? '-' : ''}${newSort[0]}` : '' });
    toggleApplyNewFilter(true);
  };

  const setNewAdminFilter = (newAdminFilter: keyof typeof superAdminFilter) => {
    setAdminFilter(newAdminFilter);
    const newValue: string[] = newAdminFilter !== 'Alle' && superAdminFilter[newAdminFilter] ? Object.values(superAdminFilter[newAdminFilter]) : [];
    setNewFilterParams({ ...filterParams, states: newValue, page: 1 });
  };

  if (!Object.keys(params).length || !category) {
    navigate('/');
    return <></>;
  }

  const hasFilterApplied =
    filterParams &&
    !!(
      filterParams.states?.length ||
      filterParams.fields?.length ||
      filterParams.query ||
      filterParams.as_number ||
      filterParams.usable_from ||
      filterParams.usable_to
    );

  return (
    <>
      <Helmet defer={false}>
        <title>{`${category.title} - Werbenet`}</title>
      </Helmet>
      <div className="page main-page">
        <BreadCrumb
          className="px-4 mt-4 mb-8"
          links={
            [
              {
                title: 'Home',
                link: '/',
              },
              parentCategory?.parent_id
                ? {
                    title: parentCategory.title,
                    link: `/shop/${formattCategoryNameRoute(parentCategory.title)}`,
                    onClick: (e) => {
                      setSelectedAsset(undefined);
                      navigate({ search: '' });
                    },
                  }
                : null,
              {
                title: category.title,
                link: `/shop/${formattCategoryNameRoute(category.title)}`,
                onClick: (e) => {
                  if (selected) {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedAsset(undefined);
                    navigate({ search: '' });
                  }
                  return false;
                },
              },
            ].filter(Boolean) as { title: string; link: string }[]
          }
        />
        <div className="flex w-full">
          <Filters
            isDisabled={Boolean(assetId)}
            filterParams={{ ...filterParams }}
            setFilterParams={(newfilterParams) => {
              setNewFilterParams(newfilterParams);
            }}
            categoryFields={categoryFields}
            loadCount={loadCount}
            count={count}
          ></Filters>
          {assets ? (
            selected ? (
              <AssetDetails onSelect={setNewSelectedAsset} data={selected} />
            ) : (
              <AssetsGrid
                category={category}
                title={category.title}
                onSelect={setSelectedAsset}
                onAdminFilter={setNewAdminFilter}
                adminFilter={adminFilter}
                onSortChange={setNewSort}
                sort={sort}
                data={assets}
                hasFilterApplied={hasFilterApplied}
                setPage={setPage}
              />
            )
          ) : (
            <CardSkeleton count={6} />
          )}
        </div>
      </div>
    </>
  );
}
