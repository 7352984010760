const components = {
  abort: 'Abort',
  customAccordion: {
    hide: 'Hide',
    show: 'Show',
  },
  flashMessages: {
    cart: {
      patch_success: 'Item(s) have been put to cart successfully',
      patch_error: 'The item(s) could not be put in the cart',
      delete_success: 'Item(s) have been deleted successfully from cart',
      delete_error: 'Item(s) could not be deleted from the cart',
    },
    assets_download: {
      success: 'Item(s) have been downloaded successfully',
      error: 'Item(s) could not be downloaded',
    },
    assets_share: {
      success: 'The E-Mail was successfully sent',
      error: 'E-Mail could not be sent',
    },
    server_failure: 'Ops, server failure!',
    server_500: 'Request failed. Please contact the system administrator.',
    server_404: 'No route matches',
  },
  inlineSearch: {
    looking_for: 'What are you looking for?',
    search: 'Search',
  },
  modal: {
    close: 'Close',
  },
  progressBar: {
    label: 'Download:',
    close: 'Close',
  },
  pagintaion: {
    first_page: 'First Page',
    previous_page: 'Previous Page',
    page: 'Page',
    next_page: 'Next Page',
    last_page: 'Last Page',
    out_of: 'from',
  },
} as const;

export default components;
