import { BsCheck2 } from 'react-icons/bs';

export interface ProgressBarProps {
  label?: string;
  height?: string;
  inProgress?: boolean;
  complete?: boolean;
  error?: boolean;
  classes?: string;
  showStatus?: boolean;
  message?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const { label, height, inProgress, complete, error, classes, message } = props;
  return (
    <div className={`${classes}`}>
      {label && <label>{label}</label>}

      <div className="container">
        <div className="inline-flex w-full items-center gap-x-2">
          <div
            className={`flex-1 relative w-full rounded-full overflow-hidden border border-gray-60 border-solid`}
            style={{ height: height ? height : '10px' }}
          >
            <div
              className={`percentage absolute inset-0 bg-primary-500 block rounded-full h-full bg-[length:30px_30px] bg-gradient-135 ${
                inProgress && 'animate-animation-stripes'
              }`}
              style={{ width: `100%` }}
            ></div>
          </div>
          <div className={`${complete ? 'opacity-100 ' : 'opacity-0'}`}>
            <BsCheck2 size={16} />
          </div>
        </div>
        <div className={`text-xs ${message ? 'opacity-100 ' : 'opacity-0'} ${error && 'text-red-70'}`}>{message || '-'}</div>
      </div>
    </div>
  );
};
