import { ReactElement } from 'react';
import { BsCheckCircle, BsExclamationCircle, BsInfoCircle, BsXCircle } from 'react-icons/bs';
import { ToastProps } from 'react-toast-notifications';
import { useT } from './translations';
import styled from 'styled-components';
import tw from 'twin.macro';

export const toastColors = {
  success: {
    icon: 'green-100',
  },
  error: {
    icon: 'red-70',
  },
  info: {
    icon: 'blue-70',
  },
  warning: {
    icon: 'orange-80',
  },
};

export function CustomToast({ onDismiss, children, appearance, onMouseEnter, onMouseLeave }: ToastProps): ReactElement {
  const t = useT();
  return (
    <ToastContainer
      className={`flex flex-row flex-nowrap w-[360px] mb-2 p-3 gap-3 rounded-lg border-2`}
      appearance={appearance}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <div className="py-1">
        {appearance === 'success' && <BsCheckCircle size={18} className="text-green-100"></BsCheckCircle>}
        {appearance === 'error' && <BsExclamationCircle size={18} className="text-red-70"></BsExclamationCircle>}
        {appearance === 'info' && <BsInfoCircle size={18} className="text-blue-70"></BsInfoCircle>}
        {appearance === 'warning' && <BsExclamationCircle size={18} className="text-orange-70"></BsExclamationCircle>}
      </div>
      <div className="flex-1">{children}</div>
      <div className="py-1">
        <button
          title={t.modal.close}
          aria-label={t.modal.close}
          className={`font-extrabold text-${toastColors[appearance].icon} opacity-50`}
          onClick={() => onDismiss()}
        >
          <BsXCircle className="" size={18}></BsXCircle>
        </button>
      </div>
    </ToastContainer>
  );
}

const ToastContainer = styled.div<{ appearance: string }>`
  ${({ appearance }) => appearance === 'success' && tw`bg-[#e8f5e1] border-green-50`}
  ${({ appearance }) => appearance === 'error' && tw`bg-[#ffebed] border-red-0`}
  ${({ appearance }) => appearance === 'info' && tw`bg-[#e0faff] border-blue-0`}
  ${({ appearance }) => appearance === 'warning' && tw`bg-[#ffeed9] border-orange-40`}
`;
