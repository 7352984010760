/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'Config';
import { faqs } from './lib/index';
import { BreadCrumb, CustomAccordion, Page, Spinner } from 'Components';
import { useT } from './translations';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function FaqPage() {
  const t = useT();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const questionsByCategory = useAppSelector(faqs.byCategory);

  useEffect(() => {
    setLoading(true);
    dispatch(faqs.fetch()).finally(() => setLoading(false));
  }, []);

  return (
    <Page key={'page-faq'}>
      <Helmet defer={false}>
        <title>{t.page_title}</title>
      </Helmet>
      <BreadCrumb
        style={{ margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: 'Home',
              link: '/',
            },
            {
              title: t.title,
              link: '/faq',
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      <div className="flex mb-6">
        <h1 className="font-extrabold text-4xl">{t.pageHeader}</h1>
      </div>

      {loading ? (
        <Spinner size={60} />
      ) : (
        <div className="flex flex-col gap-8">
          {questionsByCategory.map((questions) => (
            <div key={`category-${questions.category.id}`}>
              <h2 className="text-primary-500 py-3 font-bold text-2xl">{questions.category.name}</h2>
              <CustomAccordion
                items={questions.faqs.map((faq) => ({
                  label: faq.question,
                  panelContent: <AnswerContent dangerouslySetInnerHTML={{ __html: faq.answer }} />,
                }))}
              ></CustomAccordion>
            </div>
          ))}
        </div>
      )}
    </Page>
  );
}

const AnswerContent = styled.div`
  a {
    ${tw`underline text-blue-60 hover:text-blue-70`}
  }
`;
