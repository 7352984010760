const appleApproval = {
  page_title: 'Approval request for Apple templates',
  apply_for_apple: 'Apply for Apple-Vermarkter',
  contact_person_at_telekom: 'Contact person at Telekom',
  infos: 'Please name the contact person who should release your Apple templates.',
  approvalForm: {
    first_name: 'First name',
    last_name: 'Last name',
    email_address: 'E-mail address',
    email_title: "Please only enter an '@telekom.de' or '@8s.de' e-mail address",
    message: 'Message',
    message_label: 'Message to contact person (optional)',
    submit: 'Submit',
  },
  messages: {
    success: 'The email was successfully sent to %{emailAddress}',
    error: 'Email could not be sent to  %{emailAddress}',
  },
} as const;

export default appleApproval;
