const email = {
  page_title: 'Datei per E-Mail versenden - WerbeNet',
  assetMsg: {
    title: 'Datei per E-Mail versenden',
    body: 'Hier können Sie die ausgewählten Medien an einen beliebigen E-Mail-Empfänger senden. Wenn Sie diese Funktion nutzen, kann der Empfänger den Downloadlink maximal 7 Tage lang nutzen.',
    noAssetSelected: 'Kein Asset ausgewählt.',
    submit: 'E-Mail senden',
  },
  email: {
    to: 'An',
    address: 'Mehrere E-Mail Adressen können mit einem Komma "," getrennt hinzugefügt werden.',
    salutation: 'Anrede',
    name: 'Name',
    description: 'Beschreibung',
    description_label: 'Geben Sie hier eine Erläuterung für den Empfänger ein (optional)',
    success: 'Die E-Mail wurde erfolgreich an %{emailAddress} gesendet.',
    error: 'E-Mail konnte nicht an %{emailAddress} gesendet werden.',
    invalidEmail: 'Die folgende 👎 E-Mail(s) oder Trennzeichen sind ungültig:',
  },
} as const;

export default email;
