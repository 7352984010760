import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { useT } from '../translations';
import { CustomQuillToolbar, formats, modules } from 'Components';
import { modulesEmty } from '../lib/utils';
import 'react-quill/dist/quill.snow.css';

const LegalText: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { control } = useFormContext();
  const t = useT();
  let quillRef: ReactQuill | null;

  function insertAsterisk() {
    if (quillRef === null) return;
    const quill = quillRef.getEditor();
    const unprivilegedEditor = quillRef.unprivilegedEditor;

    setTimeout(function () {
      if (unprivilegedEditor?.getText().startsWith('*')) return;
      const quillText = quill.getText();
      quill.insertText(0, '*');
      quill.setSelection({ index: quillText.length, length: 0 });
    }, 0);
  }

  return (
    <div className="flex flex-col gap-2">
      <h2 className="font-extrabold">{t.legalTextSection.title}</h2>
      <Controller
        name={`legal_text`}
        control={control}
        render={({ field }) => (
          <>
            {canEdit ? (
              <div>
                <CustomQuillToolbar />
                <ReactQuillContainer
                  ref={(el) => (quillRef = el)}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  onChange={(value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
                    if (editor.getText().startsWith('*')) {
                      field.onChange(editor.getText() !== '\n' ? value : undefined);
                    } else {
                      editor.getText() !== '\n' && insertAsterisk();
                    }
                  }}
                  value={field.value ?? ''}
                  readOnly={!canEdit}
                />
              </div>
            ) : (
              <ReactQuillContainer
                className={'noEdit'}
                theme={'bubble'}
                modules={modulesEmty}
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value ?? ''}
                readOnly={!canEdit}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

export default LegalText;

const ReactQuillContainer = styled(ReactQuill)`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  &.noEdit {
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    opacity: 0.5;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
`;
