import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InitialState = StaticPage[];

export const initialState: InitialState = [];

export const { actions, reducer, name } = createSlice({
  name: 'staticPages',
  initialState,
  reducers: {
    reset: (_, { payload }: PayloadAction<StaticPage[]>) => {
      return payload;
    },
    fetchDetails: (state, action: PayloadAction<StaticPage>) => {
      return state.map((page) => (page.slug === action.payload.slug ? action.payload : page));
    },
  },
});

export const slice = (state: RootState): StaticPage[] => state[name] || initialState;
