const appleApproval = {
  page_title: 'Freigabebeantragung für Apple Vorlagen',
  apply_for_apple: 'Freigabe für Apple Vorlagen',
  contact_person_at_telekom: 'Kontaktperson bei der Telekom',
  infos: 'Bitte benennen Sie die Kontaktperson, die Ihre Apple Vorlagen freigeben soll.',
  approvalForm: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    email_address: 'E-Mail-Adresse',
    email_title: "Bitte geben Sie nur eine '@telekom.de' oder '@8s.de' E-Mail-Adresse ein",
    message: 'Nachricht',
    message_label: 'Nachricht an Kontaktperson (optional)',
    submit: 'Absenden',
  },
  messages: {
    success: 'Die E-Mail wurde erfolgreich an %{emailAddress} gesendet.',
    error: 'E-Mail konnte nicht an %{emailAddress} gesendet werden.',
  },
} as const;

export default appleApproval;
