import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { actions } from './reducer';

export const fetch =
  (): AppAsyncThunk =>
  (dispatch, getState, { api }) =>
    client.GETcart().then(({ data }) => {
      dispatch(actions.set(data));
    });

export const downloadSelectedAssets = (ids, lowResolution: boolean = false, onDownloadProgress: (progressEvent: any) => void) => {
  return client
    .GETdownload(Array.from(ids), lowResolution, onDownloadProgress)
    .then(({ data }) => {
      const downloadUrl = data.download_link;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `werbenet-shop-${lowResolution ? 'low' : 'normal'}-${Math.floor(Date.now() / 10000)}.zip`); // any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      return data;
    })
    .catch((error) => {
      throw error;
    });
};
