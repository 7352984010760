const download = {
  page_title: 'Seite herunterladen',
  title: {
    200: 'Die folgende Datei(en) wurden Ihnen per E-Mail angeboten',
    404: 'Leider können Sie diese Datei(en) nicht mehr herunterladen, da der Gültigkeitszeitraum für diesen Download-Link abgelaufen ist.',
    500: 'Entschuldigung, leider ging etwas schief!',
  },
  body: {
    200: 'Die Download-Datei enthält folgende Dokumente (%{size} MB):',
    500: 'Es tut uns leid, aber Ihre Anfrage konnte nicht erfolgreich vollendet werden.',
  },
  download: 'Herunterladen',
  progressBar: {
    download_is_finished: 'Das Herunterladen ist beendet',
    download_failed: 'Herunterladen fehlgeschlagen',
  },
} as const;

export default download;
