import React from 'react';
import { Quill } from 'react-quill';
import styled from 'styled-components';

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Fonts = Quill.import('formats/font');
Fonts.whitelist = ['teleNeoWeb', 'arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Fonts, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

// Quill Toolbar component
export const CustomQuillToolbar = () => (
  <Toolbar id="toolbar">
    <select className="ql-font">
      <option value="teleNeoWeb" defaultValue={'TeleNeoWeb'}>
        TeleNeoWeb
      </option>
      <option value="arial">Arial</option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
    </select>

    <select className="ql-size" defaultValue="9">
      {Size.whitelist.map((size, index) => (
        <option value={size} key={index}>
          {size}
        </option>
      ))}
    </select>

    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
  </Toolbar>
);

export default CustomQuillToolbar;

const Toolbar = styled.div`
  .ql-picker.ql-size {
    .ql-picker-label {
      width: 65%;
    }
  }
`;
