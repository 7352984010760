type ComponentProps = {
  fill?: string;
  size?: number;
};

const ActionBackSpace: React.FC<ComponentProps> = ({ fill = 'none', size = 25 }) => (
  <svg width={size} role="img" height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <path fill={'none'} d="M0 0H64.001V64H0z"></path>
    <path
      fill={fill}
      d="M59.001,9.002H27c-1.1,0-2.636,0.636-3.414,1.414L3.415,30.585c-0.778,0.778-0.778,2.051,0,2.828 l20.171,20.173C24.364,54.364,25.9,55,27,55h32.001c1.1,0,2-0.9,2-2V11.002C61.001,9.902,60.101,9.002,59.001,9.002z M53.061,42.941c0.586,0.586,0.586,1.535,0,2.121c-0.293,0.293-0.677,0.439-1.06,0.439c-0.384,0-0.768-0.146-1.061-0.439 L40.001,34.123l-10.94,10.939c-0.293,0.293-0.677,0.439-1.06,0.439c-0.384,0-0.768-0.146-1.061-0.439 c-0.586-0.586-0.586-1.535,0-2.121L37.88,32.002L26.94,21.062c-0.586-0.586-0.586-1.535,0-2.121c0.586-0.586,1.535-0.586,2.121,0 l10.94,10.939L50.94,18.941c0.586-0.586,1.535-0.586,2.121,0c0.586,0.586,0.586,1.535,0,2.121L42.122,32.002L53.061,42.941z"
    ></path>
  </svg>
);

export default ActionBackSpace;
