import { useState } from 'react';
import { Modal, PrimaryButton, CustomCheckbox } from 'Components';
import { useT } from '../translations';

interface ModalProps {
  visible: boolean;
  onRequestClose: () => void;
  onDownload: () => void;
}

export function ModalTermsOfUse({ visible, onRequestClose, onDownload }: ModalProps) {
  const t = useT();
  const [termsOfUse, setTermsOfUse] = useState(false);

  return (
    <Modal
      bodyClasses="py-5 px-1 overflow-auto"
      title={t.terms_of_use.title}
      visible={visible}
      onRequestClose={() => {
        setTermsOfUse(false);
        onRequestClose();
      }}
    >
      <h2>{t.terms_of_use.body_title}</h2>
      <br />
      <p>{t.terms_of_use.rules.info}</p>
      <br />
      <ul className="list-disc pl-8">
        <li>{t.terms_of_use.rules[1]}</li>
        <li className="font-bold">{t.terms_of_use.rules[2]}</li>
        <li>{t.terms_of_use.rules[3]}</li>
        <li>{t.terms_of_use.rules[4]}</li>
        <li>{t.terms_of_use.rules[5]}</li>
        <li>{t.terms_of_use.rules[6]}</li>
        <li>{t.terms_of_use.rules[7]}</li>
      </ul>
      <br />
      <p>
        <span>{t.terms_of_use.regards}</span>
        <span>{t.terms_of_use.telekom}</span>
      </p>
      <br />
      <div>
        <CustomCheckbox
          style={{ height: 24, width: 24 }}
          id="terms-of-use"
          value="terms-of-use"
          checked={termsOfUse}
          onChange={(e) => {
            setTermsOfUse(e.target.checked);
          }}
        >
          {t.terms_of_use.confirm}
        </CustomCheckbox>
      </div>
      <br />
      <PrimaryButton
        className="float-right"
        disabled={!termsOfUse}
        title={t.terms_of_use.download}
        aria-label={t.terms_of_use.download}
        onClick={() => {
          setTermsOfUse(false);
          onDownload();
        }}
      >
        {t.terms_of_use.download}
      </PrimaryButton>
    </Modal>
  );
}
