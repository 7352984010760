/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { client, useAppDispatch, useAppSelector } from 'Config';
import { formatISO } from 'date-fns';
import { breakpoint } from 'Helpers';
import { useParams, useLocation } from 'react-router-dom';
import { email } from './lib';
import styled from 'styled-components';
import { useT } from './translations';
import { BreadCrumb, TextInput, TextArea, IconButton, Page } from 'Components';
import Trans from 'Components/Trans';
import { Helmet } from 'react-helmet';
import { BsEnvelopeFill } from 'react-icons/bs';

const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function EmailPage() {
  let typingTimerEmail: NodeJS.Timeout; //timer identifier
  let doneTypingInterval = 1000; //time in ms (1 seconds)
  const dispatch = useAppDispatch();
  const location = useLocation();
  const assetsSelected = useAppSelector(email.selectedAssets);
  const [assetsIds, setAssetsIds] = useState<string[]>(assetsSelected.map((asset) => asset.id));
  const [formData, setFormData] = useState<{ emailAddress: string; description?: string }>({
    emailAddress: '',
    description: '',
  });
  const [invalidEmails, setInvalidEmails] = useState<string[]>();
  const [shareStatus, setShareStatus] = useState<undefined | 'success' | 'error'>(undefined);
  const t = useT();
  const params = useParams<{ id: string }>();
  const id = params.id;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (id) {
      client
        .GETbyID(id)
        .then(() => {
          setAssetsIds([id]);
        })
        .catch(() => {
          setAssetsIds([]);
        });
    } else {
      const regexCart = /^\/cart/i;
      const regexShop = /^\/shop\//i;
      if (!(regexCart.test(location.state?.prevPath || '') || regexShop.test(location.state?.prevPath || ''))) {
        dispatch(email.resetAssets());
        setAssetsIds([]);
      }
    }
  }, [id]);

  const emailValidation = (emailAddress?: string) => {
    const emails = emailAddress?.split(/[ ,]+/);
    let invalidEmails: string[] | undefined = emailAddress?.length ? [] : undefined;
    emails?.forEach((email) => {
      if (!email || regex.test(email) === false) {
        invalidEmails?.push(email);
      }
    });

    return invalidEmails;
  };

  useEffect(() => {
    setShareStatus(undefined);
  }, [formData]);

  const onSubmit = (e) => {
    e.preventDefault();

    var date = new Date();
    date.setDate(date.getDate() + 7);

    const formDataRequest = {
      message: formData.description,
      expires_on: formatISO(date, { representation: 'date' }),
      assets_ids: assetsIds,
      recipients: e.target.email_address.value.split(/[ ,]+/),
    };

    if (emailValidation(e.target.email_address.value)?.length === 0) {
      client
        .POSTshare(formDataRequest)
        .then(() => {
          setShareStatus('success');
        })
        .catch(() => {
          setShareStatus('error');
        });
    }
  };

  const noAssetsSelected = !assetsIds.length;
  const fontSize = '18px';
  return (
    <Page key={'page-email'}>
      <Helmet defer={false}>
        <title>{t.page_title}</title>
      </Helmet>
      <BreadCrumb
        style={{ width: 'var(--page-width)', margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: 'Home',
              link: '/',
            },
            {
              title: t.assetMsg.title,
              link: `/email/asset${id ? `/${id}` : ``}`,
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      <PageContainer>
        <HeaderContainer>
          <h2>{t.assetMsg.title}</h2>
        </HeaderContainer>
        <div className="info font-bold">
          <p>{t.assetMsg.body}</p>
          {noAssetsSelected && (
            <StatusMessage className={`info mt-4`} status={'error'}>
              {t.assetMsg.noAssetSelected}
            </StatusMessage>
          )}
        </div>
        <EmailForm onSubmit={onSubmit} disabled={noAssetsSelected}>
          <fieldset disabled={noAssetsSelected}>
            <Label fontSize={fontSize} htmlFor="email_address">
              {t.email.to}
            </Label>
            <TextInput
              fontSize={fontSize}
              required={true}
              label={t.email.address}
              name="email_address"
              type="text"
              ref={ref}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                clearTimeout(typingTimerEmail);
                typingTimerEmail = setTimeout(function () {
                  setInvalidEmails(emailValidation(e.target.value));
                  setFormData({ ...formData, emailAddress: e.target.value });
                }, doneTypingInterval);
              }}
            />

            {invalidEmails && invalidEmails.length > 0 && (
              <p className="text-red-500 font-bold italic col-start-2">{`${t.email.invalidEmail} ${invalidEmails.join(', ')}`}</p>
            )}
          </fieldset>

          <fieldset disabled={noAssetsSelected}>
            <Label fontSize={fontSize} htmlFor="description">
              {t.email.description}
            </Label>
            <TextArea
              fontSize={fontSize}
              rows={10}
              label={t.email.description_label}
              name="description"
              value={formData.description || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, description: e.target.value })}
            />
          </fieldset>
          <IconButton
            id="button-submit "
            disabled={noAssetsSelected}
            title={t.assetMsg.submit}
            aria-label={t.assetMsg.submit}
            className="ml-auto w-40"
            onClick={() => {
              if (invalidEmails?.length) {
                ref?.current?.focus();
              }
            }}
          >
            {t.assetMsg.submit} <BsEnvelopeFill className="ml-2" />
          </IconButton>
        </EmailForm>
        {shareStatus && (
          <StatusMessage className={`info`} status={shareStatus}>
            <Trans id={`${shareStatus}-message`} {...{ value: t.email[shareStatus], emailAddress: formData.emailAddress }} />
          </StatusMessage>
        )}
      </PageContainer>
    </Page>
  );
}

const PageContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  max-width: 940px;

  .info {
    font-size: 1.3rem;
    line-height: 1.9rem;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    .info {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    #button-submit {
      width: 100%;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    h2 {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
`;

const EmailForm = styled.form<{
  disabled: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  color: ${({ disabled }) => disabled && `var(--text-disabled)`};

  > fieldset {
    display: grid;
    grid-template-columns: 135px 1fr;
  }

  @media (max-width: ${breakpoint.size.med}) {
    > fieldset {
      grid-template-columns: 1fr;
    }
  }
`;

const Label = styled.label<{
  fontSize?: string;
}>`
  font-size: ${({ fontSize = '1rem' }) => fontSize};
  font-weight: 700;
  padding-top: ${({ fontSize = '1rem' }) => `calc(2 * ${fontSize})`};
`;

const StatusMessage = styled.div<{ status: 'success' | 'error' }>`
  color: ${({ status }) => `var(--text-${status})`};
`;
