import { AxiosInstance } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useT } from '../translations';

export function ConnectivityMonitor({ api }: { api?: AxiosInstance }) {
  const t = useT();
  const toastManager = useToasts();
  const [isOnline, toggleOnline] = useState(!!window.navigator.onLine);
  const mounted = useRef(false);
  useEffect(() => {
    // prevent show message on inital mount
    if (!mounted.current && isOnline) {
      mounted.current = true;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);
  useEffect(() => {
    const offline = () => toggleOnline(false);
    const online = () => toggleOnline(true);
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    };
  }, []);

  useEffect(() => {
    let i: null | number = null;
    if (api) {
      i = api.interceptors.response.use(
        (response) => {
          let message = '';
          switch (response?.config?.url) {
            case '/cart': {
              message =
                response?.config?.method === 'patch'
                  ? t.flashMessages.cart.patch_success
                  : response?.config?.method === 'delete'
                  ? t.flashMessages.cart.delete_success
                  : '';
              break;
            }
            case '/assets/download': {
              message = t.flashMessages.assets_download.success;
              break;
            }
            case '/assets/share': {
              message = t.flashMessages.assets_share.success;
              break;
            }
            default: {
              break;
            }
          }

          if (message) {
            toastManager.addToast(
              <div role="alert">
                <div>{message}</div>
              </div>,
              {
                appearance: 'success',
                autoDismiss: true,
              }
            );
          }

          return Promise.resolve(response);
        },
        (error) => {
          const errorResponse = error?.response;
          const url = errorResponse?.config?.url;
          const method = errorResponse?.config?.method;
          const errorMessage = errorResponse?.data?.message;
          let header = '';
          let message = '';

          switch (url) {
            case '/cart': {
              message = method === 'patch' ? t.flashMessages.cart.patch_error : method === 'delete' ? t.flashMessages.cart.delete_error : '';
              break;
            }
            case '/assets/download': {
              message = t.flashMessages.assets_download.error;
              break;
            }
            case '/assets/share': {
              message = t.flashMessages.assets_share.error;
              break;
            }
            case '/publication_role_requests': {
              break;
            }
            default: {
              break;
            }
          }

          if (errorResponse && errorResponse?.status === 404 && !!errorMessage && String(errorMessage).startsWith("Couldn't find Asset with 'id'")) {
            message = '';
          } else if (errorResponse && (errorResponse?.status === 500 || errorResponse?.status === 404)) {
            header = `${t.flashMessages.server_failure},  ${errorResponse?.status}`;
            message = errorResponse.status === 500 ? t.flashMessages.server_500 : `${t.flashMessages.server_404} [${url}] ${method}`;
          } else if (errorResponse && errorResponse?.status === 401) {
            console.warn('session expired...');
          }

          if (message) {
            toastManager.addToast(
              <div role="alert">
                {header && <strong>{header}</strong>}
                <div>{message}</div>
              </div>,
              {
                appearance: 'error',
                autoDismiss: true,
              }
            );
          }

          return Promise.reject(error);
        }
      );
    }
    return () => {
      i !== null && api?.interceptors.response.eject(i);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
