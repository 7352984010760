const appHeader = {
  go_home_page: 'Gehen Sie zur Startseite',
  claim: 'Erleben, was verbindet',
  dropDownOptions: {
    profile: 'Profil',
    admin: 'Admin',
    logOut: 'Vom SSO abmelden',
  },
  serviceCategory: {
    selfService: 'Self-Service',
    faqs: 'FAQs',
    documents: 'Dokumente',
    help: 'Hilfe',
    apply_for_apple: 'Freigabe für Apple Vorlagen',
  },
  eyeCatcher: 'Störer Generator',
  search: 'Suchen',
  shopping_cart: 'Downloadkorb',
  shopping_cart_contains: 'Downloadkorb (enthält %{count} Werbemittel)',
  user_dropdown: 'Benutzer-Dropdown',
} as const;

export default appHeader;
