import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PrimaryButton } from 'Components';
import styled from 'styled-components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { BsSearch, BsTrash, BsPlusLg, BsJustify } from 'react-icons/bs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuid } from 'uuid';
import { useT } from '../translations';
import { Menu, MenuList, MenuButton } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { useQuery } from 'react-query';
import { client } from 'Config';
import { getSVG, modules, modulesEmty } from '../lib/utils';

const BulletPoints: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { append, fields, remove, move } = useFieldArray({
    name: 'bullet_points',
    control,
    rules: { required: true },
  });
  const t = useT();
  const [isDraggable, setIsDragable] = useState(false);

  const { data: icons } = useQuery('icons_eyeCatcher', () => client.GETIcons());

  const onAddBtnClick = (event: any) => {
    append({ icon: undefined, text: undefined, id: uuid() });
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    if (isDragging) {
      return {
        borderBottom: '5px solid white',
        backgroundColor: '#f568b5',
        borderRadius: '8px',
        ...draggableStyle,
      };
    }
    return { userSelect: 'none', margin: `0 0 1rem 0`, ...draggableStyle };
  };

  const getListStyle = (isDraggingOver) => {
    if (isDraggingOver) {
      return {
        background: 'var(--magenta_light)',
        border: '1px solid var(--magenta_light)',
        borderRadius: '8px',
      };
    }
    return { background: 'none' };
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <h2 className="font-extrabold">{t.bulletPointsSection.title}</h2>
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result);
            setIsDragable(false);
          }}
          onDragStart={() => setIsDragable(true)}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {fields.map((field: { id: string; icon?: IIconBE; text?: string }, index) => {
                  return (
                    <React.Fragment key={field.id}>
                      <Draggable key={index} draggableId={`${index}-draggable`} index={index} isDragDisabled={!canEdit}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div className="flex flex-row gap-4 mb-1">
                              <p>{t.bulletPointsSection.bgColor.name}:</p>
                              <div className="inline-flex items-center gap-1">
                                <input
                                  type="radio"
                                  value="magenta"
                                  {...register(`bullet_points.${index}.bullet_points_background`)}
                                  className="w-4 h-4 text-primary-600"
                                  disabled={!canEdit}
                                />
                                <label>{t.bulletPointsSection.bgColor.magenta}</label>
                              </div>
                              <div className="inline-flex items-center gap-1">
                                <input
                                  type="radio"
                                  value="white"
                                  {...register(`bullet_points.${index}.bullet_points_background`)}
                                  className="w-4 h-4 text-primary-600"
                                  disabled={!canEdit}
                                />
                                <label>{t.bulletPointsSection.bgColor.white}</label>
                              </div>
                            </div>
                            <div className={`flex flex-row gap-4 `} key={index}>
                              <Controller
                                name={`bullet_points.${index}.icon`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => {
                                  return (
                                    <div className="flex flex-row items-center border border-gray-300 rounded-md">
                                      <div className="border-r border-gray-300 flex flex-row items-center p-2">
                                        <div className="inline-flex">
                                          <Menu>
                                            <MenuButton
                                              className="btn px-2 py-2 font-medium rounded transition duration-150 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed "
                                              disabled={!canEdit}
                                              ref={ref}
                                            >
                                              <BsSearch size={16} className="pointer-events-none" />
                                            </MenuButton>
                                            <MenuList className="whitespace-normal max-w-[244px] rounded">
                                              <div className="flex flex-col gap-2">
                                                <div className="px-4">
                                                  {icons &&
                                                    icons?.length > 0 &&
                                                    icons.map((icon) => {
                                                      return (
                                                        <button
                                                          key={icon.id}
                                                          type="button"
                                                          name="bullet_icon"
                                                          className="btn px-2 py-2 text-[#262626] font-medium rounded transition duration-150 ease-in-out hover:border-primary-500 hover:text-primary-600"
                                                          onClick={() => field.onChange(icon)}
                                                        >
                                                          {getSVG(icon)}
                                                        </button>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </MenuList>
                                          </Menu>
                                        </div>

                                        <button
                                          type="button"
                                          className="btn px-2 py-2 font-medium rounded transition duration-150 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed "
                                          onClick={() => field.onChange(null)}
                                          disabled={!canEdit}
                                        >
                                          <BsTrash size={16} />
                                        </button>
                                      </div>
                                      <div className="p-2 h-full">
                                        <div
                                          className={`w-8 h-full flex items-center justify-center ${
                                            watch(`bullet_points.${index}.bullet_points_background`) === 'white'
                                              ? 'bg-white text-primary-600'
                                              : 'bg-primary-500 text-white'
                                          }  border-primary-500 border rounded`}
                                        >
                                          {field.value && getSVG(field.value)}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              />

                              <Controller
                                name={`bullet_points.${index}.text`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => (
                                  <ReactQuillContainer
                                    className={canEdit ? 'canEdit' : 'noEdit'}
                                    ref={ref}
                                    theme={canEdit ? 'snow' : 'bubble'}
                                    modules={canEdit ? modules : modulesEmty}
                                    onChange={(value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
                                      if (isDraggable) return;
                                      field.onChange(editor.getText() !== '\n' ? value : null);
                                    }}
                                    value={field.value ?? ''}
                                    readOnly={!canEdit}
                                  />
                                )}
                              />

                              <div className="flex items-center">
                                <button
                                  type="button"
                                  className="btn px-2 py-2 text-primary-600 font-medium rounded transition duration-150 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed"
                                  onClick={() => remove(index)}
                                  disabled={!canEdit}
                                >
                                  <BsTrash size={16} />
                                </button>

                                <button
                                  type="button"
                                  className="btn px-2 py-2 text-primary-600 font-medium rounded transition duration-150 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed"
                                  disabled={!canEdit}
                                >
                                  <BsJustify size={16} />
                                </button>
                              </div>
                            </div>
                            {(errors?.bullet_points?.[index]?.icon || errors?.bullet_points?.[index]?.text) && (
                              <p className="text-base italic text-red-60 mt-2">{t.field_bullets.text}</p>
                            )}
                          </div>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {(watch('bullet_points') === undefined || watch('bullet_points').length === 0) && errors?.bullet_points && (
          <p className="text-base italic text-red-60 mt-2">{t.field_bullets.generalMessage}</p>
        )}
        <PrimaryButton
          className="flex flex-row gap-2 w-fit !text-sm items-center disabled:opacity-40 disabled:cursor-not-allowed"
          onClick={onAddBtnClick}
          type="button"
          disabled={!canEdit}
        >
          <BsPlusLg size={12} />
          {t.bulletPointsSection.addButton}
        </PrimaryButton>
      </div>
    </>
  );
};

export default BulletPoints;

const ReactQuillContainer = styled(ReactQuill)`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  &.noEdit {
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    opacity: 0.5;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
`;
