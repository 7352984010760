import { useState, Fragment } from 'react';
import styled from 'styled-components';
import { BsZoomIn } from 'react-icons/bs';
import { useT } from '../translations';
import { API_URL } from 'Env';
import defaultImgSrc from 'Assets/nopreview.png';
import { FormatDate, formattCategoryNameRoute, zeroFill } from 'Helpers';
import { useAppSelector } from 'Config';
import { categoryStore } from 'Features/Categories';
import { Link } from 'react-router-dom';
import AssetPreview from 'Features/Shop/components/AssetPreview';
import { CustomCheckbox } from 'Components';

interface P {
  assets: Asset[];
  selected: Asset[];
  setAssetsSelected: (assets: Asset[]) => void;
}

export const AssetsTable: React.FC<P> = ({ assets = [], selected = [], setAssetsSelected }) => {
  const t = useT();
  const categories = useAppSelector(categoryStore.selectCategories);
  const [assetPreview, setAssetPreview] = useState<Asset>();
  const onCheckedChanged = (e, asset: Asset) => {
    const val = e.target.checked;
    setAssetsSelected(val ? selected.concat(asset) : selected.filter((assetSelected) => assetSelected.id !== asset.id));
  };

  const selectAll = (value) => {
    setAssetsSelected(value ? assets : []);
  };

  const assetDetailsLink = (asset: Asset) => {
    const assetCategory = categories.find((category) => category.id === asset.category_id);
    return assetCategory && `/shop/${formattCategoryNameRoute(assetCategory.title)}?id=${asset.id}`;
  };

  return (
    <Fragment>
      {assetPreview && <AssetPreview assetPreview={assetPreview} onClose={() => setAssetPreview(undefined)}></AssetPreview>}
      <div className="flex flex-col text-left">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 break-all">
                <thead className="bg-gray-10 font-bold">
                  <tr>
                    <th scope="col" className="px-6 py-3 w-44 text-left text-xs whitespace-nowrap text-gray-800 tracking-wider">
                      <CustomCheckbox
                        title={t.tabel.selectAll}
                        aria-label={t.tabel.selectAll}
                        id="select-all"
                        value="select-all"
                        checked={!!assets.length && selected.length === assets.length}
                        onChange={(e) => selectAll(e.target.checked)}
                      >
                        {t.tabel.selectAll}
                      </CustomCheckbox>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs whitespace-nowrap text-gray-800 tracking-wider">
                      {t.tabel.assetNumber}
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs whitespace-nowrap text-gray-800 tracking-wider">
                      {t.tabel.name}
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs whitespace-nowrap text-gray-800 tracking-wider">
                      {t.tabel.endofUsePeriod}
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {!assets.length && (
                    <tr>
                      <td colSpan={4} className="h-40">
                        <h3 className="flex justify-items-center justify-center">{t.tabel.noAssets}</h3>
                      </td>
                    </tr>
                  )}
                  {assets.map((asset, index) => (
                    <Fragment key={asset.id}>
                      <tr key={asset.id}>
                        <td className="pr-6 pl-4 py-4 whitespace-nowrap">
                          <AssetCard key={asset.id}>
                            <CustomCheckbox
                              title={`${selected.some((selectedAsset) => selectedAsset.id === asset.id) ? t.deselect : t.select}`}
                              aria-label={`${selected.some((selectedAsset) => selectedAsset.id === asset.id) ? t.deselect : t.select}`}
                              id={asset.id}
                              value={asset.id.toString()}
                              name={asset.name}
                              checked={selected.some((selectedAsset) => selectedAsset.id === asset.id)}
                              onChange={(e) => onCheckedChanged(e, asset)}
                            >
                              <div
                                className="img-container"
                                title={`${selected.some((selectedAsset) => selectedAsset.id === asset.id) ? t.deselect : t.select}`}
                                aria-label={`${selected.some((selectedAsset) => selectedAsset.id === asset.id) ? t.deselect : t.select}`}
                              >
                                {asset.alternative_image_url || asset.file_preview_url ? (
                                  <img
                                    src={`${API_URL.slice(0, -8)}${asset.alternative_image_url ?? asset.file_preview_url}`}
                                    onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                                      if (e.target.src !== defaultImgSrc) {
                                        e.target.onerror = null;
                                        e.target.src = defaultImgSrc;
                                      }
                                    }}
                                    alt={`Bild ${asset.name}`}
                                  />
                                ) : (
                                  <img src={defaultImgSrc} alt="Default Img" />
                                )}

                                <button
                                  title={t.zoom_image}
                                  aria-label={t.zoom_image}
                                  className="button-zoom absolute inset-1/4 m-auto text-primary-500 bg-white rounded-lg p-2"
                                  onClick={(e) => {
                                    setAssetPreview(asset);
                                  }}
                                >
                                  <BsZoomIn size={50} />
                                </button>
                              </div>
                            </CustomCheckbox>
                          </AssetCard>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-sm leading-4">{zeroFill(asset.as_number, 6)}</span>
                        </td>
                        <td className="px-6 py-4">
                          {assetDetailsLink(asset) ? (
                            <Link
                              className="px-2 inline-flex text-sm leading-4 underline text-blue-60 hover:text-blue-70"
                              to={assetDetailsLink(asset) || '#'}
                            >
                              {asset.name}
                            </Link>
                          ) : (
                            <span className="px-2 inline-flex text-sm leading-4 underline cursor-not-allowed text-gray-50">{asset.name}</span>
                          )}
                        </td>
                        <td className="px-6 py-4">
                          <span className="px-2 inline-flex text-sm leading-4">
                            {asset.usable_to ? FormatDate(asset?.usable_to || '', true) : '--.--.----'}
                          </span>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const AssetCard = styled.div`
  border: 1px solid hsl(0deg 0% 80%);
  padding: 0.5rem;

  > label {
    flex-flow: column;
    width: 130px;
    align-items: unset;
    row-gap: 0.5rem;

    > .img-container {
      flex: 0 0 130px;
      height: 130px;
      margin: 0px;
      position: relative;

      .button-zoom {
        width: fit-content;
        opacity: 0;

        &:focus,
        &:hover {
          opacity: 0.9 !important;
        }
      }

      img {
        position: absolute;
        inset: 0px;
        display: block;
        max-height: 100%;
        max-width: 100%;
        margin: auto;
      }

      &:hover {
        .button-zoom {
          cursor: zoom-in;
          opacity: 0.6;
        }
      }
    }
  }
`;
