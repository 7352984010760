const approveUser = {
  page_title: 'WerbeNet Freigabebeantragung',
  allow_access: 'Zugriff auf Apple Vorlagen im WerbeNet für einen Benutzer freigeben',
  infos: 'Der Benutzer ist ein autorisierter Apple Vermarkter.\nHier können Sie den Zugriff auf Apple Vorlagen freigeben.',
  no_request: 'Es gibt keine Anfrage mit der angegebenen ID',
  fields: {
    date: 'Datum',
    name: 'Name',
    user_name: 'Benutzername',
    status: 'Status',
    role: 'Rolle',
    target_group: 'Zielgruppe',
    subgroup: 'Untergruppe',
    message: 'Nachricht',
  },
  reject: 'Ablehnen',
  release: 'Freigeben',
  messages: {
    success: 'Die E-Mail wurde erfolgreich an %{userName} gesendet.',
    error: 'E-Mail konnte nicht an %{userName} gesendet werden.',
  },
} as const;

export default approveUser;
