import { useAppDispatch, useAppSelector } from 'Config';
import { api } from 'Config/api';
import { useToasts } from 'react-toast-notifications';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { authStore } from '../lib';
import { Helmet } from 'react-helmet';
import { SSO_URL } from 'Env';
import { OutlineButton, Skeleton } from 'Components';
import tw from 'twin.macro';
import { useT } from '../translations';
import { Link } from 'react-router-dom';

const Button = tw.button`bg-primary-500 text-xl px-4 py-2 text-white rounded shadow hover:bg-primary-700`;
const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`w-full text-gray-500 focus:text-black border-gray-300 mt-2 border-solid border-2 shadow rounded p-4`, // Add base styles first
  active && tw`text-black`, // Then add conditional styles
]);

export default function ProfilePage() {
  const t = useT();
  const user = useAppSelector(authStore.selectUser);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Record<string, any>>({ ...user });
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState(false);
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    api
      .get(`/api/v2/profiles?filter[user]=${user?.id}&filter[membership]=${user?.membership_id}`, {
        baseURL: SSO_URL,
      })
      .then(({ data }) => {
        setLoading(false);
        setData({
          ...data.data[0].attributes,
          id: data.data[0].id,
        });
      })
      .catch(console.warn);
  }, [user?.id, user?.membership_id]);

  useEffect(() => {
    if (!isEditingDisabled) {
      inputRef.current?.focus();
      setShowButton(true);
    }
  }, [isEditingDisabled]);

  const save = (attributes) => {
    const profileId = data.id;
    let savingEntries = { company: '', phone_number: '', vo_number: '', vp_number: '', data: {} };
    Object.entries(savingEntries).forEach((value) => {
      const [name] = value;
      if (name === 'area') {
        savingEntries.data['area'] = attributes.data['area'];
      } else if (name === 'partner') {
        savingEntries.data['partner'] = attributes.data['partner'];
      } else {
        savingEntries[name] = attributes[name];
      }
    });
    setShowButton(false);
    return dispatch(authStore.editProfile(profileId, savingEntries))
      .then(() => {
        addToast(
          <div role="alert">
            <div>{t.successfully_saved}</div>
          </div>,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
      })
      .catch((newErrors) => {
        addToast(<div role="alert">{t.not_valid}</div>, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  if (loading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }

  return (
    <Container className="page main-page">
      <Helmet defer={false}>
        <title>Profil</title>
      </Helmet>
      <article className={'profile--page flex mt-6'}>
        {loading ? (
          <div className="flex-1 font-bold ">
            <h1 className="text-lg border-b border-solid border-gray-200 py-4 mb-4">{t.personal_data}</h1>
            <Skeleton />
          </div>
        ) : (
          <div className="flex-1 font-bold ">
            <h1 className="text-lg border-b border-solid border-gray-200 py-4 mb-4">{t.personal_data}</h1>
            <div>
              <div className="mb-8">
                <span className={`block text-gray-500`}>{t.full_name}</span>
                <span>{data?.first_name + ' ' + data?.last_name}</span>
              </div>

              <div className="mb-8">
                <span className={`block text-gray-500`}>{t.email}</span>
                <span>{data?.email}</span>
              </div>

              <div className="mb-8">
                <span className={`block text-gray-500`}>{t.created_at}</span>
                <span>{data?.created_at || '--'}</span>
              </div>
            </div>
            <form
              id="myform"
              className="mb-8"
              style={{ maxWidth: 700 }}
              onSubmit={(e) => {
                e.preventDefault();
                save(data);
              }}
            >
              <div className="border-b border-solid border-gray-200 py-4 mt-8 mb-4 flex justify-between">
                <h2 className="text-lg">{t.form_subtitle_one}</h2>
                <button
                  type="button"
                  className="cursor-pointer font-bold text-lg"
                  onClick={() => {
                    setIsEditingDisabled(false);
                  }}
                >
                  {t.edit}
                </button>
              </div>
              <label className="mt-8 block" htmlFor="VO_Nummer">
                <span className="text-gray-500 min-w-[100px] pr-6">{t.fields.vo_number}</span>
                <Input
                  onChange={(e) => {
                    setData({ ...data, vo_number: e.currentTarget.value });
                    setShowButton(true);
                  }}
                  title={t.fields.vo_number}
                  placeholder={t.fields.vo_number}
                  name="vo_number"
                  value={data.vo_number}
                  id="VO_Nummer"
                  disabled={isEditingDisabled}
                  ref={inputRef}
                />
              </label>
              <label className="mt-8 block" htmlFor="VP_Nummer">
                <span className="text-gray-500 min-w-[100px] pr-6">{t.fields.vp_number}</span>
                <Input
                  onChange={(e) => {
                    setData({ ...data, vp_number: e.currentTarget.value });
                    setShowButton(true);
                  }}
                  title={t.fields.vp_number}
                  placeholder={t.fields.vp_number}
                  name="vp_number"
                  value={data.vp_number}
                  id="VP_Nummer"
                  disabled={isEditingDisabled}
                />
              </label>
              <label className="mt-8 block" htmlFor="Telefonnummer">
                <span className="text-gray-500 min-w-[100px] pr-6">{t.fields.telephone}</span>
                <Input
                  id="Telefonnummer"
                  onChange={(e) => {
                    setData({ ...data, phone_number: e.currentTarget.value });
                    setShowButton(true);
                  }}
                  title={t.fields.telephone}
                  placeholder={t.fields.telephone}
                  name="phone_number"
                  type="tel"
                  minLength={8}
                  value={data.phone_number}
                  disabled={isEditingDisabled}
                />
              </label>
              <label className="mt-8 block" htmlFor="Unternehmen">
                <span className="text-gray-500 min-w-[100px] pr-6">{t.fields.company}</span>
                <Input
                  onChange={(e) => {
                    setData({ ...data, company: e.currentTarget.value });
                    setShowButton(true);
                  }}
                  title={t.fields.company}
                  placeholder={t.fields.company}
                  name="company"
                  value={data.company}
                  disabled={isEditingDisabled}
                />
              </label>
              <h2 className="text-lg border-b border-solid border-gray-200 py-4 mt-8 mb-4">{t.form_subtitle_two}</h2>
              <label className="mt-8 block" htmlFor="Bereich">
                <span className="text-gray-500 min-w-[100px] pr-6">{t.fields.area}</span>
                <Input
                  id="Bereich"
                  onChange={(e) => {
                    setData({ ...data, data: { ...data.data, area: e.currentTarget.value } });
                    setShowButton(true);
                  }}
                  title="Bereich"
                  placeholder={t.fields.area}
                  name="area"
                  value={data?.data?.['area'] || ''}
                  disabled={isEditingDisabled}
                />
              </label>
              <label className="mt-8 block" htmlFor="Handelspartner">
                <span className="text-gray-500 min-w-[100px] pr-6">{t.fields.partner}</span>
                <Input
                  id="Handelspartner"
                  onChange={(e) => {
                    setData({ ...data, data: { ...data.data, partner: e.currentTarget.value } });
                    setShowButton(true);
                  }}
                  title={t.fields.partner}
                  name="partner"
                  value={data?.data?.['partner'] || ''}
                  disabled={isEditingDisabled}
                />
              </label>
              <div className={`w-full gap-5 mt-6 flex justify-end items-center ${showButton ? 'block' : 'hidden'}`}>
                <Button type="submit">{t.save}</Button>
                <OutlineButton
                  title={t.abort}
                  aria-label={t.abort}
                  type="button"
                  onClick={() => {
                    setIsEditingDisabled(true);
                    setData({ ...user });
                    setShowButton(false);
                  }}
                  className="outline outline-1 !rounded !mt-0 !font-normal text-xl"
                >
                  {t.abort}
                </OutlineButton>
              </div>
            </form>
          </div>
        )}
        <aside className="ml-4 md:ml-6 lg:ml-8 max-w-[300px] mt-4">
          <h2 className="font-bold mb-8">{t.privacy_policy}</h2>
          <p className="mb-4">
            {`Die Deutsche Telekom AG legt großen Wert auf den Schutz Ihrer persönlichen Daten. Wir informieren Sie stets darüber, welche personenbezogenen Daten wir erheben, verwenden, verarbeiten und wie Sie diese beeinflussen können.`}
          </p>
          <p className="mb-4">
            {'Wenn Sie mehr über die Datenschutzbestimmungen erfahren möchten, rufen Sie unsere Datenschutzrichtlinien über folgenden Link auf:'}
          </p>

          <Link className="text-blue-500 hover:text-blue-600" to="statics/datenschutzerklaerung">
            {t.privacy_policy}
          </Link>
        </aside>
      </article>
    </Container>
  );
}

const Container = styled.div`
  h1 {
    border-bottom: 1px solid #8c8c8c;
    font-size: 18px;
    font-weight: bold;

    span {
      border-bottom: 2px solid var(--primary);
      display: inline-block;
      padding: 0.5em 0;
    }
  }
`;
