const components = {
  abort: 'Abbrechen',
  customAccordion: {
    hide: 'Verbergen',
    show: 'Anzeigen',
  },
  flashMessages: {
    cart: {
      patch_success: 'Artikel wurde(n) erfolgreich in den Downloadkorb gelegt',
      patch_error: 'Artikel konnte(n) nicht in den Downloadkorb gelegt werden',
      delete_success: 'Artikel wurde(n) erfolgreich aus dem Downloadkorb gelöscht',
      delete_error: 'Artikel konnte(n) nicht aus dem Downloadkorb gelöscht werden',
    },
    assets_download: {
      success: 'Artikel wurde(n) erfolgreich heruntergeladen',
      error: 'Artikel konnte(n) nicht heruntergeladen werden',
    },
    assets_share: {
      success: 'Die E-Mail wurde erfolgreich gesendet',
      error: 'Die E-Mail konnte nicht gesendet werden',
    },
    server_failure: 'Ops, Serverfehler!',
    server_500: 'Anfrage fehlgeschlagen. Bitte kontaktieren Sie den Systemadministrator.',
    server_404: 'Keine Route stimmt überein mit',
  },
  inlineSearch: {
    looking_for: 'Was suchen Sie?',
    search: 'Suchen',
  },
  modal: {
    close: 'Schließen',
  },
  progressBar: {
    label: 'Herunterladen:',
    close: 'Schließen',
  },
  pagintaion: {
    first_page: 'Erste Seite',
    previous_page: 'Vorherige Seite',
    page: 'Seite',
    next_page: 'Nächste Seite',
    last_page: 'Letzte Seite',
    out_of: 'von',
  },
} as const;

export default components;
