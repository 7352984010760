import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion';
import '@reach/accordion/styles.css';
import './index.css';
import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { useT } from '../translations';
import styled from 'styled-components';
import tw from 'twin.macro';

interface AccordionProps {
  items: Array<{
    label: string;
    panelContent: string | React.ReactNode;
    props?: any;
  }>;
}

export function CustomAccordion(props: AccordionProps) {
  const t = useT();
  const { items } = props;
  const [indices, setIndices] = React.useState<number[]>([]);
  const toggleItem = (toggledIndex) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
    } else {
      setIndices([...indices, toggledIndex]);
    }
  };
  return (
    <Accordion collapsible multiple onChange={toggleItem} className="flex flex-col gap-1">
      {items.map((item, index) => (
        <AccordionItem key={`accordion-item-${index}`} className="bg-white rounded-md" {...item.props}>
          <AccordionButton
            title={indices.includes(index) ? t.customAccordion.hide : t.customAccordion.show}
            aria-label={indices.includes(index) ? t.customAccordion.hide : t.customAccordion.show}
            className="relative inline-flex items-center gap-2 w-full py-2 px-4 text-xl font-extrabold text-left transition hover:text-primary-500 hover:bg-gray-0"
          >
            {indices.includes(index) ? (
              <IconContainer>
                <BsChevronRight size={16} className="stroke-[0.5px] rotate-90" />
              </IconContainer>
            ) : (
              <span className="inline-flex items-center w-[18px]">
                <BsChevronRight size={16} className="stroke-[0.5px]" />
              </span>
            )}
            <span>{item.label}</span>
          </AccordionButton>
          <AccordionPanel className={`${indices.includes(index) ? 'inline-flex' : 'hidden'} items-center gap-2 w-full px-4 pt-2 pb-8`}>
            <IconContainer className="opacity-0"></IconContainer>
            {item.panelContent}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

const IconContainer = styled.span`
  ${tw`inline-flex items-center w-[18px] min-w-[18px]`}
`;
