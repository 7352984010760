const homePage = {
  learn_more: 'Learn more',
  image: 'image',
  last_edited: 'Last edited',
  last_added: 'Last added',
  most_used: 'Most used',
  page: 'Page',
  active_page: 'Active Page',
  pagination_label: 'pagination of slides ',
  slider: 'Slider',
  status_batch: {
    publication_stopped: 'Publication stopped',
    publication_expired: 'Publication expired',
    not_published: 'Not published',
    archived: 'Archived',
    draft: 'Draft',
    require_approval: 'Require Approval',
  },
} as const;

export default homePage;
