/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { client } from 'Config';
import { BreadCrumb, Page, Spinner } from 'Components';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

export default function DocumentsPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Documents[]>([]);

  useEffect(() => {
    setLoading(true);
    client
      .GETDocuments()
      .then(({ data }) => setData(data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Page key={'page-docs'}>
      <Helmet defer={false}>
        <title>{'Dokument - WerbeNet'}</title>
      </Helmet>
      <BreadCrumb
        style={{ margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: 'Home',
              link: '/',
            },
            {
              title: 'Dokument',
              link: '/documents',
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      <div className="flex mb-6">
        <h1 className="font-extrabold text-4xl">{'Dokument'}</h1>
      </div>

      {loading ? (
        <Spinner size={60} />
      ) : (
        <Grid>
          {data.map((item) => (
            <div key={item.id} className="shadow-md max-w-md border-solid border border-gray-300 text-left rounded bg-white">
              <h2 className="font-bold text-left text-3xl border-solid border-b border-gray-300 py-4 px-8">{item.name}</h2>
              <p className="p-8 text-left ">{item.description}</p>
              <div className="justify-center items-center flex pb-4">
                <a href={item.file_url} className="bg-primary-500 rounded text-center p-4 text-white">
                  {'Dokument herunterladen'}
                </a>
              </div>
            </div>
          ))}
        </Grid>
      )}
    </Page>
  );
}

const Grid = styled.div`
  border: 0px;
  display: grid;
  gap: 1rem 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 473px));
  -webkit-box-pack: center;

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`;
