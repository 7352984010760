import { client } from 'Config';
import { CategoryField } from 'Features/Shop';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { ReactSVG } from 'react-svg';
import { API_URL } from 'Env';

export const getSVG = (icon): JSX.Element => {
  return (
    <ReactSVG
      src={`${API_URL.slice(0, -8)}${icon?.image}`}
      beforeInjection={(svg) => {
        svg.setAttribute('width', '16px');
        svg.setAttribute('height', '16px');
        svg.setAttribute('fill', 'currentColor');
      }}
    />
  );
};

export function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    value && formData.append(parentKey, value);
  }
}

export const generateBlobFromPDF = async (): Promise<Blob> => {
  const printSelector = document?.querySelector('#print') as HTMLElement;
  let blob = new Blob();
  await html2canvas(printSelector, {
    scrollX: 0,
    scrollY: 0,
    scale: 4,
    useCORS: true,
    allowTaint: true,
  }).then(async function (canvas) {
    let imgData = canvas.toDataURL('image/png', 1);
    let imageHeight = canvas.height;
    let imageWidth = canvas.width;

    const isPortrait = imageWidth < imageHeight;
    let pdf = new JsPDF({
      orientation: isPortrait ? 'p' : 'l',
      unit: 'mm',
      format: [120, (imageHeight / imageWidth) * 120],
      putOnlyUsedFonts: true,
    });
    let pageWidth = pdf.internal.pageSize.getWidth();

    let pageHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgData, 'png', 0, 0, pageWidth, pageHeight);

    const printSeconPgeSelector = document?.querySelector('#print-page-2') as HTMLElement;
    if (printSeconPgeSelector) {
      await html2canvas(printSeconPgeSelector, { scrollX: 0, scrollY: 0, scale: 4, useCORS: true, allowTaint: true }).then(function (
        canvasLegalText
      ) {
        let imgSecondData = canvasLegalText.toDataURL('image/png', 1);
        let imageSecondHeight = canvasLegalText.height;
        let imageSecondWidth = canvasLegalText.width;

        const isPortrait = imageSecondWidth < imageSecondHeight;
        pdf.addPage([120, (imageSecondHeight / imageSecondWidth) * 120], isPortrait ? 'p' : 'l');

        let pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgSecondData, 'png', 0, 0, pageWidth, pageHeight);
      });
    }

    blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });
  });

  return blob;
};

export const getNewCategoryFields = async (
  allCategoryFields: (FieldType & {
    multiple: boolean;
    required: boolean;
  })[],
  hidden: Set<string>
): Promise<CategoryField[]> => {
  let newCategoryFields: Array<CategoryField> = [];
  await client.GETfields(allCategoryFields?.map((r) => r.key)).then(({ data }) => {
    const newOptions = data.reduce((a: Record<string, FieldOption[]>, c) => {
      const optionValues: FieldOption[] = a[c.field_type] ? a[c.field_type].concat(c) : [c];
      return {
        ...a,
        [c.field_type]: optionValues.sort((a, b) => {
          if (a.sorting_order === b.sorting_order) return a.title.localeCompare(b.title);
          if (a.sorting_order === 0 || a.sorting_order === undefined) return 1;
          if (b.sorting_order === 0 || b.sorting_order === undefined) return -1;
          return a.sorting_order - b.sorting_order;
        }),
      };
    }, {});

    Object.entries(newOptions).forEach(([optionKey, optionValue]) => {
      const categoryFields = allCategoryFields.find((category) => category.key === optionKey);
      if (!hidden.has(optionKey) && optionValue.length > 0 && categoryFields !== undefined) {
        newCategoryFields.push({ ...categoryFields, options: optionValue });
      }
    });
  });

  return newCategoryFields;
};

export const COLORS = [
  'var(--primary)',
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
];

export const modules = {
  toolbar: [['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ color: COLORS }], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
  clipboard: {
    matchVisual: false,
  },
};

export const modulesEmty = {
  toolbar: [[], [], [], []],
  clipboard: {
    matchVisual: false,
  },
};
