import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useT } from '../translations';
import { breakpoint } from 'Helpers';
import styled from 'styled-components';
import { BsCartPlus, BsCartDash } from 'react-icons/bs';
import { CustomLink, DownloadProgress, ProgressBarProps } from 'Components';
import { ModalTermsOfUse } from './ModalTermsOfUse';
import { downloadSelectedAssets } from '../lib/actions';
import { BACKEND_BASE_URL } from 'Env';
import { getAssetCustomDimensionsDownload } from 'Components/MatomoGateway/customDimensions';
interface AssetsLinksProps {
  ids: string[];
  assetsSelected?: Asset[];
  termsOfUse: boolean;
  assetDetails?: 'add' | 'remove';
  addToCartDisabled?: boolean;
  selectAll?: () => void;
  addToCart?: () => void;
  removeFromCart?: () => void;
}

export function AssetsLinks({
  termsOfUse,
  ids,
  assetsSelected,
  selectAll,
  addToCart,
  removeFromCart,
  assetDetails,
  addToCartDisabled,
}: AssetsLinksProps) {
  const t = useT();
  const { addToast } = useToasts();
  const location = useLocation();
  const [modalTermsOfUse, setModalTermsOfUse] = useState(false);
  const [loadedSize, setLoadedSize] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [progressBarProps, setProgressBarProps] = useState<ProgressBarProps>();

  const copyAssetLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        addToast(
          <div role="alert">
            <div>{t.copy_link_success}</div>
          </div>,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
      })
      .catch(() => {
        addToast(<div role="alert">{t.copy_link_failed}</div>, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const onDownloadProgress = useCallback(
    (progressEvent) => {
      progressEvent.loaded && setLoadedSize(progressEvent.loaded);
    },
    [setLoadedSize]
  );

  const trackDownload = (lowResolution: boolean = false, assets: AssetDownloaded[]) => {
    var _paq = (window._paq = window._paq || []);
    assets?.forEach((asset) => {
      const customDimensions = getAssetCustomDimensionsDownload(asset.as_number, asset.name, asset.category_name, asset.fields);
      let dimensions = {};
      customDimensions.forEach((dim) => {
        dimensions[`dimension${dim.id}`] = dim.value;
      });

      _paq.push([
        'trackEvent',
        ['Download-basket', lowResolution ? 'low-res' : 'high-res'].join(' '),
        `${BACKEND_BASE_URL}/admin/assets/${asset.id}`,
        asset.name,
        1,
        { ...dimensions },
      ]);
    });
  };

  const onDownloadSelectedAssets = (lowResolution: boolean = false) => {
    setShowProgress(true);
    setLoadedSize(0);
    setProgressBarProps({ ...progressBarProps, inProgress: true, complete: false, error: false, message: undefined });
    downloadSelectedAssets(ids, lowResolution, onDownloadProgress)
      .then((data) => {
        setProgressBarProps({ ...progressBarProps, inProgress: false, complete: true, message: t.progressBar.download_is_finished });
        trackDownload(lowResolution, data.assets ?? []);
      })
      .catch((e) => {
        setProgressBarProps({ ...progressBarProps, inProgress: false, error: true, message: t.progressBar.download_failed });
      });
  };

  return (
    <>
      <ModalTermsOfUse
        visible={modalTermsOfUse}
        onRequestClose={() => setModalTermsOfUse(false)}
        onDownload={() => {
          onDownloadSelectedAssets();
          setModalTermsOfUse(false);
        }}
      ></ModalTermsOfUse>
      <AssetsLinksContainer className="flex items-center gap-y-4 gap-x-8 ">
        <div id="assets-links" className="flex flex-wrap items-center gap-y-4 gap-x-8">
          {!assetDetails && (
            <CustomLink classes="w-44 whitespace-nowrap" onClick={selectAll} label={ids.length ? t.options.reset_selection : t.options.select_all} />
          )}
          {termsOfUse ? (
            <CustomLink
              classes="whitespace-nowrap"
              disabled={!ids.length}
              onClick={() => {
                setModalTermsOfUse(!modalTermsOfUse);
              }}
              label={t.options.download_request}
            />
          ) : (
            <>
              <CustomLink
                classes="whitespace-nowrap"
                disabled={!ids.length}
                onClick={() => {
                  onDownloadSelectedAssets();
                }}
                label={t.options.download_medium}
              />
              <CustomLink
                classes="whitespace-nowrap"
                disabled={!ids.length}
                onClick={() => {
                  onDownloadSelectedAssets(true);
                }}
                label={t.options.download_low_resolution}
              />
              <CustomLink
                classes="whitespace-nowrap"
                disabled={!ids.length}
                href={{ pathname: `/email/asset${assetDetails ? `/${ids[0]}` : ''}` }}
                state={{ prevPath: location.pathname }}
                label={t.options.download_link}
              />
            </>
          )}
          <CustomLink classes="whitespace-nowrap" onClick={() => copyAssetLink()} label={t.options.copy_asset_link} />
        </div>
        {!termsOfUse &&
          (assetDetails === 'remove' ? (
            <button
              id="btn-remove-cart"
              title={t.remove_from_shopping_cart}
              aria-label={t.remove_from_shopping_cart}
              onClick={removeFromCart}
              className="flex text-primary-500 bg-white justify-center text-center items-center rounded-xl px-4 py-2 border-2 border-primary-500  disabled:bg-primary-300 disabled:border-primary-300 disabled:cursor-default"
              disabled={!ids.length || addToCartDisabled}
            >
              <BsCartDash size={22} />
            </button>
          ) : (
            <button
              id="btn-add-cart"
              onClick={addToCart}
              disabled={!ids.length || addToCartDisabled}
              title={t.add_to_shopping_cart}
              aria-label={t.add_to_shopping_cart}
              className="flex text-white bg-primary-500 justify-center text-center items-center rounded-xl px-4 py-2 border-2 border-primary-500 disabled:bg-primary-300 disabled:border-primary-300 disabled:cursor-default"
            >
              <BsCartPlus size={22} />
            </button>
          ))}
      </AssetsLinksContainer>

      <DownloadProgress
        loadedSize={loadedSize}
        progressBarProps={progressBarProps}
        showProgress={showProgress}
        hideDownloadProgress={() => setShowProgress(false)}
      ></DownloadProgress>

      {termsOfUse && (
        <div className="mb-8">
          <p className="text-base font-bold">{t.terms_of_use_note}</p>
          <p className="text-base">{t.terms_of_use_info}</p>
        </div>
      )}
    </>
  );
}

const AssetsLinksContainer = styled.div`
  #btn-add-cart,
  #btn-remove-cart {
    margin-left: auto;
  }

  @media (max-width: ${breakpoint.size.lg}) {
    #assets-links a,
    #assets-links button {
      flex: 1 1 calc(50% - 2rem);
    }
  }

  @media (max-width: ${breakpoint.size.med}) {
    flex-flow: wrap;
    #assets-links a,
    #assets-links button {
      flex: 1 1 100%;
    }
    #btn-add-cart,
    #btn-remove-cart {
      flex: 1 1 100%;
    }
  }
`;
