import { VisuallyHidden } from '@reach/visually-hidden';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import { useT } from '../translations';

type CustomProp = { totalPages: number } | { pageSize: number };
interface PagintaionProps {
  totalPages: number;
  totalCount: number;
  currentPage: number;
  onSelect: (n: number) => void;
  loading?: boolean;
}

type Props = PagintaionProps & CustomProp;

export const Pagintaion: React.FC<PagintaionProps> = (props: Props) => {
  const t = useT();
  const { loading, totalCount, currentPage, onSelect } = props;
  const totalPages = 'pageSize' in props ? Math.ceil(totalCount / props.pageSize) : props.totalPages;

  if (!totalCount || loading) {
    return null;
  }
  const className = (active: boolean) => (active ? 'text-white' : 'border-gray-200 bg-white text-black');

  if (totalPages === 1) {
    return null;
  }
  const gotoButtonsDisabled = {
    first: currentPage <= 2 || !currentPage,
    prev: currentPage - 1 < 1 || !currentPage,
    next: currentPage + 1 > totalPages,
    last: currentPage >= totalPages - 1 || !totalPages,
  };
  return (
    <nav className="flex flex-row flex-nowrap items-center mt-8 mb-16" aria-label="Pagination">
      <button
        className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
        title={t.pagintaion.first_page}
        aria-label={t.pagintaion.first_page}
        disabled={gotoButtonsDisabled.first}
        onClick={() => onSelect(1)}
      >
        <VisuallyHidden>{t.pagintaion.first_page}</VisuallyHidden>
        <BsChevronDoubleLeft />
      </button>
      <button
        className={`flex w-10 h-10 mr-1 justify-center items-center rounded-full border disabled:hidden hover:border-gray-300 ${className(false)}`}
        title={t.pagintaion.previous_page}
        aria-label={t.pagintaion.previous_page}
        disabled={gotoButtonsDisabled.prev}
        onClick={() => onSelect(currentPage - 1)}
      >
        <VisuallyHidden>{t.pagintaion.previous_page}</VisuallyHidden>
        <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
          <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
        </svg>
      </button>
      {currentPage - 2 > 0 ? (
        <button
          className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
          title={`${t.pagintaion.page} ${currentPage - 2}`}
          aria-label={`${t.pagintaion.page} ${currentPage - 2}`}
          onClick={() => onSelect(currentPage - 2)}
        >
          {currentPage - 2}
        </button>
      ) : null}
      {currentPage - 1 > 0 ? (
        <button
          className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
          title={`${t.pagintaion.page} ${currentPage - 1}`}
          aria-label={`${t.pagintaion.page} ${currentPage - 1}`}
          onClick={() => onSelect(currentPage - 1)}
        >
          {currentPage - 1}
        </button>
      ) : null}
      <button
        className={`hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border pointer-events-none  ${className(true)}`}
        style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}
        aria-current="page"
        title={`${t.pagintaion.page} ${currentPage}`}
        aria-label={`${t.pagintaion.page} ${currentPage}`}
      >
        {currentPage}
      </button>
      {currentPage + 1 <= totalPages ? (
        <button
          className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
          title={`${t.pagintaion.page} ${currentPage + 1}`}
          aria-label={`${t.pagintaion.page} ${currentPage + 1}`}
          onClick={() => onSelect(currentPage + 1)}
        >
          {currentPage + 1}
        </button>
      ) : null}
      {currentPage + 2 <= totalPages ? (
        <button
          className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
          title={`${t.pagintaion.page} ${currentPage + 2}`}
          aria-label={`${t.pagintaion.page} ${currentPage + 2}`}
          onClick={() => onSelect(currentPage + 2)}
        >
          {currentPage + 2}
        </button>
      ) : null}
      <button
        className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
        title={t.pagintaion.next_page}
        aria-label={t.pagintaion.next_page}
        disabled={gotoButtonsDisabled.next}
        onClick={() => onSelect(currentPage + 1)}
      >
        <VisuallyHidden>{t.pagintaion.next_page}</VisuallyHidden>
        <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
          <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" />
        </svg>
      </button>
      <button
        disabled={gotoButtonsDisabled.last}
        className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
        title={t.pagintaion.last_page}
        aria-label={t.pagintaion.last_page}
        onClick={() => onSelect(totalPages)}
      >
        <VisuallyHidden>{t.pagintaion.last_page}</VisuallyHidden>
        <BsChevronDoubleRight />
      </button>
      <div className="flex ml-4 h-10 justify-center items-center  bg-white  text-black">
        {currentPage} {t.pagintaion.out_of} {totalPages}
      </div>
    </nav>
  );
};
