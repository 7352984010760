const shopPage = {
  page_title: 'My Download Basket - WerbeNet',
  all_categories: 'All categories',
  back: 'Dack',
  search: 'Search',
  keyword_search: 'Keyword Search',
  search_for: 'Search for',
  title: 'Download basket',
  DESC: 'DESC',
  ASC: 'ASC',
  date: 'Date',
  name: 'Name',
  period_from: 'Usage period from',
  period_to: 'Usage period until',
  filter_noun: 'Filter',
  filter: 'Filters',
  reset_filter: 'Reset Filters',
  approve_modal: {
    approve: 'Approve',
    reject: 'Reject',
    confirm: 'Confirm',
    cancel: 'Cancel',
    confirm_approval: 'Confirmation of approval',
    reject_approval: 'Rejection of approval',
    message: 'Are you sure you want to continue with this action?',
    reason: 'Please state your reason!',
    reason_placeholder: 'Reason for rejection',
  },
  succesMessageOnReject: 'EyeCatcher status was rejected!',
  successMessageOnApprove: 'EyeCatcher was approved with success!',
  no_assets: {
    filter: 'No Results Found.',
    category: 'The category does not contain any articles.',
  },
  all_products: 'All promotional items',
  terms_of_use_note: 'Please note:',
  terms_of_use_info:
    'HD commercials cannot be downloaded in low resolution. In addition, a direct download is not possible for licensing reasons. As soon as you click on “Send download request” above, a popup will appear with the corresponding terms of use. Please read through the terms of use and confirm receipt. You can then download the file immediately.',
  terms_of_use: {
    special_rules: 'Special rules apply to the use of Telekom electronic media.',
    title: 'Your download request',
    body_title: 'Dear Werbenet User,',
    rules: {
      info: 'Special rules apply to the use of Telekom electronic media (such as TV spots). By downloading this file you accept the conditions for the use of this media:',
      1: 'The electronic media provided are to be used exclusively at the POS. Any other use is not permitted.',
      2: 'The content is protected by license and must be removed immediately after the expiry date. In the event of non-compliance, we reserve the right to pass on any penalty buyouts to you.',
      3: 'The costs (GEZ, GEMA, etc.) for the use of the electronic media made available here are to be borne by the marketing partner.',
      4: 'Only media that are available for download here may be shown.',
      5: 'The media must not be changed',
      6: 'The electronic media may only be passed on after prior approval.',
      7: 'Failure to comply with these requirements may result in the download authorization being blocked!',
    },
    regards: 'Kind regards',
    telekom: 'Telekom Deutschland GmbH',
    confirm: 'I confirm that I have read and understood the terms of use.',
    download: 'Download',
  },
  options: {
    select_all: 'Select All',
    download_request: 'Send download request',
    reset_selection: 'Reset selection',
    download_medium: 'Download medium',
    download_low_resolution: 'Download in low resolution',
    download_link: 'Send download link via email',
    copy_asset_link: 'Copy asset link',
  },
  copy_link_success: 'URL has been copied to the clipboard successfully',
  copy_link_failed: 'URL could not be copied to the clipboard',
  remove_from_shopping_cart: '"Remove from the shopping cart',
  add_to_shopping_cart: 'Add to shopping cart',
  information: 'Information',
  select: 'Select',
  deselect: 'Deselect',
  as_nummer: 'AS-Number',
  usage_period_start: 'Start of usage period',
  usage_period_end: 'End of usage period',
  properties: 'Properties',
  other_file: 'Other file formats of the medium',
  detailed_view: 'Detailed view of the medium',
  file_properties: 'File properties',
  details: {
    resolution: 'Resolution (%{unit}):',
    file_extension: 'File extension:',
    size: 'Document size (in cm / inch):',
    file_size_MB: 'File size (in MB):',
    file_size: 'File size:',
    print_size: 'Dimensions [h x w]:',
    color_space: 'Color space:',
    download: 'Download',
  },
  advertising_materials: 'Advertising Materials',
  note: 'Note',
  zoom_image: 'Enlarge image',
  tabel: {
    selectAll: 'Select All',
    select: 'Select',
    assetNumber: 'AS-Number',
    name: 'Name',
    keyWords: 'Key Words',
  },
  overview: {
    partnernet: {
      id: 'partnernet',
      label: 'Partnernet',
    },
    magenta_smart: {
      id: 'magenta_smart',
      label: 'Magenta SmartHome',
    },
  },
  links: {
    delete: {
      link: '#link1',
      label: 'Delete from download basket',
    },
    download_link: {
      link: '#link2',
      label: 'Send download link via email',
    },
    download_low_resolution: {
      link: '#link3',
      label: 'Download in low resolution',
    },
  },
  download: 'Download',
  date_format: 'dd.mm.yyyy',
  progressBar: {
    download_is_finished: 'Download is finished',
    download_failed: 'Download Failed',
  },
  no_options_available: 'No option available',
  assetStatus: {
    published: 'Published',
    not_published: 'Not published',
    publication_expired: 'Publication expired',
    publication_stopped: 'Publication stopped',
    publication_planned: 'Publication planned',
    archived: 'Archived',
    require_approval: 'Require approval',
    draft: 'Draft',
  },
} as const;

export default shopPage;
