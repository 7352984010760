import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formattCategoryNameRoute } from 'Helpers';

export interface CategoryState {
  data: Category[];
  byParent: Record<string, Array<string>>;
  byId: Record<string, Category>;
  byName: Record<string, Category>;
  locale: Record<string, FieldType>;
}

function buildItem(id: string, cache: CategoryState) {
  const row = cache.byId[id];

  if (cache.byParent[row.id]) {
    row.children = cache.byParent[row.id].map((id) => buildItem(id, cache));
  }
  return row;
}

export const initialState: CategoryState = {
  data: [],
  locale: {},
  byParent: {},
  byId: {},
  byName: {},
};

export const { actions, reducer, name } = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setFields: (state, action: PayloadAction<FieldType[]>) => {
      state.locale = action.payload.reduce((c, i) => {
        c[i.key] = i;
        return c;
      }, {});
    },
    set: (state, action: PayloadAction<Category[]>) => {
      state.data = action.payload;
      state.byParent = {};
      state.byId = {};
      state.byName = {};
      action.payload.forEach((element) => {
        if (!state.byParent[element.parent_id || 'null']) {
          state.byParent[element.parent_id || 'null'] = [];
        }
        state.byId[element.id] = element;
        state.byParent[element.parent_id || 'null'].push(`${element.id}`);

        const categoryName = formattCategoryNameRoute(element.title);
        if (element.title && !state.byName[categoryName]) {
          state.byName[categoryName] = element;
        }
      });

      state.byParent['null'].forEach((id) => buildItem(id, state));
      return state;
    },
  },
});

export const slice = (state: RootState): CategoryState => state[name] || initialState;

export const selectCategories = (state: RootState) => {
  return slice(state).data;
};
export const selectCategoriesById = (state: RootState) => {
  return slice(state).byId;
};
export const selectCategoriesByName = (state: RootState) => {
  return slice(state).byName;
};
export const selectAppNavItems = (state: RootState) => (state[name].byParent['null'] || []).map((id) => state[name].byId[id]);

export const selectCategory = (id: string) => (state: RootState) => {
  return slice(state).byId[id];
};

export const selectFieldsLocale = (state: RootState) => state[name].locale;

export const selectFields = (categoryId?: string) => (state: RootState) => {
  if (!categoryId) {
    return null;
  }
  let category = selectCategory(categoryId)(state);
  if (!category) {
    return null;
  }
  while (!category.fields && category.parent_id) {
    category = selectCategory(`${category.parent_id}`)(state);
  }

  return category.fields;
};

export const selectCategoryFilters = (categoryId: string) => (state: RootState) => {
  const category = selectFields(categoryId)(state);
  if (!category) {
    return null;
  }

  const T = selectFieldsLocale(state);
  const returnValue = [] as Array<FieldType & { multiple: boolean; required: boolean }>;
  for (const key in category) {
    if (Object.prototype.hasOwnProperty.call(category, key)) {
      const element = category[key];
      if (!element.hidden && T[key]) {
        returnValue.push({
          ...T[key],
          ...category[key],
          multiple: !!element.multiple,
          required: !!element.required,
        });
      }
    }
  }

  return returnValue;
};

export const selectAllCategoryFiltersIncludingHidden = (categoryId: string) => (state: RootState) => {
  const category = selectFields(categoryId)(state);
  if (!category) {
    return null;
  }

  const T = selectFieldsLocale(state);
  const returnValue = [] as Array<FieldType & { multiple: boolean; required: boolean }>;
  for (const key in category) {
    if (Object.prototype.hasOwnProperty.call(category, key)) {
      const element = category[key];

      returnValue.push({
        ...T[key],
        ...category[key],
        multiple: !!element.multiple,
        required: !!element.required,
      });
    }
  }

  return returnValue;
};
