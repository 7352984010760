type ComponentProps = {
  fillLeft?: string;
  fillRight?: string;
};

const ActionSortIcon: React.FC<ComponentProps> = ({ fillLeft, fillRight }) => (
  <svg className="scale-icon mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <title>sort</title>
    <g fill="currentColor">
      <g>
        <path
          d="M 17.25 4 z m -10.5 -0.75 l 4.4 6.75 H 7.5 v 9.25 c 0 0.4 -0.35 0.75 -0.75 0.75 S 6 19.65 6 19.25 V 10 H 2.35 z"
          fillRule="evenodd"
          fill={fillLeft}
        ></path>
        <path
          d="M 17.25 4 c 0.4 0 0.75 0.35 0.75 0.75 V 14 h 3.65 l -4.4 6.75 l -4.4 -6.75 h 3.65 V 4.75 c 0 -0.4 0.35 -0.75 0.75 -0.75 z z"
          fillRule="evenodd"
          fill={fillRight}
        ></path>
      </g>
    </g>
  </svg>
);

export default ActionSortIcon;
