import { Tabs, TabList, Tab, TabPanels, TabPanel, TabsOrientation } from '@reach/tabs';
import '@reach/tabs/styles.css';
import styled from 'styled-components';
import tw from 'twin.macro';

import React from 'react';

interface TabsProps {
  Icon?: React.ReactNode;
  label?: string;
  tabIndex?: number;
  items: Array<{
    icon?: React.ReactNode;
    label: string;
    props?: any;
    tabContent: React.ReactNode;
  }>;
  handleTabsChange?(tabIndex: number): void;
}

export function CustomTabs(props: TabsProps) {
  const { tabIndex, items, handleTabsChange } = props;
  return (
    <Container index={tabIndex} onChange={handleTabsChange} orientation={TabsOrientation.Horizontal}>
      <TabList>
        {(items || []).map((item, index) => (
          <Tab key={`tab_${index}`} className="font-bold disabled:text-gray-20">
            {item.label}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {(items || []).map((item, index) => (
          <TabPanel key={`tab_panel_${index}`} tabIndex={-1}>
            {item.tabContent}
          </TabPanel>
        ))}
      </TabPanels>
    </Container>
  );
}

const Container = styled(Tabs)`
  [data-reach-tab-list] {
    flex-wrap: wrap;
    background: unset !important;
    column-gap: 2rem;
    row-gap: 0.5rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    padding-bottom: 1.5rem;
  }

  [data-reach-tab] {
    position: relative;

    &::after {
      left: 0px;
      right: 0px;
      width: 100%;
      bottom: 0px;
      content: '';
      display: block;
      position: absolute;

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 2px;
      ${tw`bg-gray-20`}
    }

    &:hover {
      ${tw`text-primary-500`}
      &::after {
        ${tw`bg-primary-500`}
      }
    }
  }
  [data-reach-tab]:disabled {
    cursor: text;
    opacity: 1;
    ${tw`text-gray-20`}
    &:hover {
      ${tw`text-gray-20`}
      &::after {
        ${tw`bg-gray-20`}
      }
    }
  }
  [data-selected] {
    ${tw`text-primary-500`}
    &::after {
      height: 4px;
      ${tw`bg-primary-500`}
    }
  }
`;
