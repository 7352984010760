import { RootState } from 'Config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CategoryState {
  // @TODO type the slides
  data: Slide[];
}

export const initialState: CategoryState = {
  data: [],
};

const slice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Slide[]>) => {
      state.data = action.payload;
    },
  },
});

export const { actions, reducer, name } = slice;

export const selectSlice = (state: RootState) => state[name];

export const selectSlides = (state: RootState) => selectSlice(state).data;
