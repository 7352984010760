import { APP_UID, SSO_URL } from 'Env';
import React from 'react';

export default function LoginPage() {
  sessionStorage.setItem('originalpath', window.location.pathname);

  React.useLayoutEffect(() => {
    const elm: any = document.querySelector('#login-form');
    if (elm && 'submit' in elm) {
      elm.submit();
    }
  }, []);

  return (
    <form id="login-form" action={`${SSO_URL}oauth/authorize`} method="GET">
      <input type="hidden" name="client_id" value={APP_UID} />
      <input type="hidden" name="redirect_uri" value={`${window.location.protocol}//${window.location.host}/oauth/callback`} />
      <input type="hidden" name="response_type" value="code" />
    </form>
  );
}
