import React, { useEffect, useState, ReactNode, KeyboardEventHandler } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { Logo } from '../Logo';
import { breakpoint, formattCategoryNameRoute } from 'Helpers';
import Masonry from 'react-masonry-css';
import { useT } from './translations';
import { useEyeCatcherRoles } from 'Helpers/useEyeCatcherRoles';

/**
 * Defaultlayout expect exactly 2 children
 * - search
 * - nav menu
 */

export function AppNavbar({ hide, data, showSearch, children }: { children: ReactNode[]; data: Category[]; showSearch: boolean; hide: boolean }) {
  let onFocusTimeout: NodeJS.Timeout; //timer identifier
  let onLeaveTimeout: NodeJS.Timeout; //timer identifier
  let delayInterval = 300; //time in ms

  const t = useT();
  const location = useLocation();
  const [ihide, i] = useState(hide);
  const [idx, focus] = useState(-1);
  const refNavMenu = React.useRef<HTMLElement>(null);
  const refMenu = React.useRef<HTMLElement>(null);
  const { isCreator } = useEyeCatcherRoles();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => i(hide), 300);
  }, [hide]);

  const selfServiceCategory = {
    id: 'service-category',
    title: t.serviceCategory.selfService,
    children: [
      {
        label: t.serviceCategory.faqs,
        href: '/faq',
        disabled: false,
      },
      {
        label: t.serviceCategory.documents,
        href: '/documents',
      },
      {
        label: t.serviceCategory.apply_for_apple,
        href: '/approval',
        disabled: false,
      },
    ],
  };
  const selfServiceCategoryActive = selfServiceCategory.children.some((item) => item.href === location.pathname);

  const eyeCatcher = {
    id: 'eye-catcher',
    title: t.eyeCatcher,
    href: '/eyecatcheroverview',
  };
  const regexEyeCatcher = /^\/eyecatcher/;
  const isEyeCatcherPage = regexEyeCatcher.test(location.pathname);

  const tab: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!refMenu.current || !refNavMenu.current) return;
    const links = refMenu.current.querySelectorAll<HTMLAnchorElement>('div > [tabIndex]');
    const focusLink = refMenu.current.querySelector<HTMLAnchorElement>('div > [tabIndex]:focus');
    var navMenuButtons = refNavMenu.current?.querySelectorAll<HTMLButtonElement>('button');
    let newFocusIndex = -1;
    links.forEach((link, index) => {
      if (link === focusLink) {
        newFocusIndex = index;
      }
    });
    switch (e.key) {
      case 'Tab':
        e.shiftKey ? newFocusIndex-- : newFocusIndex++;
        break;
      case 'ArrowDown':
        newFocusIndex++;
        break;
      case 'ArrowUp':
        newFocusIndex--;
        break;
      case 'Escape':
        return navMenuButtons && navMenuButtons[idx].focus();
      default:
        return;
    }

    e.stopPropagation();
    e.preventDefault();
    if (newFocusIndex > links.length) {
      links[0].focus();
    } else if (newFocusIndex < 0) {
      links[links.length - 1].focus();
    } else if (!links[newFocusIndex]) {
      links[0].focus();
    } else {
      links[newFocusIndex].focus();
    }
  };

  return (
    <Container className={`${ihide ? 'hide' : ''} ${idx !== -1 ? 'open' : ''}`}>
      <div className="border-b border-gray-200">
        <div className="page">
          {showSearch ? (
            <>{children[0] || null}</>
          ) : (
            <>
              <Link
                to="/"
                title={t.go_home_page}
                aria-label={t.go_home_page}
                className={`logo ${!ihide ? 'pointer-events-none mr-0 invisible' : 'mr-4'}`}
                tabIndex={ihide ? 0 : -1}
                aria-hidden={ihide}
              >
                <Logo aria-hidden={ihide} height={28} />
              </Link>
              <NavMenu
                className="nav flex-1"
                ref={refNavMenu}
                onMouseLeave={(e) => {
                  clearTimeout(onLeaveTimeout);
                  onLeaveTimeout = setTimeout(function () {
                    try {
                      if (
                        refMenu.current &&
                        e.relatedTarget &&
                        !(refMenu.current.contains(e.relatedTarget as Node) || (e.relatedTarget as HTMLElement).contains(refMenu.current))
                      ) {
                        focus(-1);
                      }
                    } catch (error) {
                      focus(-1);
                    }
                  }, delayInterval);
                }}
              >
                <ul className="flex ml-[-1em]">
                  {data.map((category, _idx) => (
                    <Li
                      key={category.id}
                      className={idx === _idx ? 'active' : ''}
                      onFocus={() => focus(_idx)}
                      onBlur={(e) => {
                        clearTimeout(onLeaveTimeout);
                        onLeaveTimeout = setTimeout(function () {
                          try {
                            if (
                              refMenu.current &&
                              e.relatedTarget &&
                              !(refMenu.current.contains(e.relatedTarget as Node) || (e.relatedTarget as HTMLElement).contains(refMenu.current))
                            ) {
                              if (idx !== -1 && _idx !== idx) focus(-1);
                            }
                          } catch (error) {
                            focus(-1);
                          }
                        }, delayInterval);
                      }}
                      onMouseOver={(e) => {
                        clearTimeout(onFocusTimeout);
                        onFocusTimeout = setTimeout(function () {
                          var hoverRefMenu = refMenu.current && refMenu.current.querySelector<HTMLAnchorElement>(':hover');
                          if (!hoverRefMenu) focus(_idx);
                        }, delayInterval);
                      }}
                      onMouseLeave={(e) => {
                        clearTimeout(onLeaveTimeout);
                        onLeaveTimeout = setTimeout(function () {
                          var navMenuButtonHover = refNavMenu.current?.querySelector<HTMLButtonElement>('button:hover');
                          if (!navMenuButtonHover) focus(-1);
                        }, delayInterval);
                      }}
                      onKeyDown={(e) => {
                        if (refMenu.current) {
                          switch (e.key) {
                            case 'Tab':
                              e.shiftKey && idx === 0 && focus(-1);
                              break;
                            case 'ArrowDown':
                              e.preventDefault();
                              const links = refMenu.current.querySelectorAll<HTMLAnchorElement>('div > [tabIndex]');
                              links.length > 0 && links[0]?.focus();
                              break;
                            default:
                              return;
                          }
                        }
                      }}
                      aria-label={category.title}
                    >
                      <button className="ring-black font-bold p-2 px-4">{category.title}</button>
                    </Li>
                  ))}
                  <Li
                    key={selfServiceCategory.id}
                    className={idx > data.length || selfServiceCategoryActive ? 'active' : ''}
                    onFocus={() => focus(data.length)}
                    onBlur={(e) => {
                      clearTimeout(onLeaveTimeout);
                      onLeaveTimeout = setTimeout(function () {
                        try {
                          if (
                            refMenu.current &&
                            e.relatedTarget &&
                            !(refMenu.current.contains(e.relatedTarget as Node) || (e.relatedTarget as HTMLElement).contains(refMenu.current))
                          ) {
                            if (idx !== -1 && data.length !== idx) focus(-1);
                          }
                        } catch (error) {
                          focus(-1);
                        }
                      }, delayInterval);
                    }}
                    onMouseOver={(e) => {
                      clearTimeout(onFocusTimeout);
                      onFocusTimeout = setTimeout(function () {
                        var hoverRefMenu = refMenu.current && refMenu.current.querySelector<HTMLAnchorElement>(':hover');
                        if (!hoverRefMenu) focus(data.length);
                      }, delayInterval);
                    }}
                    onMouseLeave={(e) => {
                      clearTimeout(onLeaveTimeout);
                      onLeaveTimeout = setTimeout(function () {
                        var navMenuButtonHover = refNavMenu.current && refNavMenu.current?.querySelector<HTMLButtonElement>('button:hover');
                        if (!navMenuButtonHover) focus(-1);
                      }, delayInterval);
                    }}
                    onKeyDown={(e) => {
                      if (refMenu.current) {
                        switch (e.key) {
                          case 'Tab':
                            !e.shiftKey && focus(-1);
                            break;
                          case 'ArrowDown':
                            e.preventDefault();
                            const links = refMenu.current.querySelectorAll<HTMLAnchorElement>('div > [tabIndex]');
                            links.length > 0 && links[0]?.focus();
                            break;
                          default:
                            return;
                        }
                      }
                    }}
                    aria-label={selfServiceCategory.title}
                  >
                    <button className="ring-black font-bold p-2 px-4">{selfServiceCategory.title}</button>
                  </Li>
                  {isCreator && (
                    <Li
                      className={`${idx > data.length || isEyeCatcherPage ? 'active' : ''}`}
                      aria-label={eyeCatcher.title}
                      onMouseOver={(e) => {
                        clearTimeout(onFocusTimeout);
                        onFocusTimeout = setTimeout(function () {
                          focus(-1);
                        }, delayInterval);
                      }}
                    >
                      <button className="ring-black font-bold p-2 px-4" onClick={() => navigate(eyeCatcher.href)}>
                        {eyeCatcher.title}
                      </button>
                    </Li>
                  )}
                </ul>
              </NavMenu>
              <MobileNavContainer>{children[1] || null}</MobileNavContainer>
            </>
          )}
        </div>
      </div>
      <section
        ref={refMenu}
        className="page"
        onClick={() => focus(-1)}
        onMouseLeave={(e) => {
          try {
            if (refMenu.current && e.relatedTarget && !refMenu.current.contains(e.relatedTarget as Node)) {
              focus(-1);
            }
          } catch (error) {
            focus(-1);
          }
        }}
        onKeyDown={tab}
      >
        {idx === -1 || !(idx < data.length) ? null : (
          <div className="menu p-1">
            {data[idx]?.children && (
              <Masonry
                breakpointCols={{
                  default: 5,
                  1500: 4,
                  1100: 3,
                  900: 2,
                  600: 1,
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {data[idx].children?.map((children: Category) => (
                  <ListItem key={children.id} data={children} level={1} />
                ))}
              </Masonry>
            )}
          </div>
        )}
        {idx === data.length && (
          <div className="menu p-1 !pb-8">
            <Masonry
              breakpointCols={{
                default: 5,
                1500: 4,
                1100: 3,
                900: 2,
                600: 1,
              }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {selfServiceCategory.children?.map((children, index) => (
                <React.Fragment key={`self-service-${index}`}>
                  {children.disabled ? (
                    <div key={`self-service-${index}`} className="leading-5 opacity-70 cursor-default font-bold pt-[10px] pb-[7px]">
                      {children.label}
                    </div>
                  ) : (
                    <NavLink tabIndex={0} key={`self-service-${index}`} to={children.href} className={`leading-5  font-bold pt-[10px] pb-[7px]`}>
                      {children.label}
                    </NavLink>
                  )}
                </React.Fragment>
              ))}
            </Masonry>
          </div>
        )}
      </section>
    </Container>
  );
}

function ListItem({ data, level }: { data: Category; level: number }) {
  return (
    <div className={`category-group ${!!data.children?.length && 'has-children'}`}>
      <NavLink
        tabIndex={0}
        to={`/shop/${formattCategoryNameRoute(data.title)}`}
        end={level > 2}
        className={`leading-5 text-base level-${level} ${level === 1 ? 'font-bold pt-[10px] pb-[7px]' : 'font-normal'}`}
        aria-label={data.title}
      >
        {data.title}
      </NavLink>
      {data.children && (
        <div className="">
          {data.children.map((n: any) => (
            <ListItem key={n.id} data={n} level={level + 1} aria-label={n.title} />
          ))}
        </div>
      )}
    </div>
  );
}

const NavMenu = styled.nav`
  position: relative;
  :after {
    background-color: transparent;
    content: ' ';
    display: block;
    height: 10px;
    position: absolute;
    left: 0;
    right: 0;
  }
`;

const Li = styled.li`
  position: relative;
  :after {
    background-color: transparent;
    content: ' ';
    display: block;
    height: 10px;
    position: absolute;
    left: 0;
    right: 0;
  }
`;

const MobileNavContainer = styled.nav``;
const Container = styled.div`
  position: relative;
  margin-top: -4px;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  .my-masonry-grid {
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
  .my-masonry-grid_column:not(:last-child) {
    padding-right: 2em;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 0;
  }

  .my-masonry-grid_column a {
    overflow: hidden;
    width: 100%;
    display: inline-block;
  }

  .my-masonry-grid_column > .category-group.has-children {
    margin-bottom: 12px;
  }

  .menu {
    max-width: var(--page-width);
    max-height: 0px;
    overflow: hidden;
    padding-top: 1em;
  }

  &:hover .menu,
  &.open .menu {
    max-height: 70vh;
    overflow-y: auto;
  }
  > div > div {
    margin: 0 auto;
    display: flex;
    align-items: center;
    min-height: 52px;
  }
  .logo svg {
    color: var(--primary);
    min-width: 1px;
    width: 1px;
    font-size: 1.5rem;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  li ul {
    margin-left: 1em;
  }
  .__old_col3 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .col3 {
    display: flex;
    flex-flow: column wrap;
    max-height: 50vh;
  }
  li a:hover {
    color: var(--primary);
  }
  .col3 > li {
    margin-top: 1em;
    flex: 1;
  }
  .col3 li > a {
    font-size: 16px;
    padding: 12px 12px 4px 12px;
    line-height: 2;
  }
  .level-3 {
    padding-left: 12px;
  }
  .level-4 {
    padding-left: 24px;
  }
  .active {
    color: var(--primary);
  }
  a:hover,
  button:hover {
    cursor: pointer;
    color: var(--primary);
  }
  &.hide {
    border-radius: 0;
    margin-top: 4px;
    .logo > svg {
      min-width: 30px;
    }
  }

  @media (max-width: ${breakpoint.size.med}) {
    .page {
      padding-right: 0;
    }
  }
`;
