const cartPage = {
  page_title: 'Mein Downloadkorb - WerbeNet',
  title: 'Downloadkorb',
  select: 'Auswählen',
  deselect: 'Abwählen',
  zoom_image: 'Bild vergrößern',
  tabel: {
    selectAll: 'Alle auswählen',
    select: 'Auswählen',
    assetNumber: 'AS-Nummer',
    name: 'Name',
    keyWords: 'Key Words',
    endofUsePeriod: 'Ende Nutzungszeitraum',
    noAssets: 'Keine Assets für die ausgewählte Kategorie',
    detailsPage: 'Detailseite',
  },
  overview: {
    partnernet: {
      id: 'partnernet',
      label: 'Partnernet',
    },
    magenta_smart: {
      id: 'magenta_smart',
      label: 'Magenta SmartHome',
    },
  },
  links: {
    delete: {
      link: '#link1',
      label: 'Aus Downloadkorb entfernen',
    },
    download_link: {
      link: '#link2',
      label: 'Download-Link per E-Mail senden',
    },
    download_low_resolution: {
      link: '#link3',
      label: 'In geringer Auflösung herunterladen',
    },
  },
  download: 'Herunterladen',
  no_assets: 'Es befinden sich keine Assets im Downloadkorb',
  progressBar: {
    download_is_finished: 'Das Herunterladen ist beendet',
    download_failed: 'Herunterladen fehlgeschlagen',
  },
} as const;

export default cartPage;
