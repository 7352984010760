import { StylesConfig, Theme } from 'react-select';

export const themeSelect = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary)',
    primary25: 'var(--magenta_light)',
    primary50: 'var(--gray-10)',
    dangerLight: 'var(--primary-hover)',
    neutral10: 'var(--magenta_light)',
  },
});

export const customStyles: StylesConfig = {
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      zIndex: 101,
    };
  },
  control: (provided: any, state: any) => {
    return {
      ...provided,
      borderRadius: '0.5rem',
      minWidth: '300px',
      ...(state.isDisabled ? { borderColor: 'var(--grey-10)' } : !state.isFocused && { borderColor: 'var(--grey-30)' }),
      '&:hover': {
        cursor: 'pointer;',
        border: '1px solid var(--primary)',
      },
    };
  },
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 101,
  }),
  container: (provided: any, state: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: 'visible',
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer;',
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    input: {
      boxShadow: 'unset! important',
    },
  }),
};
