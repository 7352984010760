import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface FaqsState {
  faqs: Array<FAQ>;
  categories: Array<FAQCategory>;
  byCategory: Array<{ category: FAQCategory; faqs: FAQ[] }>;
}

export const initialState: FaqsState = {
  faqs: [],
  categories: [],
  byCategory: [],
};

const slice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setFaqs: (state, action: PayloadAction<FAQ[]>) => {
      state.faqs = action.payload;
      state.byCategory = [];
      action.payload.forEach((faq) => {
        const index = state.byCategory.findIndex((category) => category.category.id === faq.faq_category.id);
        if (index < 0) {
          state.byCategory.push({
            category: faq.faq_category,
            faqs: [faq],
          });
        } else {
          state.byCategory[index].faqs.push(faq);
        }
      });
      state.byCategory.sort((c1, c2) => c1.category.order - c2.category.order);
      state.byCategory.forEach((element) => {
        element.faqs.sort((f1, f2) => f1.order - f2.order);
      });
    },
    setFaqCategories: (state, action: PayloadAction<FAQCategory[]>) => {
      state.categories = action.payload;
    },
  },
});

export default slice;

export const { actions, reducer, name } = slice;
