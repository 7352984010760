import { Fragment, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ActionEdit from 'Components/Icons/ActionEdit';
import IconFileGraph from 'Components/Icons/IconFileGraph';
import { BsEye, BsSearch, BsArrowRight, BsUpload, BsFileEarmarkPlus, BsPerson } from 'react-icons/bs';
import styled from 'styled-components';
import { useT } from '../translations';
import { useNavigate } from 'react-router-dom';
import { client } from 'Config';
import { API_URL } from 'Env';
import { FormatDate2, timeAgo, zeroFill } from 'Helpers';
import { Pagintaion } from 'Components/Pagination';
import { useT as useET } from '../../EyeCatcher/translations';
import classNames from 'classnames';

const MyEyeCatchers = () => {
  let doneTypingInterval = 1000; //time in ms (1 seconds)
  const t = useT();
  const tE = useET();
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const paramPage = queryParams.get('page') || 1;
  const paramQuery = queryParams.get('query') || undefined;
  const [search, setSearch] = useState('');

  const { data, isSuccess } = useQuery(['list_eyecatchers', queryParams.toString()], () =>
    client.GETEyeCatchers({ page: +paramPage, query: paramQuery, state: [paramState], creator: paramCreator })
  );

  const state = [
    { id: 'all', label: t.state.all },
    { id: 'draft', label: t.state.draft },
    { id: 'require_approval', label: t.state.requireApproval },
    { id: 'approved', label: t.state.approved },
  ];

  const creators = [
    { id: 'me', label: t.tabs.myEyeCatcher },
    { id: 'others', label: t.tabs.usersEyeCatchers },
  ];

  const paramState = queryParams.get('state') || state[0].id;
  const paramCreator = queryParams.get('creator') || creators[0].id;

  const setPage = (newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    queryParams.set('page', newPage.toString());
    navigate({
      search: queryParams.toString(),
    });
    queryClient.invalidateQueries(['list_eyecatchers', queryParams.toString()]);
  };

  useEffect(() => {
    const typingQuery = setTimeout(() => {
      queryParams.set('query', search);
      queryParams.set('page', '1');
      navigate({
        search: queryParams.toString(),
      });
    }, doneTypingInterval);

    return () => clearTimeout(typingQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <div className="mt-10 flex flex-col gap-10">
      <div className="flex flex-row gap-6">
        {creators.map((creator, index) => (
          <div
            key={index}
            className={`${
              creator.id === paramCreator ? 'text-primary-500 font-bold border-b-[5px] rounded-lg border-primary-500' : ''
            } active:text-primary-500`}
          >
            <button
              type="button"
              className={`px-2 hover:text-primary-500 py-1.5 text-lg focus:!shadow-none cursor-pointer hover:outline-none border-none`}
              onClick={() => {
                queryParams.set('creator', creator.id);
                queryParams.set('page', '1');
                navigate({
                  search: queryParams.toString(),
                });
              }}
            >
              {creator.label}
            </button>
          </div>
        ))}
      </div>

      <div className="flex flex-row gap-14">
        <p className="flex flex-row items-center gap-2">
          <BsEye /> {t.state.title}:
        </p>
        <div className="flex flex-row gap-8">
          {state.map((filter) => (
            <button
              type="button"
              key={filter.id}
              className={`px-2 hover:text-primary-500 focus:text-primary-500 cursor-pointer hover:outline-none focus:shadow-none ${
                filter.id === paramState ? 'text-primary-500 font-bold' : ''
              } active:text-primary-500`}
              onClick={() => {
                queryParams.set('state', filter.id);
                queryParams.set('page', '1');
                navigate({
                  search: queryParams.toString(),
                });
              }}
            >
              {filter.label}
            </button>
          ))}
        </div>
      </div>
      <div
        className="flex flex-row items-center border w-full border-gray-300 border-solid h-10 px-5 pl-10 
      rounded-full bg-white mb-6 mt-2 text-sm 
        focus:border-primary-500 hover:border-primary-500 active:border-primary-500"
      >
        <BsSearch size={18} />
        <input
          className="w-full border-none h-8 p-3 focus:border-none !focus:shadow-none disabled:placeholder:text-gray-80 placeholder:italic"
          type="search"
          placeholder={`${t.search}...`}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
        />
        <BsArrowRight size={18} />
      </div>
      {isSuccess && data?.eye_catchers.length === 0 && <p className="text-lg italic">{t.noData}</p>}
      {isSuccess &&
        data?.eye_catchers &&
        data.eye_catchers.map((eyeCatcher) => {
          const eyeCatcherStatus = eyeCatcher.asset.state.code;
          return (
            <Fragment key={eyeCatcher.id}>
              <div className="flex flex-row gap-6 w-full" key={eyeCatcher.id}>
                <div className="self-center grow-0 shrink-0">
                  <img
                    className="w-[86px] h-[136px] object-contain"
                    src={`${API_URL.slice(0, -8)}${eyeCatcher.asset?.file_preview_url}`}
                    alt={`Bild ${eyeCatcher.name}`}
                  />
                </div>
                <div className="border-r border-gray-10"></div>
                <Details className="self-center">
                  <div className="border-b border-gray-10 py-2">
                    <h1 className="text-base font-extrabold">{eyeCatcher.name}</h1>
                    <p>Asset-Nummer: {zeroFill(eyeCatcher.asset?.as_number, 6)}</p>
                    <p>Asset-Version: {eyeCatcher.asset?.version}</p>
                  </div>
                  <div className="py-2">
                    <p>{eyeCatcher.asset?.category_title ?? 'Category'}</p>
                  </div>
                </Details>
                <div className="border-r border-gray-10"></div>
                <div className="flex flex-col gap-4 self-center min-w-[200px]">
                  <div className="inline-flex items-center border-2 rounded-full gap-2 border-gray-200 px-3 py-2">
                    <ActionEdit size={14} />
                    <div className="text-sm whitespace-nowrap">{timeAgo(eyeCatcher.updated_at)}</div>
                  </div>

                  <div className="inline-flex items-center border-2 rounded-full gap-2 border-gray-200 px-3 py-2">
                    <IconFileGraph size={14} />
                    <div className="text-sm whitespace-nowrap">{tE.assetStatus[eyeCatcherStatus]}</div>
                  </div>

                  <div className="inline-flex items-center border-2 rounded-full gap-2 border-gray-200 px-3 py-2">
                    <BsPerson size={14} />
                    <div className="text-sm whitespace-nowrap">{`${eyeCatcher.creator?.first_name ?? '-'} ${
                      eyeCatcher.creator?.last_name ?? ''
                    }`}</div>
                  </div>
                  {eyeCatcher.publish_from && (
                    <div className="inline-flex items-center border-2 rounded-full gap-2 border-gray-200 px-3 py-2">
                      <BsUpload size={14} />
                      <div className="text-sm whitespace-nowrap">{FormatDate2(eyeCatcher.publish_from ?? '')}</div>
                    </div>
                  )}
                </div>
                <div className="border-r border-gray-10"></div>
                <div className="flex flex-col gap-4">
                  <button
                    type="button"
                    className={classNames(
                      'flex flex-row gap-2 p-2 items-center justify-center text-base font-bold whitespace-nowrap',
                      'text-white bg-primary-500 border-primary-500',
                      'border-[1px] rounded',
                      'hover:bg-primary-700',
                      'disabled:border-primary-300 disabled:bg-primary-300 disabled:cursor-default'
                    )}
                    onClick={() => navigate(`/eye_catcher/${eyeCatcher.id}?editmode=false`)}
                  >
                    <BsEye size={14} />
                    {t.buttons.view}
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'flex flex-row gap-2 p-2 items-center justify-center text-base font-bold whitespace-nowrap',
                      'text-white bg-primary-500 border-primary-500',
                      'border-[1px] rounded',
                      'hover:bg-primary-700',
                      'disabled:border-primary-300 disabled:bg-primary-300 disabled:cursor-default'
                    )}
                    onClick={() => navigate(`/eye_catcher/${eyeCatcher.id}`)}
                  >
                    <ActionEdit size={14} />
                    {t.buttons.edit}
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'flex flex-row gap-2 p-2 items-center justify-center text-base font-bold whitespace-nowrap',
                      'text-white bg-primary-500 border-primary-500',
                      'border-[1px] rounded',
                      'hover:bg-primary-700',
                      'disabled:border-primary-300 disabled:bg-primary-300 disabled:cursor-default'
                    )}
                    onClick={() => navigate('/eye_catcher', { state: { ...eyeCatcher, saveAsTemplate: true } })}
                  >
                    <BsFileEarmarkPlus size={14} />
                    {t.dropdownActions.saveAsTemplate}
                  </button>
                </div>
              </div>

              <div className="border-b-2 border-gray-10"></div>
            </Fragment>
          );
        })}
      {isSuccess && data && (
        <div className="flex justify-center">
          <Pagintaion
            currentPage={+paramPage}
            onSelect={setPage}
            totalCount={data.pagination_meta.total_count}
            totalPages={data.pagination_meta.total_pages}
          />
        </div>
      )}
    </div>
  );
};

export default MyEyeCatchers;

const Details = styled.div`
  flex: 1 1 50%;
`;
