import React, { FC, TextareaHTMLAttributes, useRef } from 'react';

import t from 'prop-types';
import styled from 'styled-components';

const MAP_STATE_TO_CLASSNAME = {
  error: 'text-error',
  info: 'text-info',
  success: 'text-success',
  mute: 'grey-10',
  default: 'grey-50',
  primary: 'primary',
} as const;
const CLASSES = Object.keys(MAP_STATE_TO_CLASSNAME) as Array<keyof typeof MAP_STATE_TO_CLASSNAME>;

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * function called to change value
   */
  onChange?: (e: any) => void;
  /**
   * textArea value
   */
  value: string;
  /**
   * width string
   */
  width?: string;
  /**
   * textArea name
   */
  name: string;
  /**
   * placeholder text to show while input is empty
   */
  placeholder?: string;
  /**
   * text to show above input as floating label
   */
  label?: string;
  /**
   * override textArea state style
   */
  mode?: keyof typeof MAP_STATE_TO_CLASSNAME;
  /**
   * textArea font size;
   * it affect height and label sizes too
   * @default 18px
   */
  fontSize?: string;
  /**
   * extra information in black
   */
  message?: string;
  /**
   * list of classes to pass to parent container
   * please don't abuse and polute global css
   */
  className?: string;
  /**
   * input is disabled
   */
  disabled?: boolean;
  block?: boolean;
  feedbackAtTop?: boolean;
  containerStyle?: any;
  error?: string;
}

export const TextArea: FC<TextAreaProps> = ({
  error,
  containerStyle,
  block,
  name,
  required,
  message: _message,
  label,
  placeholder,
  mode,
  fontSize,
  className,
  feedbackAtTop,
  value,
  width,
  ...props
}) => {
  const { current: randomId } = useRef(`${name}${Date.now()}`);
  const currentMode: TextAreaProps['mode'] = error ? 'error' : mode;
  const message = error || _message;
  const id = props.id || randomId;

  return (
    <LabelContainer
      block={block}
      disabled={props.disabled}
      className={`${className || ''}${value ? ' has-value' : ''}`}
      mode={currentMode}
      htmlFor={id}
      size={fontSize}
      id={id + '-label'}
      width={width || '100%'}
      style={containerStyle}
    >
      <TextAreaInput
        className="text-gray-60"
        placeholder={label ? '' : placeholder}
        name={name}
        id={id}
        data-label={typeof label === 'string' ? label : undefined}
        required={required}
        {...props}
        onChange={props.onChange}
        value={value || ''}
      ></TextAreaInput>

      {label && (!feedbackAtTop || (feedbackAtTop && !message)) ? (
        <span className="text-gray-60">
          {label} {required ? '*' : ''}
        </span>
      ) : null}

      {message ? typeof message !== 'string' ? message : <Feedback feedbackAtTop={!!feedbackAtTop}>{message}</Feedback> : null}
    </LabelContainer>
  );
};

TextArea.defaultProps = {
  value: '',
  placeholder: '',
  name: 'text-area' + Date.now(),
};

TextArea.propTypes = {
  name: t.string.isRequired,
  value: t.string.isRequired,
  placeholder: t.string,
  label: t.string,
  message: t.string,
  mode: t.oneOf(CLASSES),
};

const TextAreaInput = styled.textarea`
  border: 1px solid;
  border-radius: 4px;
  min-height: 100px;
  display: block;
  outline: none;
  padding: 0.51em 2em 0.5em 0.5em;
  width: 100%;
`;
const Feedback = styled.small<{ feedbackAtTop: boolean }>`
  bottom: 4px;
  position: absolute;
  white-space: nowrap;
  ${({ feedbackAtTop }) =>
    feedbackAtTop
      ? `
  top: 1.4em;
  right: 0;
  `
      : `
  bottom: 0;
  left: 0.5em;
  position: absolute;
  `}
`;
const LabelContainer = styled.label<{
  block?: boolean;
  mode: TextAreaProps['mode'];
  size?: string;
  label?: string;
  disabled?: boolean;
  width?: string;
}>`
  color: ${pickColorMixin};
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  font-size: ${({ size = '18px' }) => size};
  outline: none;
  padding-bottom: ${({ label }) => (label ? 0 : '1em')};
  padding-top: ${({ size = '1rem' }) => `calc(2 * ${size})`};
  position: relative;
  width: ${({ block, width }) => width || (block ? '100%' : 'auto')};

  > span {
    transition: all 0.2s ease;
  }

  > textarea + span {
    left: 1em;
    position: absolute;
    top: 2.6em;
  }

  > textarea:focus + span,
  &.has-value > textarea + span {
    left: 0.5em;
    top: 0.6em;
  }

  ${TextAreaInput} {
    border-color: ${pickColorMixin};
  }
  ${TextAreaInput}:focus {
    border-color: ${pickColorMixin};
  }
  ${Feedback} {
    color: ${pickColorMixin};
  }
`;

function pickColorMixin({ disabled, mode }: any) {
  if (disabled) {
    return `var(--${MAP_STATE_TO_CLASSNAME.mute})`;
  }

  const color = MAP_STATE_TO_CLASSNAME[mode] || MAP_STATE_TO_CLASSNAME.default;

  return `var(--${color})`;
}
