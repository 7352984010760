import React, { FC, InputHTMLAttributes } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import styled from 'styled-components';
import { BsCalendar3 } from 'react-icons/bs';
import { FormatDate } from 'Helpers';

export interface DateTimePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  type: 'date' | 'time' | 'datetime';
  /**
   * function called to change value
   */
  onChangeTime: (date: Date) => void;
  /**
   * input value
   */
  value: any;
  /**
   * placeholder text to show while input is empty
   */
  placeholder?: string;
  /**
   * input name
   */
  name: string;
  /**
   * text to show above input as floating label
   */
  label?: string;
  /**
   * list of classes to pass to parent container
   * please don't abuse and polute global css
   */
  className?: string;
  minDate?: Date;
  innerRef?: any;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  type,
  name,
  label,
  placeholder,
  className,
  value,
  onChangeTime,
  disabled,
  minDate,
  innerRef,
  ...props
}) => {
  const id = props.id;

  return (
    <DatePickerContainer className={`${className || ''} w-full`} id={id + '-label'}>
      <label id={`label-sr-only-${id?.replace(/\s/g, '_')}`} className="sr-only">
        {FormatDate(value) || [type.includes('date') ? placeholder : null, type.includes('time') ? '--:-- --' : null].join(' ')}
      </label>
      <DatePicker
        id={id}
        {...(innerRef && { ref: innerRef })}
        name={`${name}`}
        selected={value || undefined}
        shouldCloseOnSelect={true}
        placeholderText={[type.includes('date') ? placeholder : null, type.includes('time') ? '--:-- --' : null].join(' ')}
        onChange={(date: Date) => onChangeTime(date)}
        showPopperArrow={false}
        showTimeSelect={type !== 'date'}
        showTimeSelectOnly={type === 'time'}
        timeIntervals={15}
        timeCaption="Time"
        dateFormat={[type.includes('date') ? 'dd.MM.yyyy' : null, ...(type.includes('time') ? ['HH:mm'] : [])].join(' ')}
        locale={de}
        minDate={minDate}
        disabled={disabled}
        ariaLabelledBy={`label-sr-only-${id?.replace(/\s/g, '_')}`}
        className={className}
        autoComplete="off"
      />
      <BsCalendar3 className="icon"></BsCalendar3>
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled.div`
  position: relative;
  .react-datepicker-popper {
    z-index: 99;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
  }
  input {
    width: 100%;
    padding: 0.5em;
    padding-right: calc(1em + 0.5em + 3px); /* icon width + icon padding-right + desired separation*/
    border: 1px solid;
    border-radius: 4px;
    align-items: center;
    outline: none;
  }
`;
