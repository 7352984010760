import { AppAsyncThunk } from 'Config/store';
import { actions } from './model';

export const fetch =
  (): AppAsyncThunk<any[]> =>
  (dispatch, getState, { api }) =>
    Promise.all([
      api.get<Category[]>('/categories').then(({ data }) => {
        data?.length &&
          dispatch(
            actions.set(
              data.map((category) =>
                category.title === 'Downloadanfragen Vorlagen' ? { ...category, terms_of_use: 'terms_of_use dummy' } : category
              )
            )
          );
      }),
      api.get<FieldType[]>('/field_types').then(({ data }) => {
        data?.length && dispatch(actions.setFields(data));
      }),
    ]);
