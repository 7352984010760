import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppFooter, AppHeader, SplashScreen, ConnectivityMonitor } from 'Components';
import { useAppDispatch, useAppSelector } from 'Config';
import AppRouter from 'Features';
import { useEffect, useState } from 'react';
import { categoryStore } from './Features/Categories';
import { cart } from 'Features/Cart/lib/index';
import { staticPages } from 'Features/StaticPages/lib';
import { api } from 'Config/api';
import { authStore } from 'Features/Authentication/lib';

function App() {
  const [loading, ready] = useState(true);
  const dispatch = useAppDispatch();
  const nav = useAppSelector(categoryStore.selectAppNavItems);
  const pages = useAppSelector(staticPages.slice);

  useEffect(() => {
    dispatch(authStore.fetchUser())
      .then(() => Promise.all([dispatch(categoryStore.fetch()), dispatch(cart.fetch()), dispatch(staticPages.fetch())]).finally(() => ready(false)))
      .catch(() => {
        ready(false);
      });
    return () => {
      ready(true);
    };
  }, [dispatch]);

  if (loading) return <SplashScreen />;

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col h-full min-h-screen">
        <SkipNavLink />
        <AppHeader data={nav} />
        <main className="relative rounded-b-xl">
          <SkipNavContent />
          <ConnectivityMonitor api={api} />
          <AppRouter />
        </main>
        <AppFooter
          pages={pages.map(({ name, slug }) => ({
            name: name,
            href: '/statics/' + slug,
          }))}
        />
      </div>
    </QueryClientProvider>
  );
}

export default App;
