import { Modal, Spinner } from 'Components';
import { API_URL } from 'Env';
import defaultImgSrc from 'Assets/nopreview.png';
import { useEffect, useState } from 'react';
import { GETbyID } from 'Config/client';
import PdfPreviewer from './PdfPreviewer';

interface AssetPreviewProps {
  assetPreview: Asset;
  onClose: () => void;
}

export default function AssetPreview({ assetPreview, onClose }: AssetPreviewProps) {
  const fileExtension = assetPreview.file_preview_url.split('.').pop();
  const [assetPreviewDetails, setAssetPreviewDetails] = useState<Partial<AssetDetails>>(assetPreview);
  const [failedLoadingPdf, setFailedLoadingPdf] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    GETbyID(assetPreview.id)
      .then(({ data }) => {
        setAssetPreviewDetails(data);
      })
      .finally(() => setLoading(false));
  }, [assetPreview.id]);

  return (
    <Modal visible={true} onRequestClose={onClose} bodyClasses="py-6 px-1 overflow-hidden" title={assetPreview.name} isPreviewImage={true}>
      <div className="flex justify-center max-h-[calc(98vh-8rem-70px)] items-center flex-col">
        {loading ? (
          <Spinner className="pt-8 pb-4 text-primary-500" size={60} />
        ) : fileExtension === 'mp4' && assetPreviewDetails.file_url ? (
          <video controls src={`${API_URL.slice(0, -8)}${assetPreviewDetails.file_url}`}>
            <track default kind="captions" />
          </video>
        ) : fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' ? (
          <>
            {assetPreviewDetails.file_url || assetPreview.alternative_image_url || assetPreview.file_preview_url ? (
              <img
                src={`${API_URL.slice(0, -8)}${assetPreviewDetails.file_url || assetPreview.alternative_image_url || assetPreview.file_preview_url}`}
                className="object-contain w-full h-auto max-w-screen-2xl max-h-[calc(98vh-8rem-70px)]"
                onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                  if (e.target.src !== defaultImgSrc) {
                    e.target.onerror = null;
                    e.target.src = defaultImgSrc;
                  }
                }}
                alt={`${assetPreview.name}`}
              />
            ) : (
              <img
                className="object-contain w-full h-auto max-w-screen-2xl max-h-[calc(98vh-8rem-70px)]"
                src={defaultImgSrc}
                alt={`${assetPreview.name}`}
              />
            )}
          </>
        ) : !failedLoadingPdf && fileExtension === 'pdf' && assetPreviewDetails.file_size ? (
          <PdfPreviewer pdfUrl={assetPreviewDetails.file_url} setFailedLoadingPdf={setFailedLoadingPdf} />
        ) : (
          <>
            {assetPreview.alternative_image_url || assetPreview.file_preview_url ? (
              <img
                src={`${API_URL.slice(0, -8)}${assetPreview.alternative_image_url || assetPreview.file_preview_url}`}
                className="object-contain w-full h-auto max-w-screen-2xl max-h-[calc(98vh-8rem-70px)]"
                onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                  if (e.target.src !== defaultImgSrc) {
                    e.target.onerror = null;
                    e.target.src = defaultImgSrc;
                  }
                }}
                alt={`${assetPreview.name}`}
              />
            ) : (
              <img
                className="object-contain w-full h-auto max-w-screen-2xl max-h-[calc(98vh-8rem-70px)]"
                src={defaultImgSrc}
                alt={`${assetPreview.name}`}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
}
