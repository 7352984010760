import React, { useContext, useMemo, useState } from 'react';

import translations from './translations';
interface LocalState {
  state: 'en' | 'de';
  onChange: (lang: LocalState['state']) => void;
}

export type Translations = typeof translations.en;

export const localContext = React.createContext({} as LocalState);
const { Provider } = localContext;

export const LocalProvider: React.FC<{ children: any }> = ({ children }) => {
  const [state, onChange] = useState<LocalState['state']>('de');

  return (
    <Provider
      value={{
        state,
        onChange,
      }}
    >
      {children}
    </Provider>
  );
};

export const useLocale = () => {
  const { state, onChange } = useContext(localContext);

  return useMemo<[typeof state, typeof onChange]>(() => [state, onChange], [state, onChange]);
};
