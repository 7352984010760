export default function ActionEdit({ size = 24, color = 'currentColor', select = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      strokeWidth={select ? '0.5' : '0'}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color}
    >
      <path
        d="m21.25 19.5c.4 0 .75.35.75.75s-.35.75-.75.75h-13.2l1.05-1.5zm-17.9-2.35 3.15 2.2-3.5 1.65zm10.55-15.05c.55-.8 1.8-1.05 2.65-.45l.136.1c.783.625.932 1.745.364 2.55l-9.7 13.8-3.15-2.2z"
        fillRule="evenodd"
      />
    </svg>
  );
}
