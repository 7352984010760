const authenticationPage = {
  personal_data: 'Personal Data',
  form_subtitle_one: 'VO/VP Number',
  form_subtitle_two: 'More Data',
  fields: {
    vo_number: 'VO Number',
    vp_number: 'VP Number',
    telephone: 'Telephone',
    company: 'Comapany',
    area: 'Area',
    partner: 'Trading partner',
  },
  full_name: 'Full Name',
  email: 'E-mail address',
  created_at: 'Created at',
  save: 'Save',
  privacy_policy: 'Privacy Policy',
  successfully_saved: 'Successfully saved!',
  not_valid: 'is not valid!',
  edit: 'Edit',
  abort: 'Cancel',
};

export default authenticationPage;
