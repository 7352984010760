import { API_URL } from 'Env';
import React, { useState } from 'react';
import { BsCaretRightFill, BsCaretLeftFill } from 'react-icons/bs';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import styled from 'styled-components';

interface IPdfPreviewer {
  pdfUrl?: string;
  setFailedLoadingPdf?: (value: boolean) => void;
}
export default function PdfPreviewer({ pdfUrl, setFailedLoadingPdf }: IPdfPreviewer): JSX.Element {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfFullPath = `${API_URL.slice(0, -8)}${pdfUrl}`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Document file={pdfFullPath} onLoadSuccess={onDocumentLoadSuccess}>
        <PageWrapper pageNumber={pageNumber} />
      </Document>
      <Text>
        Page {pageNumber} of {numPages}
      </Text>
      {numPages > 1 && (
        <>
          <PreviousButton type="button" show={pageNumber <= 1} onClick={previousPage}>
            <BsCaretLeftFill size={24} />
          </PreviousButton>
          <NextButton type="button" show={pageNumber >= numPages} onClick={nextPage}>
            <BsCaretRightFill size={24} />
          </NextButton>
        </>
      )}
    </>
  );
}
const PageWrapper = styled(Page)`
  > canvas {
    margin: 0 auto;
    width: 80% !important;
    max-height: calc(98vh - 8rem - 70px);
    max-width: 80vw !important;
  }
`;

const Text = styled.p`
  text-align: center;
  margin-top: 1.5em;
`;

const Button = styled.button<{ show: boolean }>`
  align-items: center;
  background-color: white;
  color: var(--primary);
  display: flex;
  font-size: 24px;
  font-weight: 800;
  height: 48px;
  justify-content: center;
  position: absolute;
  top: 45%;
  width: 35px;
  z-index: 8;
  font-size: 1em;
  ${({ show }) => show && 'display: none;'}
`;

const PreviousButton = styled(Button)`
  left: 0.2em;
`;

const NextButton = styled(Button)`
  right: 0.2em;
`;
