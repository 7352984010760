import { Navigate, Route, Routes } from 'react-router';
import './index.css';
import DownloadPage from './DownloadPage';
import Home from './HomePage';
import Shop from './Shop';
import SearchPage from './Shop/SearchPage';
import Cart from './Cart';
import EmailPage from './EmailPage';
import StaticPage from './StaticPages';
import ProfilePage from './Authentication/views/ProfilePage';
import FaqPage from './Faq';
import Documents from './Documents';
import AppleApprovalPage from './AppleApprovalPage';
import ApproveUserPage from './ApproveUserPage';
import EyeCatcher from './EyeCatcher';
import EyeCatcherOverView from './EyeCatcherOverview';
import { useEyeCatcherRoles } from 'Helpers/useEyeCatcherRoles';

export default function AppRouter() {
  const { isCreator } = useEyeCatcherRoles();
  return (
    <Routes>
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/shop/:itemid" element={<Shop />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/documents" element={<Documents />} />
      <Route path="/approval" element={<AppleApprovalPage />} />
      <Route path="/approve" element={<ApproveUserPage />} />
      <Route path="/download/:uuid/:contact_uuid" element={<DownloadPage />} />
      <Route path="/email/asset/:id?" element={<EmailPage />} />
      <Route path="/statics/:slug" element={<StaticPage />} />
      <Route path="/eye_catcher/:pdfid?" element={isCreator ? <EyeCatcher /> : <Navigate to="/" />} />
      <Route path="/eyecatcheroverview" element={isCreator ? <EyeCatcherOverView /> : <Navigate to="/" />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
