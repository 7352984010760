import { BreadCrumb, Page, Spinner } from 'Components';
import { formatISO } from 'date-fns';
import { useForm, FormProvider } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { BsFileEarmark, BsBoxArrowInUp } from 'react-icons/bs';
import BulletPoints from './Components/BulletPoints';
import AssetInformation from './Components/AssetInformation';
import { useT } from './translations';
import Previewer from './Components/Previewer';
import { useToasts } from 'react-toast-notifications';
import Price from './Components/Price';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import GeneralInformation from './Components/GeneralInformation';
import { client, useAppSelector } from 'Config';
import { buildFormData, generateBlobFromPDF, getNewCategoryFields } from './lib/utils';
import { AxiosError } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { categoryStore } from 'Features/Categories';
import { CategoryField } from 'Features/Shop';
import LegalText from './Components/LegalText';
import './Components/customToolbar.css';
import tw from 'twin.macro';
import classNames from 'classnames';
import { useQueryParams, zeroFill } from 'Helpers';
import ActionEdit from 'Components/Icons/ActionEdit';

export default function EyeCatcher() {
  const toastManager = useToasts();
  const queryClient = useQueryClient();
  const t = useT();
  const navigate = useNavigate();
  const location = useLocation();
  const eyeCatherFromState = location.state as IEyeCatcher;
  const saveAsTemplate: boolean = location.state?.saveAsTemplate ?? false;
  const params = useParams<{ pdfid?: string }>();
  const editMode = useQueryParams().get('editmode') || undefined;

  const methodsForm = useForm<IEyeCatcherForm>({
    defaultValues: { price_background: 'white', headline: '', canceled_price: false, bullet_points: [{ bullet_points_background: 'white' }] },
  });

  const { pdfid } = params;
  const eyeCatcherQuery = useQuery(['eyeCatcher', pdfid], () => client.GETEyeCatcherByID(pdfid), { enabled: !!pdfid });
  const { data: eyeCatcherData } = eyeCatcherQuery;
  const createEyecatcherMutation = useMutation(client.CreateEyeCatchers);
  const updateEyeCatcherMutation = useMutation(client.updateEyeCatcher);
  const generateNewVersionMutation = useMutation(client.createEyeCatcherVersion);

  const { data: icons } = useQuery('icons_eyeCatcher', () => client.GETIcons());
  const assetStateCode = eyeCatcherData?.eye_catcher.asset?.state.code ?? '';

  const [inProgressMutation, setInProgressMutation] = useState<boolean>(false);
  const categories = useAppSelector(categoryStore.selectCategories);
  const [categoryFields, setCategoryFields] = useState<Array<CategoryField>>([]);

  const initialValue: { title: string; fields?: Record<string, Field>; id?: number | string; fieldsId?: number }[] = [];

  const parentsCategories = categories.filter((category) => !category.parent_id);
  const reducedCategories = parentsCategories.map((category) => getCategoryName(category)).flat();
  const categoriesOptions = reducedCategories.map((cat) => ({ value: cat.id, label: cat.title, parent_id: cat.fieldsId, id: cat.id }));

  const [selectedOptionCategory, setSelectedOptionCategory] = useState<{ value: string; label?: string; id: string; parent_id: number }>();
  const allCategoryFields = useAppSelector(
    categoryStore.selectCategoryFilters(
      selectedOptionCategory?.value ||
        eyeCatcherData?.eye_catcher.asset?.category_id.toString() ||
        eyeCatherFromState?.asset?.category_id.toString() ||
        ''
    )
  );
  const canEditForm = (eyeCatcherData?.eye_catcher.asset?.state.code === 'draft' || eyeCatcherData === undefined) && !editMode;
  const canCreateNewVersion = assetStateCode === 'require_approval' || assetStateCode === 'published';

  const status = {
    require_approval: t.assetStatus.require_approval,
    draft: t.assetStatus.draft,
    published: t.assetStatus.published,
    not_published: t.assetStatus.not_published,
    publication_expired: t.assetStatus.publication_expired,
    publication_stopped: t.assetStatus.publication_stopped,
    publication_planned: t.assetStatus.publication_planned,
    archived: t.assetStatus.archived,
  };

  useEffect(() => {
    (async function () {
      if (!reducedCategories || reducedCategories.length === 0) return;
      if (!allCategoryFields || allCategoryFields.length === 0) return;

      const newCategoryFields = await getNewCategoryFields(allCategoryFields, new Set<string>());
      setCategoryFields(newCategoryFields);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eyeCatcherData, selectedOptionCategory]);

  const setValues = () => {
    Object.entries(eyeCatcherData?.eye_catcher ?? eyeCatherFromState ?? {}).forEach(([key, val]) => {
      if (key === 'publish_to' || key === 'publish_from') {
        methodsForm.setValue(key, val ? new Date(val) : undefined);
      } else if (key === 'bullet_points') {
        const allBullets = (Object.values(val) as IBulletPoints[]).map((bullet) => {
          const icon = icons?.find((icon) => bullet.icon_id && icon.id === +bullet.icon_id);
          return { icon, text: bullet.text, bullet_points_background: bullet.bullet_points_background };
        });
        methodsForm.setValue('bullet_points', allBullets);
      } else if (key === 'asset') {
        if (val.hasOwnProperty('usable_to')) {
          methodsForm.setValue('usable_to', val['usable_to'] ? new Date(val['usable_to']) : undefined);
        }
        if (val.hasOwnProperty('usable_from')) {
          methodsForm.setValue('usable_from', val['usable_from'] ? new Date(val['usable_from']) : undefined);
        }
        if (val.hasOwnProperty('category_id')) {
          const categoryId = val['category_id'];
          const category = categoriesOptions.find((cat) => cat.id === categoryId);
          methodsForm.setValue('category', category);
        }
        if (val.hasOwnProperty('comment')) {
          methodsForm.setValue('comment', val['comment']);
        }
        if (val.hasOwnProperty('description')) {
          methodsForm.setValue('description', val['description']);
        }
        Object.fromEntries(
          Object.entries(val['category_fields'] ?? []).map(([k, v]) => {
            const selectedField = categoryFields.find((c) => c.key === k);
            const selectedOption = selectedField?.options.find((f) => f.title === v);
            methodsForm.setValue(`category_fields.${k}`, { label: v as string, value: selectedOption?.id as number });
            return [k, { label: v, value: selectedOption?.id }];
          })
        );
      } else {
        methodsForm.setValue(key as keyof IEyeCatcherForm, val);
      }
    });
  };

  useEffect(() => {
    if ((pdfid && eyeCatcherData) || eyeCatherFromState) {
      setValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eyeCatcherData, pdfid, methodsForm.setValue, icons, categoryFields, eyeCatherFromState]);

  const onSubmit = async (data, eyeCatcherState) => {
    setInProgressMutation(true);
    const file = await generateBlobFromPDF();
    const eyeCatcherForm: IEyeCatcherForm = {
      ...data,
      ...(pdfid ? { category: eyeCatcherData?.eye_catcher.asset?.category_id } : { category: data.category.id }),
      usable_to: data.usable_to ? formatISO(data.usable_to, { representation: 'date' }) : undefined,
      usable_from: data.usable_from ? formatISO(data.usable_from, { representation: 'date' }) : undefined,
      publish_to: data.publish_to ? formatISO(data.publish_to, { representation: 'date' }) : undefined,
      publish_from: data.publish_from ? formatISO(data.publish_from, { representation: 'date' }) : undefined,
      state: eyeCatcherState,
      asset: undefined,
    };

    const categoryFields = Object.entries(eyeCatcherForm.category_fields ?? {}).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value?.value };
    }, {});

    const bulletPoints = Object.entries(eyeCatcherForm.bullet_points ?? {}).reduce(
      (acc: { icon_id?: number; text?: string }[], [key, bulletPoint]) => {
        return [...acc, { icon_id: bulletPoint.icon?.id, text: bulletPoint.text, bullet_points_background: bulletPoint.bullet_points_background }];
      },
      []
    );

    const formData = new FormData();
    formData.append('files', file, `${data.name}.pdf`);

    buildFormData(formData, { ...eyeCatcherForm, category_fields: categoryFields, bullet_points: bulletPoints }, undefined);
    if (pdfid) {
      updateEyeCatcherMutation.mutate(
        { formData: formData, id: eyeCatcherData?.eye_catcher.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('list_eyecatchers');
            queryClient.invalidateQueries(['eyeCatcher', pdfid]);
            succesToast(t.succesMessageOnEdit);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          },
          onError: (error: any) => errorToast(error),
          onSettled: () => {
            setInProgressMutation(false);
          },
        }
      );
    } else if (eyeCatherFromState && !saveAsTemplate) {
      generateNewVersionMutation.mutate(
        { formData: formData, id: eyeCatherFromState?.id },
        {
          onSuccess: (data) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            queryClient.invalidateQueries('list_eyecatchers');
            succesToast(t.successMessageGenerateVersion);
            data.id && queryClient.invalidateQueries(['eyeCatcher', data.id]);
            navigate(data.id ? `/eye_catcher/${data.id}` : '/eyecatcheroverview');
          },
          onError: (error: any) => errorToast(error),
          onSettled: () => {
            setInProgressMutation(false);
          },
        }
      );
    } else {
      createEyecatcherMutation.mutate(formData, {
        onSuccess: (data) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          queryClient.invalidateQueries('list_eyecatchers');
          succesToast(t.succesMessageCreation);
          data.id && queryClient.invalidateQueries(['eyeCatcher', data.id]);
          navigate(data.id ? `/eye_catcher/${data.id}` : '/eyecatcheroverview');
        },
        onError: (error: any) => errorToast(error),
        onSettled: () => {
          setInProgressMutation(false);
        },
      });
    }
  };

  function getCategoryName(category: Category, obj?: any) {
    if (!category) {
      return [];
    }
    obj = obj ?? {};
    obj.fields = category.fields ?? obj.fields;
    obj.fieldsId = category.fields ? category.id : obj.fieldsId;
    obj.id = category.id;
    obj.title = (obj.title ? obj.title + '-' : '') + category.title;
    if (!category.children) {
      return [obj];
    }

    return category.children?.reduce((a, c) => {
      return a.concat(getCategoryName(c, { ...obj }));
    }, initialValue);
  }

  const errorToast = (error) => {
    const e: AxiosError = error;
    toastManager.addToast(
      <div role="alert">
        <strong>{e.response?.statusText}</strong>
        <div>{e.message}</div>
      </div>,
      {
        appearance: 'error',
        autoDismiss: true,
      }
    );
  };
  const succesToast = (message) => {
    toastManager.addToast(
      <div role="alert">
        <strong>{message}</strong>
      </div>,
      {
        appearance: 'success',
        autoDismiss: true,
      }
    );
  };
  return (
    <Page key={'page-eye'}>
      <Helmet defer={false}>
        <title>{t.breadcrumbs.linkGenerator}</title>
      </Helmet>
      <BreadCrumb
        style={{ margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: t.breadcrumbs.linkGenerator,
              link: '/eyecatcheroverview',
            },
            ...(pdfid
              ? editMode
                ? [
                    {
                      title: t.breadcrumbs.linkViewGenerator,
                    },
                  ]
                : [
                    {
                      title: t.breadcrumbs.linkEditGenerator,
                    },
                  ]
              : [
                  {
                    title: t.breadcrumbs.linkGenerate,
                  },
                ]),
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />

      <FormProvider {...methodsForm}>
        <div className="flex flex-row my-8 mx-4">
          <FormContainer onSubmit={(e) => e.preventDefault()}>
            <header className="flex flex-col gap-2">
              <h1 className="text-[#191919] font-bold leading-10">{pdfid ? (editMode ? t.title_display : t.title_edit) : t.title_generate}</h1>

              {pdfid && (
                <>
                  <div className="w-fit bg-primary-400 text-white text-sm font-bold px-2.5 py-0.5 rounded-full whitespace-nowrap">
                    Status: {status[eyeCatcherData?.eye_catcher.asset?.state.code ?? 'draft']}
                  </div>
                  <p className="font-bold text-lg">
                    {t.generalInfoSection.assetNumber}: {zeroFill(eyeCatcherData?.eye_catcher.asset?.as_number, 6)}
                  </p>
                </>
              )}
              {!editMode && (
                <>
                  <p>{t.subtitle}</p>
                  {canCreateNewVersion && (
                    <>
                      <p className="mt-6 mb-2">{t.generalInfoSection.newVersionText}</p>
                      <CustomButton
                        type="button"
                        onClick={() => navigate('/eye_catcher', { state: eyeCatcherData?.eye_catcher })}
                        className={classNames(
                          'w-fit flex items-center gap-1 p-2 rounded-md border font-medium shadow',
                          'outline-none focus:outline-none',
                          'bg-primary-500 border-primary-600 text-white  ',
                          'enabled:hover:bg-primary-700 '
                        )}
                      >
                        {t.buttons.newVersion}
                      </CustomButton>
                    </>
                  )}
                </>
              )}
            </header>
            <GeneralInformation canEdit={canEditForm} />
            <BulletPoints canEdit={canEditForm} />
            <Price canEdit={canEditForm} />
            <LegalText canEdit={canEditForm} />
            <AssetInformation
              canEdit={canEditForm}
              disableCategory={!!eyeCatherFromState}
              categoriesOptions={categoriesOptions}
              categoryFields={categoryFields}
              setSelectedOptionCategory={setSelectedOptionCategory}
            />
            {!editMode && (
              <section className="flex flex-row justify-between">
                <CustomButton
                  title={t.buttons.cancel}
                  aria-label={t.buttons.cancel}
                  className={classNames(
                    'flex items-center gap-1 p-2 rounded-md border font-medium shadow',
                    'outline-none focus:outline-none',
                    'bg-white border-gray-400 text-gray-700',
                    'enabled:hover:bg-gray-200'
                  )}
                  onClick={() => {
                    methodsForm.reset();
                    pdfid && setValues();
                    navigate(-1);
                  }}
                  disabled={inProgressMutation}
                >
                  {t.buttons.cancel}
                </CustomButton>
                {canEditForm ? (
                  <div className="flex flex-row gap-4">
                    <CustomButton
                      type="button"
                      title={t.buttons.saveAsDraft}
                      aria-label={t.buttons.saveAsDraft}
                      className={classNames(
                        'flex items-center gap-1 p-2 rounded-md border font-medium shadow',
                        'outline-none focus:outline-none',
                        'bg-white border-primary-500 text-primary-500',
                        'enabled:hover:bg-gray-200'
                      )}
                      onClick={() => {
                        methodsForm.register('publish_from', { required: false });
                        methodsForm.register('publish_to', { required: false });
                        methodsForm.register('usable_to', { required: false });
                        methodsForm.register('usable_from', { required: false });
                        methodsForm.handleSubmit((data) =>
                          onSubmit(
                            {
                              ...data,
                            },
                            'draft'
                          )
                        )();
                      }}
                      disabled={inProgressMutation}
                    >
                      <span>{inProgressMutation ? <Spinner size={16} /> : <BsFileEarmark size={'16'} />}</span>
                      <span>{pdfid ? t.buttons.save : t.buttons.saveAsDraft}</span>
                    </CustomButton>

                    <CustomButton
                      type="button"
                      title={t.buttons.publish}
                      aria-label={t.buttons.publish}
                      className={classNames(
                        'flex items-center gap-1 p-2 rounded-md border font-medium shadow',
                        'outline-none focus:outline-none',
                        'bg-primary-500 border-primary-600 text-white  ',
                        'enabled:hover:bg-primary-700 '
                      )}
                      onClick={() => {
                        methodsForm.register('publish_from', { required: true });
                        methodsForm.register('publish_to', { required: true });
                        methodsForm.register('usable_to', { required: true });
                        methodsForm.register('usable_from', { required: true });
                        methodsForm.handleSubmit((data) =>
                          onSubmit(
                            {
                              ...data,
                            },
                            'require_approval'
                          )
                        )();
                      }}
                      disabled={inProgressMutation}
                    >
                      <span>{inProgressMutation ? <Spinner size={16} /> : <BsBoxArrowInUp size={'16'} />}</span>
                      <span>{t.buttons.publish}</span>
                    </CustomButton>
                  </div>
                ) : (
                  <div className="flex flex-row gap-4">
                    <CustomButton
                      type="button"
                      title={t.buttons.update}
                      aria-label={t.buttons.update}
                      className={classNames(
                        'flex items-center gap-1 p-2 rounded-md border font-medium shadow',
                        'outline-none focus:outline-none',
                        'bg-primary-500 border-primary-600 text-white  ',
                        'enabled:hover:bg-primary-700 '
                      )}
                      onClick={() => {
                        methodsForm.handleSubmit((data) =>
                          onSubmit(
                            {
                              ...data,
                            },
                            'require_approval'
                          )
                        )();
                      }}
                      disabled={inProgressMutation}
                    >
                      <BsBoxArrowInUp size={'16'} />
                      {t.buttons.update}
                    </CustomButton>
                  </div>
                )}
              </section>
            )}
            {editMode && (
              <div className="flex justify-end">
                <button
                  type="button"
                  className={classNames(
                    'flex flex-row gap-2 p-2 items-center justify-center text-base font-bold whitespace-nowrap',
                    'text-white bg-primary-500 border-primary-500',
                    'border-[1px] rounded',
                    'hover:bg-primary-700',
                    'disabled:border-primary-300 disabled:bg-primary-300 disabled:cursor-default'
                  )}
                  onClick={() => {
                    navigate(`/eye_catcher/${pdfid}`);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <ActionEdit size={14} />
                  {t.buttons.edit}
                </button>
              </div>
            )}
          </FormContainer>
          <Previewer />
        </div>
      </FormProvider>
    </Page>
  );
}

const FormContainer = styled.form`
  width: 62vw;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 3em;
`;

const commonDisabledStyles = css`
  ${tw`disabled:opacity-50 disabled:cursor-not-allowed`}
`;

const CustomButton = styled.button`
  ${commonDisabledStyles}
`;
