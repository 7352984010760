import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Config';

/** ***************** */
// THUNKS
/** ***************** */
export const ID_PARAM = 'id';

export interface AuthState {
  user: User | null;
  refresh: string;
  auth: string;
  lastAttempt: number;
  userWerbenet: UserWerbenet | null;
}

export const initialState: AuthState = {
  user: null,
  refresh: '',
  auth: '',
  lastAttempt: 0,
  userWerbenet: null,
};

export const { actions, reducer, name } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setUserWebernet: (state, action: PayloadAction<UserWerbenet>) => {
      state.userWerbenet = { ...action.payload };
    },
    update: (state, action: PayloadAction<Partial<User>>) => {
      const id = typeof action.payload.id === 'string' ? action.payload.id : state.user?.id;
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
          id: String(id),
        };
      } else {
        console.error('cannot update user info,  since there were no intial values');
      }
    },
    logout: () => {
      // this is going to be handled in the root reducer
    },
  },
});

export const slice = (state: RootState) => state[name] || initialState;

export const selectUser = (state: RootState) => {
  return slice(state).user;
};

export const selectUserWerbenet = (state: RootState) => {
  return slice(state).userWerbenet;
};

export const selectUserToken = (state: RootState) => {
  return slice(state).user?.access_token;
};

export const isSupplier = (state: RootState) => {
  return slice(state).user?.role === 'supplier';
};

export const selectRefreshToken = (state: RootState) => {
  return slice(state).user?.refresh_token;
};
