import { useAppSelector } from 'Config';
import { authStore } from 'Features/Authentication/lib';
import { useMemo } from 'react';

const CREATOR = 'creator';
const APPROVER = 'approver';

export const useEyeCatcherRoles = () => {
  const userWerbenet = useAppSelector(authStore.selectUserWerbenet);
  const role = userWerbenet?.role.toLowerCase();
  const isSuperAdmin = role === 'super_admin' || role === 'admin';

  const isCreator = useMemo(() => {
    return isSuperAdmin || (userWerbenet?.metadata?.eye_catcher_roles ?? []).some((e) => e === CREATOR);
  }, [isSuperAdmin, userWerbenet.metadata?.eye_catcher_roles]);

  const isApprover = useMemo(() => {
    return (userWerbenet?.metadata?.eye_catcher_roles ?? []).some((e) => e === APPROVER);
  }, [userWerbenet.metadata?.eye_catcher_roles]);

  return { isCreator: isCreator, isApprover: isApprover, isSuperAdmin: isSuperAdmin };
};
