import { ProgressBar, ProgressBarProps } from 'Components/ProgressBar';
import { humanFileSize } from 'Helpers';
import { BsX } from 'react-icons/bs';
import { useT } from '../translations';

interface DownloadProgressProps {
  showProgress?: boolean;
  progressBarProps?: ProgressBarProps;
  loadedSize: number;
  hideDownloadProgress?: () => void;
}

export const DownloadProgress: React.FC<DownloadProgressProps> = (props: DownloadProgressProps) => {
  const t = useT();
  const { showProgress, progressBarProps, loadedSize, hideDownloadProgress } = props;
  return (
    <div className="inline-flex items-center w-full max-w-2xl gap-x-4 my-4" style={{ opacity: showProgress ? 1 : 0 }}>
      <button
        title={t.progressBar.close}
        aria-label={t.progressBar.close}
        className="disabled:text-gray-30"
        onClick={() => hideDownloadProgress && hideDownloadProgress()}
        disabled={progressBarProps?.inProgress || !showProgress}
      >
        <BsX size={30} />
      </button>
      <ProgressBar label={`${t.progressBar.label} ${humanFileSize(loadedSize, true)}`} classes={'flex-1'} {...(progressBarProps || {})}></ProgressBar>
    </div>
  );
};
