import { useState } from 'react';
import styled from 'styled-components';
import { useT } from './translations';
import { BreadCrumb, Page } from 'Components';
import { Helmet } from 'react-helmet';
import tw from 'twin.macro';
import { useToasts } from 'react-toast-notifications';
import Trans from 'Components/Trans';
import { client } from 'Config';

export default function AppleApprovalPage() {
  const t = useT();
  const toastManager = useToasts();
  const [formData, setFormData] = useState<PublicationAccessRequest>({
    first_name: '',
    last_name: '',
    email: '',
    notes: '',
    group_name: 'PG_Apple',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    client
      .publicationAccessRequest(formData)
      .then(() => {
        toastManager.addToast(
          <div role="alert">
            <strong>{t.page_title}</strong>
            <div>
              <Trans id={`approval-request-success`} {...{ value: t.messages.success, emailAddress: formData.email }} />
            </div>
          </div>,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
      })
      .catch(() => {
        toastManager.addToast(
          <div role="alert">
            <strong>{t.page_title}</strong>
            <div>
              <Trans id={`approval-request-error`} {...{ value: t.messages.error, emailAddress: formData.email }} />
            </div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      });
  };

  return (
    <Page key={'page-apple-approval'}>
      <Helmet defer={false}>
        <title>{t.page_title}</title>
      </Helmet>
      <BreadCrumb
        style={{ width: 'var(--page-width)', margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: 'Home',
              link: '/',
            },
            {
              title: t.apply_for_apple,
              link: `/approval`,
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      <section className="flex flex-col gap-y-6">
        <h1 className="text-2xl font-extrabold">{t.page_title}</h1>
        <div className="flex flex-col gap-y-3.5 text-base">
          <h2 className="font-bold text-lg">{t.contact_person_at_telekom}</h2>
          <p>{t.infos}</p>
        </div>
        <AppleApprovalForm id={'apple-approval_form'} className="max-w-[940px]" onSubmit={onSubmit}>
          <FieldSet>
            <Label htmlFor="first_name">{`${t.approvalForm.first_name}*`}</Label>
            <Input
              required={true}
              placeholder={t.approvalForm.first_name}
              name="first_name"
              type="text"
              value={formData.first_name || ''}
              active={!!formData.first_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, first_name: e.target.value })}
            />
          </FieldSet>

          <FieldSet>
            <Label htmlFor="last_name">{`${t.approvalForm.last_name}*`}</Label>
            <Input
              required={true}
              placeholder={t.approvalForm.last_name}
              name="last_name"
              type="text"
              value={formData.last_name || ''}
              active={!!formData.last_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, last_name: e.target.value })}
            />
          </FieldSet>

          <FieldSet>
            <Label htmlFor="email_address">{`${t.approvalForm.email_address}*`}</Label>
            <Input
              required={true}
              placeholder={t.approvalForm.email_address}
              name="email_address"
              type="email"
              pattern="(.+@telekom\.de|.+@8s\.de)"
              title={t.approvalForm.email_title}
              value={formData.email || ''}
              active={!!formData.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, email: e.target.value })}
            />
          </FieldSet>

          <FieldSet>
            <Label htmlFor="message">{t.approvalForm.message_label}</Label>
            <TextArea
              rows={7}
              placeholder={t.approvalForm.message}
              name="message"
              value={formData.notes || ''}
              active={!!formData.notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFormData({ ...formData, notes: e.target.value })}
            />
          </FieldSet>

          <button
            id="button-submit"
            type="submit"
            form="apple-approval_form"
            className="py-2 px-4 ml-auto min-w-40 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded disabled:border-primary-300 disabled:bg-primary-300 disabled:cursor-default"
          >
            {t.approvalForm.submit}
          </button>
        </AppleApprovalForm>
      </section>
    </Page>
  );
}

const AppleApprovalForm = styled.form(({ disabled }: { disabled?: boolean }) => [
  tw`flex flex-col gap-y-6 text-base`,
  disabled && tw`text-gray-50`, // Then add conditional styles
]);

const FieldSet = styled.fieldset`
  ${tw`flex flex-col`}
`;

const Label = styled.label`
  ${tw`font-bold pb-1`}
`;

const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-30 border-[1px] shadow rounded p-2 focus:text-black`,
  active && tw`text-black border-gray-80 `, // Then add conditional styles
]);

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-30 border-[1px] shadow rounded p-2 focus:text-black `,
  active && tw`text-black border-gray-80`, // Then add conditional styles
]);
