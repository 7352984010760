import { useFormContext } from 'react-hook-form';
import { useT } from '../translations';

const GeneralInformation: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const t = useT();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputCss =
    'disabled:opacity-40 disabled:cursor-not-allowed min-w-[175px] px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:border-primary-500 focus:outline-none focus:ring-primary';

  return (
    <>
      <div className="flex flex-col gap-2">
        <h2 className="font-extrabold">{t.generalInfoSection.title}</h2>
        <label>{t.generalInfoSection.eyeCatcherName}*</label>
        <input
          type="text"
          placeholder={t.generalInfoSection.eyeCatcherNamePlaceholder}
          className={inputCss}
          {...register('name', { required: `${t.generalInfoSection.eyeCatcherName} ${t.field_required_asset}` })}
          disabled={!canEdit}
        />
        {errors.name && <p className="text-base italic text-red-60">{errors.name.message?.toString()}</p>}
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="font-extrabold">{t.headSection.title}</h2>

        <label>{t.headSection.preHeadline}</label>
        <input type="text" placeholder={t.headSection.preHeadline} className={inputCss} {...register('pre_headline')} disabled={!canEdit} />

        <label>{t.headSection.headline}</label>
        <input type="text" placeholder={t.headSection.headline} className={inputCss} {...register('headline')} disabled={!canEdit} />

        <label>{t.headSection.subline}</label>
        <input type="text" placeholder={t.headSection.subline} className={inputCss} {...register('subline')} disabled={!canEdit} />
      </div>
    </>
  );
};

export default GeneralInformation;
