import { format } from 'timeago.js';

export const FormatMoney = (n: any) =>
  n
    ? Number(n).toLocaleString('de-DE', {
        currency: 'EUR',
        style: 'currency',
      })
    : '--';
// Converts a timestamp into 'x minutes/hours/days ago'
export const timeAgo = (timestamp) => format(timestamp, 'de-DE');

export const FormatDate = (n: string | Date, short = false, notime: '2-digit' | 'numeric' | undefined = '2-digit') => {
  if (!n) return null;
  const date = typeof n === 'string' ? new Date(n) : n;
  if (!date?.toLocaleDateString) {
    return null;
  }

  return short
    ? date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : date.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: false,
        hour: notime,
        minute: notime,
      });
};

export const FormatDate2 = (n: string | Date) => {
  if (!n) return null;
  const date = typeof n === 'string' ? new Date(n) : n;
  if (!date?.toLocaleDateString) {
    return null;
  }

  return date.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export function zeroFill(nb, minLength = 6, prefix = '') {
  // Convert your number to string.
  return String(`${nb}`)
    .padStart(minLength, '0')
    .padStart(minLength + prefix.length, prefix);
}

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

const umlautMap = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
};

export function formattCategoryNameRoute(str) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), (a) => umlautMap[a])
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\w+/g, function (txt) {
      // uppercase first letter and add rest unchanged
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    })
    .replace(/\s/g, ''); // remove any spaces
}

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}
