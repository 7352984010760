const shopPage = {
  page_title: 'Mein Downloadkorb - WerbeNet',
  all_categories: 'Allen Kategorien',
  back: 'Zurück',
  search: 'Suchen',
  keyword_search: 'Stichwortsuche',
  search_for: 'Suchen nach',
  title: 'Downloadkorb',
  DESC: 'Absteigend',
  ASC: 'Aufsteigend',
  date: 'Datum',
  name: 'Name',
  period_from: 'Nutzungszeitraum von',
  period_to: 'Nutzungszeitraum bis',
  filter_noun: 'Filter',
  filter: 'Filtern',
  approve_modal: {
    approve: 'Asset freigeben',
    reject: 'Asset ablehnen',
    confirm: 'Bestätigen Sie',
    cancel: 'Abbrechen',
    confirm_approval: 'Bestätigung der Genehmigung',
    reject_approval: 'Genehmigung verweigern',
    message: 'Sind Sie sicher, dass Sie mit dieser Aktion fortfahren wollen?',
    reason: 'Bitte geben Sie Ihren Grund an!',
    reason_placeholder: 'Grund für die Ablehnung',
  },
  succesMessageOnReject: 'Störer wurde abgelehnt!',
  successMessageOnApprove: 'Störer wurde erfolgreich freigegeben!',
  reset_filter: 'Filter zurücksetzen',
  no_assets: {
    filter: 'Keine Ergebnisse gefunden.',
    category: 'Die Kategorie enthält keine Artikel.',
  },
  all_products: 'Alle Werbemittel',
  terms_of_use_note: 'Bitte beachten:',
  terms_of_use_info:
    'HD-Werbespots können nicht in Low Resolution heruntergeladen werden. Außerdem ist aus lizenzrechtlichen Gründen kein direkter Download möglich. Sobald Sie oben auf “Downloadanfrage senden” klicken, erscheint ein Popup mit den entsprechenden Nutzungsbedingungen. Bitte lesen Sie sich die Nutzungsbedingungen durch und bestätigen Sie deren Erhalt. Danach können Sie die Datei sofort herunterladen.',
  terms_of_use: {
    special_rules: 'für den Einsatz von elektronischen Medien der Telekom gelten besondere Regeln.',
    title: 'Ihre Downloadanfrage',
    body_title: 'Sehr geehrter Werbenet User,',
    rules: {
      info: 'für den Einsatz von elektronischen Medien der Telekom (wie z.B. TV-Spots) gelten besondere Regeln. Mit dem Download dieser Datei akzeptieren Sie die Bedingungen für den Einsatz dieser Medien:',
      1: 'Die bereitgestellten elektronischen Medien sind ausschließlich am POS zu verwenden. Eine anderweitige Nutzung ist nicht gestattet.',
      2: 'Die Inhalte sind lizenzrechtlich geschützt und nach Ablauf der Gültigkeit sofort zu entfernen. Bei Nichtbeachtung behalten wir uns vor eventuelle Straf-Buyouts an Sie weiterzugeben.',
      3: 'Die Kosten (GEZ, GEMA, etc.), für den Einsatz der hier zur Verfügung gestellten elektronischen Medien, sind vom Vermarktungspartner zu tragen.',
      4: 'Ausschließlich hier zum Download bereitgestellte Medien dürfen gezeigt werden.',
      5: 'Die Medien dürfen nicht verändert werden.',
      6: 'Eine Weitergabe der elektronischen Medien ist nur nach vorheriger Freigabe erlaubt.',
      7: 'Bei Nichteinhaltung dieser Vorgaben kann es zur Sperrung der Downloadberechtigung kommen!',
    },
    regards: 'Mit freundlichen Grüßen',
    telekom: 'Telekom Deutschland GmbH',
    confirm: 'Ich bestätige, dass ich die Nutzungsbedingungen gelesen und verstanden habe.',
    download: 'Herunterladen',
  },
  options: {
    select_all: 'Alle auswählen',
    download_request: 'Downloadanfrage senden',
    reset_selection: 'Auswahl zurücksetzen',
    download_medium: 'Medium herunterladen',
    download_low_resolution: 'In geringer Auflösung herunterladen',
    download_link: 'Download-Link per E-Mail senden',
    copy_asset_link: 'Asset-Link kopieren',
  },
  copy_link_success: 'Die URL wurde erfolgreich in die Zwischenablage kopiert',
  copy_link_failed: 'Die URL konnte nicht in die Zwischenablage kopiert werden',
  remove_from_shopping_cart: 'Aus dem Downloadkorb entfernen',
  add_to_shopping_cart: 'Zum Downloadkorb hinzufügen',
  information: 'information',
  select: 'Auswählen',
  deselect: 'Abwählen',
  as_nummer: 'AS-Nummer',
  usage_period_start: 'Start Nutzungszeitraum',
  usage_period_end: 'Ende Nutzungszeitraum',
  properties: 'Eigenschaften',
  other_file: 'Weitere Dateiformate des Mediums',
  detailed_view: 'Detailansicht des Mediums',
  file_properties: 'Datei Eigenschaften',
  details: {
    resolution: 'Auflösung (%{unit}):',
    file_extension: 'Dateiendung:',
    size: 'Dokument Größe (in cm/inch):',
    file_size_MB: 'Dateigröße (in MB):',
    file_size: 'Dateigröße:',
    print_size: 'Abmessungen [h x b]:',
    color_space: 'Farbraum:',
    download: 'Herunterladen',
  },
  advertising_materials: 'Werbemittel',
  note: 'Hinweis',
  zoom_image: 'Bild vergrößern',
  tabel: {
    selectAll: 'Alle auswählen',
    select: 'Auswählen',
    assetNumber: 'AS-Nummer',
    name: 'Name',
    keyWords: 'Key Words',
  },
  overview: {
    partnernet: {
      id: 'partnernet',
      label: 'Partnernet',
    },
    magenta_smart: {
      id: 'magenta_smart',
      label: 'Magenta SmartHome',
    },
  },
  links: {
    delete: {
      link: '#link1',
      label: 'Aus Downloadkorb entfernen',
    },
    download_link: {
      link: '#link2',
      label: 'Download-Link per E-Mail senden',
    },
    download_low_resolution: {
      link: '#link3',
      label: 'In geringer Auflösung herunterladen',
    },
  },
  download: 'Herunterladen',
  date_format: 'tt.mm.jjjj',
  progressBar: {
    download_is_finished: 'Das Herunterladen ist beendet',
    download_failed: 'Herunterladen fehlgeschlagen',
  },
  no_options_available: 'Keine Option verfügbar',
  assetStatus: {
    published: 'Veröffentlicht',
    not_published: 'Nicht veröffentlicht',
    publication_expired: 'Veröffentlichung abgelaufen',
    publication_stopped: 'Veröffentlichung gestoppt',
    publication_planned: 'Veröffentlichung geplant',
    archived: 'Archiviert',
    require_approval: 'Genehmigung angefragt',
    draft: 'Entwurf',
  },
} as const;

export default shopPage;
