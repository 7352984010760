import { DateTimePicker } from 'Components';
import { customStyles, themeSelect } from 'Components/CustomSelect';
import React, { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
import { useT } from '../translations';
import { Controller, useFormContext } from 'react-hook-form';
import { CategoryField } from 'Features/Shop';
import { useQueryParams } from 'Helpers/useQueryParams';
import classNames from 'classnames';

const AssetInformation: React.FC<{
  canEdit: boolean;
  disableCategory: boolean;
  categoryFields: CategoryField[];
  categoriesOptions: any;
  setSelectedOptionCategory: Dispatch<
    SetStateAction<
      | {
          value: string;
          label?: string | undefined;
          id: string;
          parent_id: number;
        }
      | undefined
    >
  >;
}> = ({ disableCategory, canEdit, categoryFields, categoriesOptions, setSelectedOptionCategory }) => {
  const t = useT();
  const {
    control,
    register,
    watch,
    resetField,
    formState: { errors },
  } = useFormContext();
  const editMode = useQueryParams().get('editmode') !== null;
  const inputCss =
    'disabled:opacity-40 disabled:cursor-not-allowed min-w-[175px] px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:border-primary-500 focus:outline-none focus:ring-primary';

  return (
    <>
      <div className="flex flex-col gap-2">
        <h2 className="font-extrabold">{t.assetInfoSection.title}</h2>
        <label htmlFor="category">{t.assetInfoSection.category}</label>
        <Controller
          name="category"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Select
                {...field}
                value={watch('category') ?? ''}
                name="category"
                options={categoriesOptions}
                isClearable={true}
                closeMenuOnSelect={true}
                styles={customStyles}
                theme={themeSelect}
                onChange={(option: any) => {
                  field.onChange(option);
                  setSelectedOptionCategory(option);
                }}
                placeholder={t.assetInfoSection.category}
                isDisabled={disableCategory ? disableCategory : !canEdit}
              />
            );
          }}
        />
        {errors.category && <p className="text-base italic text-red-60 ">Asset category must be filled in!</p>}

        {watch('category') &&
          categoryFields.length > 0 &&
          categoryFields.map((categoryField) => {
            return (
              <React.Fragment key={categoryField.key}>
                {categoryField.required && (
                  <>
                    <label htmlFor={categoryField.key}>{categoryField.display_name}*</label>
                    <Controller
                      name={`category_fields.${categoryField.key}`}
                      control={control}
                      rules={{ required: `${categoryField.display_name} ${t.field_required_asset}` }}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            value={watch(`category_fields.${categoryField.key}`) ?? ''}
                            options={categoryField.options.map((op) => ({ value: op.id, label: op.title }))}
                            isClearable={true}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            theme={themeSelect}
                            onChange={(o) => field.onChange(o)}
                            isDisabled={editMode}
                          />
                        );
                      }}
                    />
                    {errors?.['category_fields']?.[categoryField.key] && (
                      <p className="text-base italic text-red-60 ">{`${errors?.['category_fields']?.[categoryField.key].message}`}</p>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
      </div>

      <div className="flex flex-col gap-2">
        <h2 className="font-extrabold">{t.optionalInfoSection.title}</h2>

        <label>{t.optionalInfoSection.note}</label>
        <input
          readOnly={editMode}
          disabled={editMode}
          type="text"
          {...register('comment')}
          placeholder={t.optionalInfoSection.note}
          className={inputCss}
        />

        <label htmlFor="keywords">{t.optionalInfoSection.keywords}</label>
        <input
          readOnly={editMode}
          disabled={editMode}
          id={t.optionalInfoSection.keywords}
          {...register('description')}
          type="text"
          placeholder={t.optionalInfoSection.keywords}
          multiple
          className={inputCss}
        />

        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <p>{t.optionalInfoSection.startPeriod}</p>
            <Controller
              name={`usable_from`}
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <DateTimePicker
                    {...field}
                    id={'usable_from'}
                    innerRef={(elem) => {
                      elem && ref(elem.input);
                    }}
                    key={'usable_from'}
                    type={'date'}
                    name={'usable_from'}
                    value={field.value}
                    placeholder={'YYYY-MM-DD'}
                    onChangeTime={(date) => {
                      field.onChange(date);
                      resetField('usable_to', {
                        defaultValue: null,
                      });
                    }}
                    className={classNames('!border-gray-300 shadow-sm bg-white text-gray-700', editMode && 'opacity-70 bg-gray-0')}
                    disabled={editMode}
                  ></DateTimePicker>
                );
              }}
            />
            {errors?.['usable_from'] && <p className="text-base italic text-red-60  mt-2">{t.field_required}</p>}
          </div>
          <div className="flex-1">
            <p>{t.optionalInfoSection.endPeriod}</p>
            <Controller
              name={`usable_to`}
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <DateTimePicker
                    {...field}
                    id={'usable_to'}
                    key={'usable_to'}
                    innerRef={(elem) => {
                      elem && ref(elem.input);
                    }}
                    type={'date'}
                    name={'usable_to'}
                    value={field.value}
                    minDate={watch('usable_from')}
                    placeholder={'YYYY-MM-DD'}
                    onChangeTime={(date) => {
                      field.onChange(date);
                    }}
                    disabled={!watch('usable_from') || editMode}
                    className={classNames(
                      '!border-gray-300 shadow-sm bg-white text-gray-700',
                      (!watch('usable_from') || editMode) && 'opacity-70 bg-gray-0'
                    )}
                  ></DateTimePicker>
                );
              }}
            />
            {errors?.['usable_to'] && <p className="text-base italic text-red-60 mt-2">{t.field_required}</p>}
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <p>{t.optionalInfoSection.publicationFromPeriod}</p>
            <Controller
              name={`publish_from`}
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <DateTimePicker
                    {...field}
                    id={'publish_from'}
                    innerRef={(elem) => {
                      elem && ref(elem.input);
                    }}
                    key={'publish_from'}
                    type={'date'}
                    name={'publish_from'}
                    value={field.value ?? ''}
                    placeholder={'YYYY-MM-DD'}
                    onChangeTime={(date) => {
                      field.onChange(date);
                      resetField('publish_to', {
                        defaultValue: null,
                      });
                    }}
                    className={classNames('!border-gray-300 shadow-sm bg-white text-gray-700', editMode && 'opacity-70 bg-gray-0')}
                    disabled={editMode}
                  ></DateTimePicker>
                );
              }}
            />
            {errors?.['publish_from'] && <p className="text-base italic text-red-60 mt-2">{t.field_required}</p>}
          </div>
          <div className="flex-1">
            <p>{t.optionalInfoSection.publicationToPeriod}</p>
            <Controller
              name={`publish_to`}
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <DateTimePicker
                    {...field}
                    id={'publish_to'}
                    innerRef={(elem) => {
                      elem && ref(elem.input);
                    }}
                    key={'publish_to'}
                    type={'date'}
                    name={'publish_to'}
                    value={field.value ?? ''}
                    minDate={watch('publish_from')}
                    placeholder={'YYYY-MM-DD'}
                    onChangeTime={(date) => {
                      field.onChange(date);
                    }}
                    disabled={!watch('publish_from') || editMode}
                    className={classNames(
                      '!border-gray-300 shadow-sm bg-white text-gray-700',
                      (!watch('publish_from') || editMode) && 'opacity-70 bg-gray-0'
                    )}
                  ></DateTimePicker>
                );
              }}
            />
            {errors?.['publish_to'] && <p className="text-base italic text-red-60 mt-2">{t.field_required}</p>}
          </div>
        </div>

        {watch('category') &&
          categoryFields.length > 0 &&
          categoryFields.map((categoryField) => {
            return (
              <React.Fragment key={categoryField.key}>
                {!categoryField.required && (
                  <>
                    <label htmlFor={categoryField.key}>{categoryField.display_name}</label>
                    <Controller
                      name={`category_fields.${categoryField.key}`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            id={categoryField.key}
                            {...field}
                            value={watch(`category_fields.${categoryField.key}`) ?? ''}
                            options={categoryField.options.map((op) => ({ value: op.id, label: op.title }))}
                            isClearable={true}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            theme={themeSelect}
                            onChange={(o) => field.onChange(o)}
                            isDisabled={editMode}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};

export default AssetInformation;
