import { api } from './api';

// Returns all published assets with paging and sorting support
export const GETassets = (params) => api.get<AssetsResponse>(`/assets`, { params });

// Find asset by id
export const GETbyID = (id) => api.get<AssetDetails>('/assets/' + id);

// Returns assets count for each field with the given field type
export const GETcount = (params) => api.get<FieldCount[]>(`/assets/count`, { params });

// Allows to download multiple assets as a single zip file
export const GETdownload = (assets_ids, lowResolution, onDownloadProgress: (progressEvent: any) => void) =>
  api.get<AssetsDownloaded>(`/assets/download_data_link`, {
    params: { assets_ids: assets_ids, resolution: lowResolution ? 'low' : 'high' },
    onDownloadProgress: onDownloadProgress,
  });

// Sends a temporay download link for the assets to the given recipients
export const POSTshare = (formDataRequest) => api.post(`/assets/share`, { ...formDataRequest });

// Retrieves current user cart items
export const GETcart = () => api.get<Asset[]>(`/cart`);

// Get all Icons for eyecatcher
export const GETIcons = async () => {
  const response = await api.get<IIconBE[]>('/icons');
  return response.data;
};

// Get all EyeCatchers
export const GETEyeCatchers = async (params) => {
  const response = await api.get<IEyecatcherResponse>('/eye_catchers', { params });
  return response.data;
};

// Creates a new EyeCatcher
export const CreateEyeCatchers = async (formData) => {
  const headers: any = {};
  headers['Content-Type'] = 'multipart/form-data';
  const response = await api.post<{ id: string }>('/eye_catchers', formData, { headers });
  return response.data;
};

// Get EyeCatcher by ID
export const GETEyeCatcherByID = async (id?: string) => {
  const response = await api.get<{ eye_catcher: IEyeCatcher }>(`/eye_catchers/${id}`);
  return response.data;
};

// Edit an EyeCatcher
export const updateEyeCatcher = async ({ formData, id }) => {
  const headers: any = {};
  headers['Content-Type'] = 'multipart/form-data';
  const response = await api.patch(`/eye_catchers/${id}`, formData, { headers });
  return response.data;
};

// Create new version from existing Eyecatcher
export const createEyeCatcherVersion = async ({ formData, id }) => {
  const headers: any = {};
  headers['Content-Type'] = 'multipart/form-data';
  const response = await api.patch<{ id: string }>(`/eye_catchers/${id}/new_version`, formData, { headers });
  return response.data;
};

// Request approval on EyeCatcher with status draft
export const requestApproval = async ({ id }) => {
  const response = await api.post(`/eye_catchers/${id}/request_approval`);
  return response.data;
};

// Approve EyeCatcher
export const approveEyeCatcher = async (id) => {
  const response = await api.post(`/eye_catchers/${id}/approve`);
  return response.data;
};

// Reject EyeCatcher
export const rejectEyeCatcher = async ({ id, message }) => {
  const response = await api.post<any>(`/eye_catchers/${id}/reject`, { message: message });
  return response.data;
};

// Adds new assets to cart
export const PATCHcart = (assets_ids) => api.patch(`/cart`, { assets_ids });

// Removes assets from cart
export const DELETEcart = (assets_ids: string[]) => api.delete(`/cart`, { data: { assets_ids } });

// Retries all categories
export const GETcategories = () => api.get(`/categories`);

// Retries all faq categories
export const GETfaq_categories = () => api.get<FAQCategory[]>(`/faq_categories`);

export const GETDocuments = () => api.get<Documents[]>(`/documents`);

// Retries all faqs
export const GETfaqs = () => api.get<FAQ[]>(`/faqs`);

// Returns all field types
export const GETfield_types = (field_types) => api.get(`/field_types`, { params: { field_types } });

// Retrieves only the fields with given field type
export const GETfields = (field_types) => api.get<FieldOption[]>(`/fields`, { params: { field_types } });

// Find share by uuid
export const GETshareByUUID = (uuid: string, contactUuid: string) => api.get(`/shares/${uuid}?contact_uuid=${contactUuid}`);

// Downloads all the shared files as a zip
export const GETshareDownload = (uuid: string, contactUuid: string, onDownloadProgress: (progressEvent: any) => void) =>
  api.get(`/shares/${uuid}/download?contact_uuid=${contactUuid}`, { responseType: 'blob', onDownloadProgress: onDownloadProgress, timeout: 0 });

// Retries all sliders
export const GETsliders = () => api.get<Slide[]>(`/sliders`);

// Retries all static pages
export const GETstatic_pages = () => api.get<StaticPage[]>(`/static_pages`);

// Find static page by slug
export const GETPage = (slug: string) => api.get<StaticPage>(`/static_pages/${slug}`);

// Retries information about current user
export const GETme = () => api.get(`/users/me`);

// Publication role request
export const publicationAccessRequest = (accessRequestParams: PublicationAccessRequest) =>
  api.post(`/publication_role_requests`, { ...accessRequestParams });

// Group join request details
export const getApproveRequest = (requestUuid: string) => api.get<PublicationRoleRequests>(`/publication_role_requests/${requestUuid}`);

// Approve/reject the group join request
export const respondApprovalRequest = (requestUuid: string, status: 'approved' | 'denied') =>
  api.patch(`/publication_role_requests/${requestUuid}`, { status });
