import { Link } from 'react-router-dom';

interface LinkProps {
  disabled?: boolean;
  href?: any;
  onClick?: () => void;
  label: string;
  icon?: any;
  classes?: string;
  state?: { prevPath: string };
}

export function CustomLink(props: LinkProps) {
  const { disabled, href, label, onClick, classes, state } = props;
  return (
    <>
      {'onClick' in props ? (
        <button
          {...(disabled && { tabIndex: -1 })}
          className={`${classes} text-left underline ${
            disabled ? 'pointer-events-none cursor-not-allowed  text-gray-50' : 'text-blue-60 hover:text-blue-70'
          }`}
          onClick={onClick}
        >
          {label}
        </button>
      ) : (
        <Link
          {...(disabled && { tabIndex: -1 })}
          className={`${classes} underline  ${
            disabled ? 'pointer-events-none cursor-not-allowed text-gray-50' : 'text-blue-60 hover:text-blue-70'
          } text-left`}
          to={href || '#'}
          state={state}
        >
          {label}
        </Link>
      )}
    </>
  );
}
