const authenticationPage = {
  personal_data: 'Persönliche Daten',
  form_subtitle_one: 'VO/VP Nummer',
  form_subtitle_two: 'Weitere Daten',
  fields: {
    vo_number: 'VO Nummer',
    vp_number: 'VP Nummer',
    telephone: 'Telefonnummer',
    company: 'Unternehmen',
    area: 'Bereich',
    partner: 'Handelspartner',
  },
  full_name: 'Vollständiger Name',
  email: 'E-Mail Adresse',
  created_at: 'Anmeldedatum',
  save: 'Speichern',
  privacy_policy: 'Datenschutz-Bestimmungen',
  successfully_saved: 'Erfolgreich gespeichert!',
  not_valid: 'ist nicht gültig!',
  edit: 'Bearbeiten',
  abort: 'Abbrechen',
};

export default authenticationPage;
