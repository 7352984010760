import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EmailState {
  assetsSelected: Array<Asset>;
}

export const initialState: EmailState = {
  assetsSelected: [],
};

export const { actions, reducer, name } = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setAssetsSelected: (state, action: PayloadAction<Asset[]>) => {
      state.assetsSelected = action.payload;
    },
    addAsset: (state, action: PayloadAction<Asset | undefined>) => {
      state.assetsSelected = action.payload ? state.assetsSelected.concat(action.payload) : state.assetsSelected;
    },
    removeAsset: (state, action: PayloadAction<Asset | undefined>) => {
      state.assetsSelected = state.assetsSelected.filter((asset) => asset.id !== action.payload?.id);
    },
  },
});

export const slice = (state: RootState): EmailState => state[name] || initialState;

export const selectedAssets = (state: RootState) => {
  return slice(state).assetsSelected;
};
