import React, { useCallback, useState } from 'react';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { BsEyeSlash, BsDownload, BsZoomOut, BsZoomIn } from 'react-icons/bs';
import 'react-quill/dist/quill.snow.css';
import { LogoClaim } from 'Components';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { getSVG } from '../lib/utils';
import { useT } from '../../EyeCatcherOverview/translations';
import classNames from 'classnames';

const Previewer = () => {
  const t = useT();
  const { watch } = useFormContext();
  const scales = ['3', '2', '1', '.75', '.5', '.3', '.1'];
  const [zoom, setZoom] = React.useState<number>(2);
  const [showPreview, setShowPreview] = useState(true);

  const isDataInPriceSection =
    watch('text_line_above_price') || watch('text_after_price') || watch('price') || watch('text_before_price') || watch('text_bellow_price');

  const generatePDF = useCallback(async () => {
    const printSelector = document?.querySelector('#print') as HTMLElement;

    const SCALE = 4;
    const canvasWidth = printSelector.offsetWidth * SCALE;
    const canvasHeight = printSelector.offsetHeight * SCALE;
    const w = 60;
    const h = (canvasHeight / canvasWidth) * 60;
    const isPortrait = w < h;

    let pdf = new JsPDF({
      orientation: isPortrait ? 'p' : 'l',
      unit: 'mm',
      format: [60, (canvasHeight / canvasWidth) * 60],
      putOnlyUsedFonts: true,
      compress: true,
    });

    await html2canvas(printSelector, {
      scrollX: 0,
      scrollY: 0,
      scale: 4,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      let imgData = canvas.toDataURL('image/png', 1);
      let pageWidth = pdf.internal.pageSize.getWidth();
      let pageHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'png', 0, 0, pageWidth, pageHeight);
    });

    if (watch('legal_text')) {
      const printSeconPgeSelector = document?.querySelector('#print-page-2') as HTMLElement;
      const canvas2Width = printSeconPgeSelector.offsetWidth * SCALE;
      const canvas2Height = printSeconPgeSelector.offsetHeight * SCALE;

      await html2canvas(printSeconPgeSelector, { scrollX: 0, scrollY: 0, scale: 4, useCORS: true, allowTaint: true }).then(function (canvas) {
        let imgData = canvas.toDataURL('image/png', 1);
        const w = 60;
        const h = Math.ceil((canvas2Height / canvas2Width) * 60);
        const isPortrait = w < h;
        pdf.addPage([w, h], isPortrait ? 'p' : 'l');
        pdf.addImage(imgData, 'png', 0, 0, w, h);
      });
    }

    pdf.save(`${watch('name')}.pdf`);
  }, [watch]);

  const isPriceBgColorWhite = watch('price_background') === 'white';
  return (
    <div className="w-[38vw] pt-4 relative">
      <div className="sticky top-20">
        <h1 className="text-[#191919] font-bold leading-10">{t.preview}</h1>
        <div className="bg-gray-200 p-4 h-full flex flex-col gap-8 items-center">
          <div className="flex justify-center">
            <div className="flex flex-row items-center border-2 border-gray-400 rounded-md bg-white">
              <div className="border-r-2 border-gray-300 flex flex-row items-center p-2">
                <button
                  type="button"
                  className="btn px-2 py-2 text-gray-700 font-medium rounded transition duration-150 ease-in-out"
                  onClick={() => setShowPreview(!showPreview)}
                >
                  <BsEyeSlash size={16} />
                </button>
                <button
                  type="button"
                  className="btn px-2 py-2 text-gray-700 font-medium rounded transition duration-150 ease-in-out"
                  onClick={() => {
                    generatePDF();
                  }}
                >
                  <BsDownload size={16} />
                </button>
              </div>
              <div className="flex flex-row gap-2 p-2">
                <button
                  type="button"
                  className="btn px-2 py-2 text-gray-700 font-medium rounded transition duration-150 ease-in-out"
                  onClick={() => setZoom(zoom - 1)}
                >
                  <BsZoomIn size={16} />
                </button>
                <button
                  type="button"
                  className="btn px-2 py-2 text-gray-700 font-medium rounded transition duration-150 ease-in-out"
                  onClick={() => {
                    setZoom(zoom + 1);
                  }}
                >
                  <BsZoomOut size={16} />
                </button>
                <select
                  className={classNames(
                    'px-2 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700',
                    'hover:border-primary-500',
                    'focus:outline-none focus:ring-primary min-w-[4.5rem]'
                  )}
                  value={zoom}
                  onChange={(e) => {
                    setZoom(+e.target.value);
                  }}
                >
                  {scales.map((val, idx) => (
                    <option key={val} value={idx}>
                      {Number(val) * 100}%
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {showPreview && (
            <div className="overflow-auto">
              <PreviewerContainer id="print" style={{ transform: `scale(${scales[Number(zoom)]})` }}>
                <div id="head">
                  <p>{watch('pre_headline')}</p>
                  <h1>{watch('headline')}</h1>
                  <p>{watch('subline')}</p>
                </div>
                {watch('bullet_points') &&
                  watch('bullet_points').length > 0 &&
                  watch('bullet_points').map((bulletPoint, index) => (
                    <BulletPoints bulletPointBg={bulletPoint.bullet_points_background} key={index}>
                      <BulletPoint className="ql-editor">
                        <div>
                          <span>{bulletPoint.icon && getSVG(bulletPoint.icon)}</span>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: bulletPoint.text ?? '' }} />
                      </BulletPoint>
                    </BulletPoints>
                  ))}
                {isDataInPriceSection && (
                  <PriceWrapper isBgColorWhite={isPriceBgColorWhite}>
                    <p>{watch('text_line_above_price')}</p>
                    <p>
                      {watch('text_before_price')}{' '}
                      <ValueCross isCancelPrice={watch('canceled_price')}>
                        {watch('price') && (
                          <>
                            {watch('price')} &euro;<sup>*</sup>
                          </>
                        )}
                      </ValueCross>
                      {watch('text_after_price')}
                    </p>
                    <p> {watch('text_bellow_price')}</p>
                  </PriceWrapper>
                )}

                <LogoWrapper>
                  <LogoClaim style={{ height: 30, color: 'white' }} />
                </LogoWrapper>
              </PreviewerContainer>
              {watch('legal_text') && (
                <LegalText id="print-page-2" style={{ transform: `scale(${scales[Number(zoom)]})` }}>
                  <div className="ql-editor" dangerouslySetInnerHTML={{ __html: watch('legal_text') ?? '' }} />
                </LegalText>
              )}
            </div>
          )}

          {!showPreview && (
            <div className="flex flex-col mt-8 items-center min-h-[700px]">
              <h2 className="font-bold text-lg">Preview disabled</h2>
              <div className="flex w-full items-center justify-center">
                <p className="flex w-full items-center gap-1">
                  Click the <BsEyeSlash size={14} /> -icon to enable the preview
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Previewer;

const PreviewerContainer = styled.div`
  width: 60mm;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1em;
  font-size: 12px;
  gap: 1em;
  #head {
    color: white;
    h1 {
      font-size: 2.5em;
      padding-bottom: 0.25em;
      font-weight: 800;
    }
  }
  #bullet_points {
    font-size: 1.25em;
    padding: 0.5em;
    margin-bottom: 1.5em;
  }
`;

const BulletPoints = styled.div<{ bulletPointBg: string }>`
  background-color: ${({ bulletPointBg }) => (bulletPointBg === 'white' ? 'white' : 'var(--primary)')};
  color: ${({ bulletPointBg }) => (bulletPointBg === 'white' ? 'var(--primary)' : 'white')};
  display: flex;
  flex-direction: column;
`;

const BulletPoint = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  height: fit-content !important;
`;

const PriceWrapper = styled.div<{ isBgColorWhite: boolean }>`
  background-color: ${({ isBgColorWhite }) => (isBgColorWhite ? 'white' : 'var(--primary)')};
  color: ${({ isBgColorWhite }) => (isBgColorWhite ? 'var(--primary)' : 'white')};
  padding: 1em;
  font-size: 1em;
  span {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 2.25em;
  }
`;

const LogoWrapper = styled.div`
  margin-top: auto;
`;

const ValueCross = styled.span<{ isCancelPrice: boolean }>`
  font-size: 1.875rem;
  line-height: 2.25rem;
  position: relative;
  font-weight: 700;
  ${({ isCancelPrice }) =>
    isCancelPrice &&
    `
    &:before {
      position: absolute;
      content: ' ';
      left: 0;
      width: calc(100% - 4px);
      top: 39%;
      right: -2rem;
      border-top: 2px solid;
      transform: rotate(-15deg);
    }
  `}
`;

const LegalText = styled.div`
  width: 60mm;
  background-color: white;
  margin-top: 1.5em;
  padding-bottom: 1em;
`;
