import addScript from 'Helpers/scripts/addScript';

const Scripts = () => {
  if (process.env.NODE_ENV === 'development') return null;

  if (process.env.REACT_APP_FRESHDESK_URL && process.env.REACT_APP_FRESHDESK_ID) {
    window.fwSettings = { widget_id: process.env.REACT_APP_FRESHDESK_ID };
    (() => {
      if (typeof window.FreshworksWidget !== 'function') {
        const n = (...args) => n.q.push(args);
        n.q = [];
        window.FreshworksWidget = n;
      }
    })();
    addScript(process.env.REACT_APP_FRESHDESK_URL, true);
  }

  // FIX for IE
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  return null;
};

export default Scripts;
