import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface CartState {
  assets: Array<Asset>;
  total: number;
}

export const initialState: CartState = {
  assets: [],
  total: 0,
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Asset[]>) => {
      state.assets = action.payload;
    },
  },
});

export default slice;

export const { actions, reducer, name } = slice;
