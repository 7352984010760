import styled from 'styled-components';
import { useLocation } from 'react-router';
import { BrandBar } from './BrandBar';
import { AppNavbar } from './AppNavbar';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsCart2, BsSearch } from 'react-icons/bs';
import { BiUser } from 'react-icons/bi';
import { attemptLogout } from 'Features/Authentication/lib/actions';
import { cart } from 'Features/Cart/lib';
import { authStore } from 'Features/Authentication/lib';
import { useAppDispatch, useAppSelector } from 'Config';
import { CustomDropDown, InlineSearch } from 'Components';
import { useT } from './translations';
import { t as tReplace } from 'Components/Trans';
import { API_URL } from 'Env';
import { nFormatter } from 'Helpers';

export function AppHeader({ data }: { data: Category[] }) {
  const t = useT();
  const location = useLocation();
  const SCROLL_THESHOLD = 50;
  const user = useAppSelector(authStore.selectUser);
  const userWerbenet = useAppSelector(authStore.selectUserWerbenet);
  const totalCartAssets = useAppSelector(cart.total);
  const [s, ss] = useState(document.documentElement.clientWidth < 800);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    dispatch(attemptLogout(true)).finally(() => window.location.reload());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const node = window.pageYOffset;
      const forceHide = document.documentElement.clientWidth < 800;
      if (node > SCROLL_THESHOLD || forceHide) {
        ss(true);
      } else if (node < SCROLL_THESHOLD) {
        ss(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    let i: NodeJS.Timeout;
    function updateSize() {
      const forceHide = document.documentElement.clientWidth < 800;
      clearTimeout(i);
      i = setTimeout(() => {
        ss(forceHide ? true : window.pageYOffset > 15);
      }, 100);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      clearTimeout(i);
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    if (showSearch) {
      refInput.current?.focus();
    }
  }, [showSearch]);

  return (
    <Container className={`${s ? 'hide' : ''} shadow-xl`}>
      <BrandBar hide={s} />
      <AppNavbar data={data || []} hide={s} showSearch={showSearch}>
        <InlineSearch onClose={() => setShowSearch(false)} refInput={refInput}></InlineSearch>

        <ul className="flex px-4">
          <li className="ring-black font-bold p-2 px-4">
            <button title={t.search} aria-label={t.search} onClick={() => setShowSearch(!showSearch)}>
              <BsSearch size={30} />
            </button>
          </li>
          <li className="ring-black font-bold p-2 px-4">
            <NavLink
              title={totalCartAssets > 0 ? tReplace(t.shopping_cart_contains, { count: totalCartAssets }) : t.shopping_cart}
              aria-label={totalCartAssets > 0 ? tReplace(t.shopping_cart_contains, { count: totalCartAssets }) : t.shopping_cart}
              className="relative block"
              to="/cart"
              type="button"
            >
              <BsCart2 size={30} className="!shadow-none" />
              {totalCartAssets > 0 && (
                <span className="absolute -top-[6px] -right-[4px] h-[14px] min-w-[14px] flex text-[11px] font-bold bg-primary-500 text-white rounded-3xl justify-center items-center px-0.5 py-0">
                  {nFormatter(totalCartAssets, 1)}
                </span>
              )}
            </NavLink>
          </li>
          <li className="ring-black font-bold p-2 px-4">
            <CustomDropDown
              id={'user-dropdown'}
              label=""
              menuButtonTitle={t.user_dropdown}
              {...('/profile' === location.pathname && { classes: 'active' })}
              showChevron={false}
              itemsLabel={`${user?.first_name} ${user?.last_name}`}
              Icon={<BiUser size={30} className="!shadow-none" />}
              items={[
                { label: t.dropDownOptions.profile, href: '/profile' },
                ...(userWerbenet?.role.toLowerCase() !== 'user'
                  ? [
                      {
                        label: t.dropDownOptions.admin,
                        href: `${API_URL || ''}`.split('/').slice(0, -3).join('/') + '/admin',
                        newTab: true,
                      },
                    ]
                  : []),
                { label: t.dropDownOptions.logOut, onSelect: logout },
              ]}
              aria-label={t.dropDownOptions.profile}
            />
          </li>
        </ul>
      </AppNavbar>
    </Container>
  );
}
const Container = styled.div`
  position: fixed;
  display: flex;
  flex-flow: col nowrap;
  align-items: flex-end;
  padding-top: 68px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  top: 0;
  width: 100%;
  z-index: 99;

  + main {
    flex: 1;
    --navbar-height: 118px;
    position: relative;
    padding-top: var(--navbar-height);
    border-radius: 66px;
  }

  &.hide {
    position: fixed;
    padding-top: 4px;
  }
  @media only screen and (max-width: 800px) {
    + main {
      padding-top: 75px;
    }
  }
`;
