const eyeCatcherOverView = {
  helmetTitle: 'Eye Catcher overview',
  breadcrumbTitle: 'Eye-Catcher Generator',
  title: 'Eye-Catcher overview',
  generateEyeCatcher: 'Generate new Eye-Catcher',
  preview: 'Preview',
  noData: 'There is no data to see!',
  tabs: {
    myEyeCatcher: 'My Eye-Catchers',
    usersEyeCatchers: 'Eye-Catchers from other users',
  },
  state: {
    title: 'Show',
    all: 'All',
    favorites: 'Favorites',
    draft: 'Draft',
    published: 'Published',
    templates: 'Templates',
    deleted: 'Deleted',
    requireApproval: 'Require Approval',
    approved: 'Approved',
  },
  search: 'Search',
  dropdownActions: {
    publish: 'Publish',
    duplicate: 'Duplicate',
    saveAsTemplate: 'Save as Template',
    generateNewVersion: 'Generate new version',
    unpublish: 'Unplublish(Save as draft)',
    requireApproval: 'Require Approval',
  },
  buttons: {
    edit: 'Bearbeiten',
    delete: 'Löschen',
    view: 'View',
  },
} as const;

export default eyeCatcherOverView;
