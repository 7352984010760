import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export const Page: FC<{ children: ReactNode }> = ({ children }) => {
  return <PageComponent className="page main-page pb-8">{children}</PageComponent>;
};

const PageComponent = styled.div`
  max-width: var(--page-width);
  margin: 0 auto 3rem auto;
`;
