const download = {
  page_title: 'Download Page',
  title: {
    200: 'The following file (s) were offered to you by email',
    404: 'Unfortunately you cannot download these files anymore as the validity of this download link has expired.',
    500: 'Sorry, something went wrong!',
  },
  body: {
    200: 'The download file contains the following documents (%{size} MB):',
    500: "We're sorry, but we where unable to complete your request.",
  },
  download: 'Download',
  progressBar: {
    download_is_finished: 'Download is finished',
    download_failed: 'Download Failed',
  },
} as const;

export default download;
