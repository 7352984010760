/* eslint-disable react-hooks/exhaustive-deps */
import { AssetsLinks } from './components/AssetsLinks';
import { Pagintaion } from 'Components/Pagination';
import { CustomDropDown } from 'Components';
import { authStore } from 'Features/Authentication/lib';
import { useAppDispatch, useAppSelector } from 'Config';
import { API_URL } from 'Env';
import { cart } from 'Features/Cart/lib';
import { email } from 'Features/EmailPage/lib';
import { selectAssetIdsInCart } from 'Features/Cart/lib/selectors';
import { breakpoint } from 'Helpers';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { BsCalendarDate, BsCartDash, BsCartPlus, BsInfoCircle, BsZoomIn } from 'react-icons/bs';
import { MdOutlinePrivacyTip, MdSortByAlpha } from 'react-icons/md';
import styled from 'styled-components';
import { AssetInfoModal } from './components/AssetInfoModal';
import { useT } from './translations';
import { useT as useTHomePage } from 'Features/HomePage/translations';
import defaultImgSrc from 'Assets/nopreview.png';
import AssetPreview from './components/AssetPreview';
import ActionSortIcon from '../../Components/Icons/ActionSortIcon';

interface Props {
  data: AssetsResponse;
  hasFilterApplied: boolean;
  title: string | JSX.Element | ReactNode;
  setPage: (n: number) => void;
  onSelect: (n: Asset) => void;
  sort: [keyof Asset | '', 'ASC' | 'DESC', string];
  onSortChange: (n: Props['sort']) => void;
  onAdminFilter: (n: Props['adminFilter']) => void;
  adminFilter: keyof typeof superAdminFilter;
  category?: Category;
}

export const superAdminFilter = {
  Alle: null,
  'Nicht veröffentlicht': ['not_published'],
  'Veröffentlicht  ': ['published'],
  'Veröffentlichung gestoppt': ['publication_stopped'],
  'Veröffentlichung abgelaufen': ['publication_expired'],
  Archiviert: ['archived'],
  'Nicht mehr veröffentlicht gesamt': ['publication_stopped', 'publication_expired', 'archived'],
};
const superAdminFilterKeys = Object.keys(superAdminFilter) as Array<keyof typeof superAdminFilter>;

export default function AssetsGrid({
  data,
  title,
  setPage,
  onSelect,
  onSortChange,
  adminFilter,
  onAdminFilter,
  sort,
  category,
  hasFilterApplied,
}: Props) {
  const t = useT();
  const tHomePage = useTHomePage();
  const dispatch = useAppDispatch();
  const [ids, setIds] = useState<Set<string>>(new Set());
  const [assetsSelected, setAssetsSelected] = useState<Asset[]>([]);
  const [idsForCart, setIdsForCart] = useState<string[]>([]);
  const cartIds = useAppSelector(selectAssetIdsInCart);
  const [assetPreview, setAssetPreview] = useState<Asset>();
  const [hasTermsOfUse, setTermsOfUse] = useState<boolean>(false);
  const role = useAppSelector(authStore.selectUser)?.role.toLowerCase();
  const isSuperAdmin = role === 'super_admin' || role === 'admin';
  const userWerbenet = useAppSelector(authStore.selectUserWerbenet);
  const isSupportAgent = userWerbenet?.role.toLowerCase() === 'support_agent';

  useEffect(() => {
    setTermsOfUse((category && !!category.terms_of_use) || data?.assets.some((asset) => ids.has(String(asset.id)) && asset.category.terms_of_use));
    setIdsForCart(
      data?.assets.filter((asset) => ids.has(String(asset.id)) && !getCode(asset.state.code, tHomePage.status_batch)).map((asset) => asset.id)
    );
  }, [category, ids]);

  useEffect(() => {
    dispatch(email.setSelectedAssets(assetsSelected || []));
  }, [assetsSelected]);

  const handleChange = useCallback(
    (e) => {
      const { name, value, checked } = e.target;
      if (checked) {
        // if cheked and selectall checkbox add all fileds to selectedList
        if (name === 'all') {
          setIds(new Set(data?.assets.map((asset) => String(asset.id))));
          setAssetsSelected(data?.assets);
        } else {
          setIds((ids) => {
            ids.add(value);
            return new Set(ids);
          });
          const newAsset = data?.assets.find((asset) => String(asset.id) === value);
          if (newAsset) {
            setAssetsSelected(assetsSelected.concat([newAsset]));
          }
        }
      } else {
        // if uncheked and selectall checkbox add remove all fileds from selectedList
        if (name === 'all') {
          setIds(new Set());
          setAssetsSelected([]);
        } else {
          // if uncheked and specific checkbox remove specific field from selectedList
          setIds((ids) => {
            ids.delete(value);
            return new Set(ids);
          });
          setAssetsSelected(assetsSelected.filter((asset) => String(asset.id) !== value));
        }
      }
    },
    [data?.assets, assetsSelected]
  );

  const toggleAll = useCallback(() => {
    setIds((ids) => {
      setAssetsSelected(ids.size ? [] : data?.assets);
      return ids.size ? new Set() : new Set(data?.assets.map((asset) => String(asset.id)));
    });
  }, [data?.assets]);

  const [showDialog, setShowDialog] = useState<Asset>();
  const close = () => setShowDialog(undefined);
  const addToCart = useCallback(
    (row?: Asset) => {
      dispatch(cart.addItems(row ? [row] : data?.assets.filter((asset) => idsForCart.includes(asset.id))));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, idsForCart]
  );
  const removeFromCart = useCallback(
    (row: Asset) => {
      dispatch(cart.removeItems([row.id]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, ids]
  );

  return (
    <div className="flex-1 pl-8">
      {assetPreview && <AssetPreview assetPreview={assetPreview} onClose={() => setAssetPreview(undefined)}></AssetPreview>}
      <AssetInfoModal
        visible={!!showDialog}
        data={showDialog}
        onRequestClose={close}
        hasTermsOfUse={!!showDialog?.category.terms_of_use}
        onAddToCart={() => {
          addToCart(showDialog);
        }}
        onRemoveFromCart={() => {
          showDialog && removeFromCart(showDialog);
        }}
        canBeAddedToCart={!!showDialog && showDialog.state.code === 'published'}
        isInCart={!!showDialog && cartIds.has(String(showDialog.id))}
      ></AssetInfoModal>

      <h1 className="uppercase font-bold text-2xl mb-4">{title}</h1>

      <HeaderContainer>
        <h2 className="font-bold text-xl">{t.all_products}</h2>
        <div className="flex">
          {isSuperAdmin || isSupportAgent ? (
            <CustomDropDown
              id={'admin-filter-dropdown'}
              label={adminFilter}
              showChevron={true}
              items={superAdminFilterKeys
                .filter((key) => !(key === 'Nicht veröffentlicht' && isSupportAgent))
                .map((key) => ({
                  label: key,
                  onSelect: () => onAdminFilter(key),
                }))}
              classes="bg-gray-200 min-w-[120px] border flex items-center border-solid border-gray-300 shadow py-2 px-4 mr-4 rounded"
            />
          ) : null}
          <button
            disabled={!data.assets.length}
            className="bg-gray-200 border flex items-center border-solid border-gray-300 shadow py-2 px-4 mr-4 rounded"
            onClick={() =>
              onSortChange([sort[0] === 'created_at' ? 'name' : 'created_at', sort[0] === 'created_at' ? 'ASC' : 'DESC', sort[2] || 'Date'])
            }
          >
            {sort[0] === 'created_at' ? (
              <>
                <BsCalendarDate className="mr-2" /> {t.date}{' '}
              </>
            ) : (
              <>
                <MdSortByAlpha className="mr-2" /> {t.name}{' '}
              </>
            )}
          </button>

          <button
            disabled={!data.assets.length}
            className="bg-gray-200 border flex items-center border-solid border-gray-300 shadow py-2 px-4 rounded"
            onClick={() => onSortChange([sort[0] || 'created_at', sort[1] === 'ASC' ? 'DESC' : 'ASC', sort[2] || 'Date'])}
          >
            {sort[1] === 'ASC' ? <ActionSortIcon fillLeft="var(--primary)" /> : <ActionSortIcon fillRight="var(--primary)" />}
            {t[sort[1]]}
          </button>
        </div>
      </HeaderContainer>

      {!data.assets.length ? (
        <>{hasFilterApplied ? <h2 className="pb-12">{t.no_assets.filter}</h2> : <h2 className="pb-12">{t.no_assets.category}</h2>} </>
      ) : (
        <div className="pb-12">
          <AssetsLinks
            termsOfUse={hasTermsOfUse}
            ids={Array.from(ids)}
            assetsSelected={assetsSelected}
            selectAll={toggleAll}
            addToCart={() => addToCart()}
            addToCartDisabled={!idsForCart.length}
          ></AssetsLinks>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 md:gap-x-10 xl-grid-cols-5 gap-y-10 gap-x-6 ">
            {data?.assets.map((r) => {
              return (
                <Article
                  data-state={getCode(r.state.code, tHomePage.status_batch)}
                  color={r.state.color}
                  key={r.id}
                  className="container  mx-auto shadow-lg relative rounded-lg max-w-md hover:shadow-2xl transition duration-300"
                >
                  {r.category.terms_of_use && (
                    <MdOutlinePrivacyTip
                      size={20}
                      className="absolute z-20 text-primary-500 right-4 top-4"
                      title={t.terms_of_use.special_rules}
                    ></MdOutlinePrivacyTip>
                  )}
                  <div className="asset-preview h-44 overflow-hidden relative w-full">
                    <button
                      title={r.name}
                      aria-label={r.name}
                      tabIndex={-1}
                      onClick={() => onSelect(r)}
                      className="absolute p-4 top-12 left-0 w-full h-full z-10 cursor-pointer"
                    />
                    <label className="absolute p-4 top-0 left-0 w-full h-12 z-10 cursor-pointer">
                      <input
                        title={`${ids.has(String(r.id)) ? t.deselect : t.select}`}
                        aria-label={`${ids.has(String(r.id)) ? t.deselect : t.select}`}
                        value={String(r.id)}
                        checked={ids.has(String(r.id))}
                        onChange={handleChange}
                        type="checkbox"
                        className="rounded-2xl"
                      />
                    </label>

                    {r.alternative_image_url ?? r.file_preview_url ? (
                      <img
                        className="rounded-t-lg object-contain w-full h-full"
                        src={`${API_URL.slice(0, -8)}${r.alternative_image_url ?? r.file_preview_url}`}
                        onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                          if (e.target.src !== defaultImgSrc) {
                            e.target.onerror = null;
                            e.target.src = defaultImgSrc;
                            e.target.style.objectFit = 'cover';
                          }
                        }}
                        alt={`Bild ${r.name}`}
                      />
                    ) : (
                      <img className="rounded-t-lg object-cover w-full h-full" src={defaultImgSrc} alt={`Bild ${r.name}`} />
                    )}

                    {(!!r.alternative_image_url || !!r.file_preview_url) && (
                      <button
                        className="button-zoom z-20 absolute inset-1/4 m-auto text-primary-500 opacity-0 bg-white rounded-lg p-2"
                        title={t.zoom_image}
                        aria-label={t.zoom_image}
                        onClick={() => setAssetPreview(r)}
                      >
                        <BsZoomIn size={50} />
                      </button>
                    )}
                  </div>
                  <div className="p-6 h-[80px]">
                    <button
                      onClick={() => onSelect(r)}
                      className="w-full text-left lg:text-1xl hover:text-primary-500 cursor-pointer transition duration-200  font-bold text-gray-900 break-words"
                    >
                      <span className="line-clamp-3">{r.name}</span>
                    </button>
                  </div>
                  <div className="flex z-20 justify-between items-center p-6 my-4 h-[80px]">
                    <button
                      title={t.information}
                      aria-label={t.information}
                      onClick={() => setShowDialog(r)}
                      className="flex hover:text-primary-800 focus:text-primary-800 justify-center text-center items-center text-black rounded-xl p-4"
                    >
                      <BsInfoCircle size={22} />
                    </button>
                    {!r.category.terms_of_use &&
                      (cartIds.has(String(r.id)) ? (
                        <button
                          title={t.remove_from_shopping_cart}
                          aria-label={t.remove_from_shopping_cart}
                          onClick={() => removeFromCart(r)}
                          className="flex text-primary-500 bg-white justify-center text-center items-center rounded-xl p-3 border-2 border-primary-500 hover:text-primary-600"
                        >
                          <BsCartDash size={22} />
                        </button>
                      ) : (
                        r.state.code === 'published' && (
                          <button
                            title={t.add_to_shopping_cart}
                            aria-label={t.add_to_shopping_cart}
                            onClick={() => addToCart(r)}
                            className="flex text-white bg-primary-500 justify-center text-center items-center rounded-xl p-3 border-2 border-primary-500 hover:text-gray-20"
                          >
                            <BsCartPlus size={22} />
                          </button>
                        )
                      ))}
                  </div>
                </Article>
              );
            })}
          </div>
          <Pagintaion
            currentPage={data.pagination_meta.current_page}
            onSelect={setPage}
            totalCount={data.pagination_meta.total_count}
            totalPages={data.pagination_meta.total_pages}
          />
        </div>
      )}
    </div>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }
  scale-dropdown {
    width: 288px;
    margin: auto 0px;
  }

  @media (max-width: ${breakpoint.size.med}) {
    flex-flow: wrap;

    h1 {
      flex: 1 1 100%;
    }
    scale-dropdown {
      flex: 1 1 100%;
    }
  }
`;

const Article = styled.article<{ color: string; state?: string }>`
  --d: 4px; /* folded part */
  --c: ${() => 'gray'}; /* color */
  --f: 12px; /* ribbon font-size */
  position: relative;
  overflow: hidden;
  &[data-state]::before {
    content: attr(data-state);
    z-index: 1;
    font-size: var(--f);
    /* I : position & coloration */
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
    color: white;
    text-align: center;
    transform: translate(29.29%, -100%) rotate(45deg);
    transform-origin: bottom left;
    padding: 5px 35px calc(var(--d) + 5px);
    background: rgb(142, 142, 142);
  }

  .asset-preview {
    .button-zoom {
      width: fit-content;
      height: max-content;
      padding: 10px;
      opacity: 0;

      &:focus,
      &:hover {
        opacity: 0.9 !important;
      }
    }

    &:hover {
      .button-zoom {
        cursor: zoom-in;
        opacity: 0.6;
      }
    }
  }
`;

function getCode(r: string, t: Record<string, string>) {
  switch (r) {
    case 'publication_stopped':
      return t.publication_stopped;
    case 'publication_expired':
      return t.publication_expired;
    case 'not_published':
      return t.not_published;
    case 'draft':
      return t.draft;
    case 'require_approval':
      return t.require_approval;
    default:
      return r.includes('archived') ? t.archived : undefined;
  }
}
