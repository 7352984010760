import { useT } from '../translations';
import { useFormContext } from 'react-hook-form';

const Price: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const t = useT();
  const { register } = useFormContext();

  const inputCss =
    'disabled:opacity-40 disabled:cursor-not-allowed min-w-[175px] px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:border-primary-500 focus:outline-none focus:ring-primary';
  return (
    <div className="flex flex-col gap-2">
      <h2 className="font-extrabold">{t.priceSection.title}</h2>
      <div className="flex flex-row gap-4">
        <p>{t.priceSection.bgColor.name}:</p>
        <div className="inline-flex items-center gap-1">
          <input type="radio" value="magenta" {...register('price_background')} className="w-4 h-4 text-primary-600" disabled={!canEdit} />
          <label>{t.priceSection.bgColor.magenta}</label>
        </div>
        <div className="inline-flex items-center gap-1">
          <input type="radio" value="white" {...register('price_background')} className="w-4 h-4 text-primary-600" disabled={!canEdit} />
          <label>{t.priceSection.bgColor.white}</label>
        </div>
      </div>
      <label>{t.priceSection.textLineAbovePrice}</label>
      <input
        type="text"
        {...register('text_line_above_price')}
        placeholder={t.priceSection.textLineAbovePrice}
        className={inputCss}
        disabled={!canEdit}
      />

      <label>{t.priceSection.textBeforePrice}</label>
      <input type="text" {...register('text_before_price')} placeholder={t.priceSection.textBeforePrice} className={inputCss} disabled={!canEdit} />

      <label>{t.priceSection.price}</label>
      <div className="flex flex-row gap-2">
        <input type="number" {...register('price')} placeholder={t.priceSection.price} className={inputCss} disabled={!canEdit} />
        <div className="flex flex-row gap-2 items-center">
          <input type="checkbox" {...register('canceled_price')} disabled={!canEdit} />
          {t.priceSection.canceledPrice}
        </div>
      </div>

      <label>{t.priceSection.textAfterPrice}</label>
      <input type="text" {...register('text_after_price')} placeholder={t.priceSection.textAfterPrice} className={inputCss} disabled={!canEdit} />

      <label>{t.priceSection.textLineBelowPrice}</label>
      <input
        type="text"
        {...register('text_bellow_price')}
        placeholder={t.priceSection.textLineBelowPrice}
        className={inputCss}
        disabled={!canEdit}
      />
    </div>
  );
};

export default Price;
