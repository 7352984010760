import { RootState } from 'Config/store';

import { name, initialState } from './reducer';

export const slice = (state: RootState) => state[name] || initialState;

export const faqs = (state: RootState) => {
  return slice(state).faqs;
};

export const byCategory = (state: RootState) => {
  return slice(state).byCategory;
};
