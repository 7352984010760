import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export default function useTrackPage() {
  const location = useLocation();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    // lag between a route changing and the title property updating
    // setTimeout(() => {
    trackPageView({});
    // }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, location.hash]);

  return null;
}
