const eyeCatcher = {
  breadcrumbs: {
    linkGenerator: 'Störer Generator',
    linkGenerate: 'Störer erzeugen',
    linkEditGenerator: 'Störer bearbeiten',
    linkViewGenerator: 'Störer anzeigen',
  },
  title_generate: 'Störer generieren',
  title_edit: 'Störer bearbeiten',
  title_display: 'Störer anzeigen',
  subtitle: 'Bitte füllen Sie die geforderten Felder aus. Die mit einem Sternchen gekennzeichneten Felder sind Pflichtfelder.',
  generalInfoSection: {
    title: 'Allgemeine Informationen',
    eyeCatcherNumber: 'Eye-Catcher-Nummer (automatisch generiert):',
    assetNumber: 'Asset-Nummer',
    eyeCatcherName: 'Name des Störers',
    eyeCatcherNamePlaceholder: 'Geben Sie einen Dateinamen ein',
    copyTemplate: 'Eine Kopie als Vorlage speichern',
    newVersionText: 'Wenn Sie den Störer anpassen möchten, muss eine neue Version erstellt werden. Klicken Sie dazu folgende Schaltfläche:',
  },
  headSection: {
    title: 'Überschrift',
    preHeadline: 'Dachzeile',
    headline: 'Überschrift',
    subline: 'Unterüberschrift',
  },
  bulletPointsSection: {
    title: 'Bullet Points',
    note: 'Hinweis: Um ein einheitliches Design zu gewährleisten, werden die hochgeladenen Icons automatisch weiß gefärbt, wenn ein magentafarbener Hintergrund ausgewählt wird. Aus diesem Grund sollte das hochgeladene Symbol einfarbig sein und auf einem transparenten Hintergrund stehen. Ist kein solches Icon vorhanden, kann ein Icon aus der Icon-Bibliothek ausgewählt werden.',
    bgColor: {
      name: 'Hintergrundfarbe',
      magenta: 'magenta',
      white: 'weiß',
    },
    addButton: 'Aufzählungspunkt hinzufügen',
  },
  legalTextSection: {
    title: 'Rechtstext',
  },
  priceSection: {
    title: 'Preis',
    bgColor: {
      name: 'Hintergrundfarbe',
      magenta: 'magenta',
      white: 'weiß',
    },
    textLineAbovePrice: 'Textzeile über dem Preis',
    textBeforePrice: 'Text vor dem Preis',
    price: 'Preis',
    canceledPrice: 'Stornierter Preis',
    textAfterPrice: 'Text nach Preis',
    textLineBelowPrice: 'Textzeile unter Preis',
  },
  assetInfoSection: {
    title: 'Asset Informationen',
    category: 'Kategorie*',
  },
  logoSection: {
    title: 'Logo',
    claimLang: 'Claim-Language*',
    englishValue: 'Englisch',
    germanValue: 'Deutsch',
  },
  optionalInfoSection: {
    title: 'Optionale Informationen',
    note: 'Hinweis',
    keywords: 'Keywords',
    startPeriod: 'Beginn des Nutzungszeitraums',
    endPeriod: 'Ende des Nutzungszeitraums',
    publicationToPeriod: 'Sichtbar im Werbenet bis',
    publicationFromPeriod: 'Sichtbar im Werbenet von',
  },
  buttons: {
    cancel: 'Abbrechen',
    save: 'Speichern',
    saveAsDraft: 'Als Entwurf speichern',
    publish: 'Freigabe anfragen',
    update: 'Aktualisierung',
    newVersion: 'Neue Version erstellen',
    edit: 'Bearbeiten',
  },
  assetStatus: {
    published: 'Veröffentlicht',
    not_published: 'Nicht veröffentlicht',
    publication_expired: 'Veröffentlichung abgelaufen',
    publication_stopped: 'Veröffentlichung gestoppt',
    publication_planned: 'Veröffentlichung geplant',
    archived: 'Archiviert',
    require_approval: 'Freigabe angefragt',
    draft: 'Entwurf',
  },
  succesMessageCreation: 'Störer wurde erfolgreich erstellt!',
  succesMessageOnEdit: 'Störer wurde erfolgreich aktualisiert!',
  successMessageGenerateVersion: 'Eine neue Version wurde erfolgreich erstellt',
  field_required: 'Dieses Feld ist erforderlich!',
  field_required_asset: 'ist ein Pflichtfeld!',
  field_bullets: {
    generalMessage: 'Mindestens ein Aufzählungspunkt ist erforderlich!',
    text: 'Text und Symbol sind erforderlich!',
  },
  approve_modal: {
    approve: 'Asset freigeben',
    reject: 'Asset ablehnen',
    confirm: 'Bestätigen Sie',
    cancel: 'Abbrechen',
    confirm_approval: 'Bestätigung der Genehmigung',
    reject_approval: 'Genehmigung verweigern',
    message: 'Sind Sie sicher, dass Sie mit dieser Aktion fortfahren wollen?',
    reason: 'Bitte geben Sie Ihren Grund an!',
    reason_placeholder: 'Grund für die Ablehnung',
  },
} as const;

export default eyeCatcher;
