import { BreadCrumb, Page } from 'Components';
import { BsPlusLg } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MyEyeCatcher from './Components/MyEyeCathers';
import { useT } from './translations';

export default function EyeCatcherOverView() {
  const t = useT();
  return (
    <>
      <Page key={'page-eye'}>
        <Helmet defer={false}>
          <title>{t.helmetTitle}</title>
        </Helmet>
        <BreadCrumb
          style={{ margin: '1rem auto 1rem auto' }}
          links={
            [
              {
                title: t.breadcrumbTitle,
                link: '/eyecatcheroverview',
              },
            ].filter(Boolean) as { title: string; link: string }[]
          }
        />
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <h1 className="font-extrabold text-4xl">{t.breadcrumbTitle}</h1>
            <Link to="/eye_catcher" className="inline-flex  w-fit items-center text-white bg-primary-500 rounded text-sm p-2 gap-1">
              <BsPlusLg size={14} /> {t.generateEyeCatcher}
            </Link>
          </div>
          <MyEyeCatcher />
        </div>
      </Page>
    </>
  );
}
