const email = {
  page_title: 'Send file by email - WerbeNet',
  assetMsg: {
    title: 'Send file by email',
    body: 'Here you can send the selected media to any email recipient. If you use this function, the recipient can use the download link for a maximum of 7 days.',
    noAssetSelected: 'No asset selected.',
    submit: 'Send E-Mail',
  },
  email: {
    to: 'to',
    address: 'Multiple email addresses can be added separated by a comma ",".',
    salutation: 'Salutation',
    name: 'Name',
    description: 'Description',
    description_label: 'Enter an explanation for the recipient here (optional)',
    success: 'The email was successfully sent to %{emailAddress}',
    error: 'Email could not be sent to  %{emailAddress}',
    invalidEmail: '👎 The following email(s) or separator(s) are invalid:',
  },
} as const;

export default email;
