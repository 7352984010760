import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { actions } from './reducer';

export const fetch =
  (): AppAsyncThunk =>
  (dispatch, _getState, { api }) =>
    client.GETcart().then(({ data }) => {
      dispatch(actions.set(data));
    });

export const addItems =
  (assets: Asset[]): AppAsyncThunk<any> =>
  (dispatch, _getState) =>
    client.PATCHcart(assets.map((asset) => asset.id)).then(() => {
      dispatch(actions.addAssets(assets));
    });

export const removeItems =
  (assets_ids: string[]): AppAsyncThunk<any> =>
  (dispatch, _getState, { api }) =>
    client.DELETEcart(assets_ids).then(() => {
      dispatch(actions.removeAssets(assets_ids));
    });

export const downloadItems =
  (assets_ids: string[], lowResolution: boolean = false, onDownloadProgress: (progressEvent: any) => void) =>
  (dispatch, _getState, { api }) =>
    client.GETdownload(assets_ids, lowResolution, onDownloadProgress);
