import { useAppDispatch, useAppSelector } from 'Config';
import { ssoApi, api } from 'Config/api';
import { APP_UID, BACKEND_BASE_URL } from 'Env';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { SplashScreen } from 'Components/SplashScreen';
import MatomoGateway from 'Components/MatomoGateway';
import LoginPage from './views/loginPage';
import DownloadPage from '../DownloadPage';
import ApproveUserPage from '../ApproveUserPage';
import { authStore } from './lib';
import { ToastProvider, ToastProps } from 'react-toast-notifications';
import { CustomToast } from 'Components/CustomToast';

export const AuthProvider: React.FC<{ App: React.ComponentType<any> }> = ({ App }) => {
  const isLoggedIn = useAppSelector(authStore.selectUser);
  const rehydrated = useAppSelector<any>((s) => s?._persist?.rehydrated);

  if (!rehydrated) return <SplashScreen />;
  api.defaults.headers.common.Authorization = isLoggedIn?.access_token ? `Bearer ${isLoggedIn?.access_token}` : undefined;
  ssoApi.defaults.headers.common.Authorization = isLoggedIn?.access_token ? `Bearer ${isLoggedIn?.access_token}` : undefined;

  const path = window.location.pathname;
  const regex = /(^\/download\/|^\/approve)/i;

  const cookieBannerSettings = true;
  const ToastCustom = ({ children, ...props }: ToastProps) => <CustomToast {...props}>{children}</CustomToast>;
  const appRoutes = (
    <ToastProvider components={{ Toast: ToastCustom }} autoDismiss autoDismissTimeout={15000}>
      <Routes>
        <Route path="/oauth/callback" element={<OnSuccess />} />
        {regex.test(path) && !isLoggedIn && (
          <>
            <Route path="/approve" element={<ApproveUserPage isLoggedIn={false} />} />
            <Route path="/download/:uuid/:contact_uuid" element={<DownloadPage isLoggedIn={false} />} />
            <Route path="/" element={<LoginPage />} />
          </>
        )}
        <Route path="*" element={isLoggedIn ? <App /> : <LoginPage />} />
      </Routes>
    </ToastProvider>
  );

  return <>{cookieBannerSettings ? <MatomoGateway>{appRoutes}</MatomoGateway> : <>{appRoutes}</>}</>;
};

function OnSuccess() {
  const code = window.location.search.slice(6);
  const [ready, toggle] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (code) {
      api
        .post<User | { error?: string; error_description?: string }>(
          'smart_sso_layer/api/v1/resources/token',
          {
            client_id: APP_UID,
            code,
            redirect_uri: `${window.location.protocol}//${window.location.host}/oauth/callback`,
            grant_type: 'authorization_code',
          },
          { baseURL: BACKEND_BASE_URL }
        )
        .then(({ data }) => {
          if (!('access_token' in data)) {
            localStorage.clear();
            sessionStorage.clear();
          } else if (data && data?.access_token) {
            api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            const membership_id = jwtDecode<{ user: User }>(data.access_token)?.user?.membership_id;
            dispatch(
              authStore.actions.set({
                ...data,
                membership_id,
              })
            );
          }
        })
        .catch(console.error)
        .then(() => toggle(true));
    } else {
      toggle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) {
    return <SplashScreen />;
  }

  return <Navigate to={sessionStorage.getItem('originalpath') || '/'} />;
}
