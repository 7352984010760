/* eslint-disable react-hooks/exhaustive-deps */
import React, { KeyboardEventHandler, useCallback, useRef } from 'react';
import { GETassets, GETbyID } from 'Config/client';
import { BsSearch } from 'react-icons/bs';
import { useT } from '../translations';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { categoryStore } from 'Features/Categories';
import { useAppSelector } from 'Config';
import { formattCategoryNameRoute } from 'Helpers';
import ActionBackSpace from 'Components/Icons/ActionBackSpace';
import styled from 'styled-components';

export interface InlineSearchProps {
  onClose?: () => void;
  refInput?: React.RefObject<HTMLInputElement>;
}

export function InlineSearch({ onClose, refInput }: InlineSearchProps) {
  const t = useT();
  let navigate = useNavigate();
  const formRef = useRef(null);
  const categories = useAppSelector(categoryStore.selectCategories);
  const [keyword, setKeyword] = React.useState('');

  const go = () => {
    if (keyword) {
      GETassets({ as_number: keyword.replace(/^0+/, '').toUpperCase() })
        .then((data) => {
          if (data.data.assets.length > 0) {
            navigate(`/search?as_number=${keyword.replace(/^0+/, '')}`);
          } else {
            GETbyID(keyword)
              .then(({ data }) => {
                const assetCategory = categories.find((category) => category.id === data.category_id);
                if (assetCategory) navigate(`/shop/${formattCategoryNameRoute(assetCategory.title)}?id=${data.id}`);
              })
              .catch((e) => {
                if (e?.response?.status === 404) {
                  const category = categories.find((category) => category.title.toLocaleLowerCase() === keyword.toLocaleLowerCase());
                  if (category) {
                    navigate(`/shop/${formattCategoryNameRoute(category.title)}`);
                  } else {
                    const path = {
                      pathname: '/search',
                      search: createSearchParams(`query=${keyword}`).toString(),
                    };

                    navigate(path);
                  }
                }
              });
          }
        })
        .catch(() => {
          const path = {
            pathname: '/search',
            search: createSearchParams(`query=${keyword}`).toString(),
          };

          navigate(path);
        });
    }
    onClose && onClose();
  };

  const keyboardHandler: KeyboardEventHandler<HTMLElement> = useCallback(
    (e) => {
      if (!formRef.current) return;
      switch (e.key) {
        case 'Escape':
          onClose && onClose();
          break;
        case 'Enter':
          go();
          break;
        default:
          return;
      }
      e.preventDefault();
    },
    [keyword]
  );
  return (
    <form
      ref={formRef}
      className="flex justify-center flex-nowrap w-full gap-1 border-2 rounded border-primary-500 "
      onKeyDown={keyboardHandler}
      onSubmit={go}
    >
      <Input
        onChange={(e) => {
          setKeyword(`${e.target.value}`.toLowerCase());
        }}
        ref={refInput || null}
        type="search"
        className="relative flex-auto block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white 
          bg-clip-padding transition ease-in-out m-0 
          focus:text-gray-700 focus:bg-white focus:border-none focus:outline-none"
        placeholder={t.inlineSearch.looking_for}
        aria-label={t.inlineSearch.looking_for}
      />
      <button
        title={t.abort}
        className="!px-4 !py-2"
        aria-label={t.abort}
        onClick={(e) => {
          if (keyword.length > 0 && refInput && refInput.current) {
            setKeyword('');
            refInput.current.value = '';
          } else {
            onClose && onClose();
          }

          e.preventDefault();
        }}
      >
        <ActionBackSpace fill={'var(--grey-50)'} />
      </button>
      <button
        type="submit"
        className="btn px-4 py-2 text-primary-600 font-medium  rounded 
          transition duration-150 ease-in-out"
      >
        <BsSearch size={25} />
      </button>
    </form>
  );
}

const Input = styled.input`
  &::-webkit-search-cancel-button{
    display: none
`;
