const CustomDimensions = {
  FormatDownload: { customDimensionId: 7, fieldKey: 'DT_Format' },
  WerbemittelDownload: { customDimensionId: 8, fieldKey: 'DT_MarketingMat' },
  TarifeFestnetzDownload: { customDimensionId: 9, fieldKey: 'DT_Asset_Meta_TariffLand' },
  TarifeMobilfunkDownload: { customDimensionId: 10, fieldKey: 'AS_Asset_Meta_TariffMobil' },
  HerstellerDownload: { customDimensionId: 11, fieldKey: 'DT_Manufacturer' },
  PrintOnlineDownload: { customDimensionId: 12, fieldKey: 'PrintOrOnline' },
  AllFilters: { customDimensionId: 14, fieldKey: 'AllFilters' },
} as const;

function combineDimensions(asNumber: string, assetName: string, assetCategoryName: string, fieldsValues: { [k: string]: string }): string {
  // 1, 7, 11, 6, 12, 9, 10, 8, 2
  const dimensions = [
    asNumber,
    fieldsValues[CustomDimensions.FormatDownload.fieldKey] ?? '',
    fieldsValues[CustomDimensions.HerstellerDownload.fieldKey] ?? '',
    assetCategoryName,
    fieldsValues[CustomDimensions.PrintOnlineDownload.fieldKey] ?? '',
    fieldsValues[CustomDimensions.TarifeFestnetzDownload.fieldKey] ?? '',
    fieldsValues[CustomDimensions.TarifeMobilfunkDownload.fieldKey] ?? '',
    fieldsValues[CustomDimensions.WerbemittelDownload.fieldKey] ?? '',
    assetName,
  ];
  return dimensions.join(';');
}

export const getAssetCustomDimensionsDownload = (asNumber: string, assetName: string, assetCategoryName: string, fields?: Field[]) => {
  const fieldsValues: { [k: string]: string } = {};
  (fields ?? []).forEach((r) => {
    fieldsValues[r.key] = r.value;
  });
  return [
    {
      id: 1,
      value: asNumber,
    },
    {
      id: 2,
      value: assetName,
    },
    {
      id: 6,
      value: assetCategoryName,
    },
    {
      id: CustomDimensions.FormatDownload.customDimensionId,
      value: fieldsValues[CustomDimensions.FormatDownload.fieldKey] ?? undefined,
    },
    {
      id: CustomDimensions.WerbemittelDownload.customDimensionId,
      value: fieldsValues[CustomDimensions.WerbemittelDownload.fieldKey] ?? undefined,
    },
    {
      id: CustomDimensions.TarifeFestnetzDownload.customDimensionId,
      value: fieldsValues[CustomDimensions.TarifeFestnetzDownload.fieldKey] ?? undefined,
    },
    {
      id: CustomDimensions.TarifeMobilfunkDownload.customDimensionId,
      value: fieldsValues[CustomDimensions.TarifeMobilfunkDownload.fieldKey] ?? undefined,
    },
    {
      id: CustomDimensions.HerstellerDownload.customDimensionId,
      value: fieldsValues[CustomDimensions.HerstellerDownload.fieldKey] ?? undefined,
    },
    {
      id: CustomDimensions.PrintOnlineDownload.customDimensionId,
      value: fieldsValues[CustomDimensions.PrintOnlineDownload.fieldKey] ?? undefined,
    },
    {
      id: CustomDimensions.AllFilters.customDimensionId,
      value: combineDimensions(asNumber, assetName, assetCategoryName, fieldsValues),
    },
  ];
};
