/* eslint-disable react-hooks/exhaustive-deps */
import { Disclosure, DisclosureButton, DisclosurePanel } from '@reach/disclosure';
import { VisuallyHidden } from '@reach/visually-hidden';
import { InputsSkeleton, DateTimePicker, Spinner } from 'Components';
import { formatISO } from 'date-fns';
import { Button, OutlineButton } from 'Components/Button';
import { Dispatch, Fragment, useCallback, useEffect, useReducer, useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { BsChevronDown, BsChevronRight, BsXLg } from 'react-icons/bs';
import { useT } from '../translations';
import { CategoryField, FilterParams } from '../index';

interface Props {
  isDisabled: boolean;
  filterParams: FilterParams;
  setFilterParams: Dispatch<FilterParams>;
  categoryFields: CategoryField[];
  loadCount: Record<string, boolean>;
  count: Record<string, Record<string, number>>;
}

export default function Filters({ isDisabled, filterParams, setFilterParams, loadCount, count, categoryFields }: Props) {
  const t = useT();

  const [isOpen, setOpen] = useReducer((a, c) => ({ ...a, ...c }), {});
  const [hasFilterApplied, setHasFilter] = useState(false);
  const [changed, setChanged] = useState(false);

  const [asNummerSearch, setAsNummerSearch] = useState('');
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filterFields, setFilterFields] = useState<Record<string, Record<number, boolean>>>({});

  useEffect(() => {
    setStartDate(filterParams.usable_from ? new Date(filterParams.usable_from) : undefined);
    setEndDate(filterParams.usable_to ? new Date(filterParams.usable_to) : undefined);
    setQuery(filterParams.query || '');
    setAsNummerSearch(filterParams.as_number || '');

    if (filterParams.fields && filterParams.fields.length) {
      const checkedFields = categoryFields.reduce((a, categoryField) => {
        const options = categoryField.options?.reduce((oa, option) => {
          if (filterParams.fields?.includes(option.id)) {
            return { ...oa, ...{ [option.id]: true } };
          }
          return { ...oa };
        }, {});
        if (Object.keys(options).length > 0) {
          return { ...a, [categoryField.key]: options };
        }
        return { ...a };
      }, {});
      setFilterFields(checkedFields);
    } else {
      setFilterFields({});
    }

    setHasFilter(
      filterParams &&
        !!(filterParams.fields?.length || filterParams.query || filterParams.as_number || filterParams.usable_from || filterParams.usable_to)
    );
  }, [filterParams]);

  const fetchAssets = () => {
    filterParams['usable_from'] = startDate ? formatISO(startDate, { representation: 'date' }) : undefined;
    filterParams['usable_to'] = endDate ? formatISO(endDate, { representation: 'date' }) : undefined;
    filterParams['query'] = query;
    filterParams['as_number'] = asNummerSearch;
    filterParams['page'] = 1;
    filterParams['fields'] = [];
    if (Object.keys(filterFields).length > 0) {
      Object.entries(filterFields).forEach(([k, value]) => {
        Object.entries(value).forEach(([rk, rv]) => {
          if (rv) filterParams['fields']?.push(Number(rk));
        });
      });
    }
    setHasFilter(false);
    setChanged(false);
    setFilterParams(filterParams);
  };

  const handleResetFilters = () => {
    filterParams['usable_from'] = undefined;
    filterParams['usable_to'] = undefined;
    filterParams['query'] = undefined;
    filterParams['as_number'] = undefined;
    filterParams['page'] = 1;
    filterParams['fields'] = [];
    setHasFilter(false);
    setChanged(false);
    setFilterParams(filterParams);
  };

  const toggle = useCallback(
    (key) => () => {
      setOpen({ [key]: isOpen[key] ? false : true });
    },
    [isOpen]
  );

  const categoryFieldOptions = (categoryField: CategoryField) => {
    if (!filterFields[categoryField.key]) {
      filterFields[categoryField.key] = {};
    }
    const fieldOptions = Object.values(categoryField.options || {}).filter((fieldOption: FieldOption) => {
      return (
        Boolean(count[categoryField.key]) &&
        (count[categoryField.key][fieldOption.id] > 0 || typeof filterFields[categoryField.key][fieldOption.id] === 'boolean')
      );
    });

    if (Boolean(loadCount[categoryField.key])) {
      return <Spinner className="text-primary-500" size={24}></Spinner>;
    }

    if (fieldOptions.length === 0) {
      return <label className="px-4">{t.no_options_available}</label>;
    } else {
      return (
        <>
          {fieldOptions.map((fieldOption: FieldOption) => {
            return (
              <label key={`${categoryField.key}-${fieldOption.id}`} className="flex px-4">
                <input
                  type={'checkbox'}
                  className="mr-4 mt-1"
                  name={categoryField.display_name}
                  checked={!!filterFields[categoryField.key][fieldOption.id]}
                  onChange={(e) => {
                    if (!changed) setChanged(true);
                    setFilterFields({
                      ...filterFields,
                      [categoryField.key]: { ...filterFields[categoryField.key], [fieldOption.id]: e.target.checked },
                    });
                  }}
                  onKeyDown={(e) => {
                    // on Enter key
                    if (e.keyCode === 13 || e.key === 'Enter') {
                      fetchAssets();
                    }
                  }}
                  tabIndex={isDisabled ? -1 : 0}
                />
                <span className="flex-1">{fieldOption.title}</span>
                {count[categoryField.key] && <span className="font-semibold text-xs">{count[categoryField.key][fieldOption.id] || '-'}</span>}
              </label>
            );
          })}
        </>
      );
    }
  };

  return (
    <aside
      className={`w-1/3 px-4 lg:w-[240px] xl:w-[280px] ${isDisabled && 'pointer-events-none'} relative `}
      style={{ opacity: isDisabled ? '0.55' : '1' }}
    >
      <h2 className="flex text-lg font-bold h-12">
        <BiFilterAlt className="mr-4 mt-1" /> {t.filter_noun}
      </h2>
      <div className="flex flex-row sticky w-full top-14 bg-white z-20">
        {!hasFilterApplied || changed ? (
          <>
            <Button
              className="w-full disabled:opacity-40 disabled:cursor-not-allowed !mb-4"
              onClick={() => {
                fetchAssets();
              }}
              disabled={!changed}
            >
              {t.filter}
            </Button>
            <Button
              title={t.reset_filter}
              aria-label={t.reset_filter}
              className="!mr-0 !mb-4 disabled:opacity-40 disabled:cursor-not-allowed"
              onClick={handleResetFilters}
              disabled={!changed}
            >
              <BsXLg size={13.2} />
            </Button>
          </>
        ) : (
          <OutlineButton
            title={t.reset_filter}
            aria-label={t.reset_filter}
            className="w-full outline outline-1 !mb-4"
            onClick={handleResetFilters}
            disable={!!isDisabled}
          >
            {t.reset_filter}
          </OutlineButton>
        )}
      </div>
      <ul className="z-10 relative">
        <li>
          <label className="px-2 py-4 font-bold" htmlFor="keyword_search">
            {t.keyword_search}
          </label>
          <div className="relative focus-within:text-primary-500 focus:text-primary-500 text-gray-600">
            <VisuallyHidden>{t.keyword_search}</VisuallyHidden>
            <input
              id="keyword_search"
              type="search"
              name="query"
              placeholder={t.keyword_search}
              disabled={!!isDisabled}
              className="bg-white border w-full mb-6 mt-2 border-gray-300 border-solid h-10 px-5 pl-10 rounded-full text-sm hover:ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary focus:border-0  disabled:placeholder:text-gray-80"
              value={query}
              onKeyDown={(e) => {
                // on Enter key
                if (e.keyCode === 13 || e.key === 'Enter') {
                  fetchAssets();
                }
              }}
              onChange={(e) => {
                const query = e.target.value;
                setChanged(true);
                setQuery(query);
              }}
            />
            <button
              title={t.search}
              aria-label={t.search}
              onClick={() => fetchAssets()}
              tabIndex={-1}
              type="button"
              className="absolute left-4 top-0 mt-5 mr-4 "
            >
              <svg className="h-4 w-4 fill-current" x="0px" y="0px" viewBox="0 0 56.966 56.966" width="512px" height="512px">
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </div>
        </li>

        <li>
          <label className="px-2 py-4 font-bold" htmlFor={t.as_nummer}>
            {t.as_nummer}
          </label>
          <div className="relative focus-within:text-primary-500 focus:text-primary-500 text-gray-600">
            <VisuallyHidden>{t.as_nummer}</VisuallyHidden>
            <input
              id={t.as_nummer}
              type="search"
              name="as_number"
              autoComplete="off"
              placeholder={t.as_nummer}
              disabled={!!isDisabled}
              className="bg-white border w-full mb-6 mt-2 border-gray-300 border-solid h-10 px-5 pl-10 rounded-full text-sm hover:ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary focus:border-0  disabled:placeholder:text-gray-80"
              value={asNummerSearch}
              onKeyDown={(e) => {
                // on Enter key
                if (e.keyCode === 13 || e.key === 'Enter') {
                  fetchAssets();
                }
              }}
              onChange={(e) => {
                setChanged(true);
                setAsNummerSearch(e.target.value);
              }}
            />
            <button
              title={t.search}
              aria-label={t.search}
              onClick={() => fetchAssets()}
              tabIndex={-1}
              type="button"
              className="absolute left-4 top-0 mt-5 mr-4 "
            >
              <svg className="h-4 w-4 fill-current" x="0px" y="0px" viewBox="0 0 56.966 56.966" width="512px" height="512px">
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </div>
        </li>
        {categoryFields.length === 0 ? (
          <InputsSkeleton />
        ) : (
          categoryFields
            .filter((categoryField) => {
              const hasCount =
                count[categoryField.key] &&
                Object.values(count[categoryField.key]).reduce((value, sum) => {
                  return sum + value;
                }, 0) > 0;

              const hasSelections = Object.values(categoryField.options || {}).some(
                (fieldOption: FieldOption) => filterFields && typeof filterFields[categoryField.key]?.[fieldOption.id] === 'boolean'
              );
              return hasCount || hasSelections;
            })
            .map((r) => (
              <li key={r.key}>
                <Disclosure open={!!isOpen[r.key]} data-id={r.key} onChange={toggle(r.key)}>
                  <DisclosureButton
                    className="font-bold text-left w-full px-2 py-4 border-b border-gray-200 flex items-center"
                    tabIndex={isDisabled ? -1 : 0}
                  >
                    <span className="flex-1">{r.display_name}</span>
                    <span className="text-xs mr-2 " style={{ color: 'var(--primary)' }}>
                      {filterFields[r.key] ? Object.values(filterFields[r.key]).filter(Boolean).length || '' : null}
                    </span>
                    {!!isOpen[r.key] ? <BsChevronRight /> : <BsChevronDown />}
                  </DisclosureButton>
                  <DisclosurePanel className="py-2">{categoryFieldOptions(r)}</DisclosurePanel>
                </Disclosure>
              </li>
            ))
        )}

        <li>
          <div className="font-bold text-left w-full px-2 p2-4 border-b border-gray-200 flex flex-col">
            <span className="flex-1">{t.period_from}</span>
            <label className="font-light" htmlFor={t.period_from}>
              {t.date_format}
            </label>
          </div>
          <DateTimePicker
            id={t.period_from}
            key={'usable_from'}
            type={'date'}
            name={'usable_from'}
            value={startDate}
            placeholder={t.period_from}
            onChangeTime={(date) => {
              setStartDate(date);
              if (!changed) setChanged(true);
            }}
            disabled={!!isDisabled}
          ></DateTimePicker>
        </li>
        <li>
          <div className="font-bold text-left w-full px-2 py-2 border-b border-gray-200 flex flex-col">
            <span className="flex-1">{t.period_to}</span>
            <label className="font-light" htmlFor={t.period_to}>
              {t.date_format}
            </label>
          </div>
          <DateTimePicker
            id={t.period_to}
            key={'usable_to'}
            type={'date'}
            name={'usable_to'}
            value={endDate}
            placeholder={t.period_to}
            minDate={startDate}
            onChangeTime={(date) => {
              setEndDate(date);
              if (!changed) setChanged(true);
            }}
            disabled={!!isDisabled}
          ></DateTimePicker>
        </li>
      </ul>
    </aside>
  );
}
