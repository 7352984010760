/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import Slider from 'react-slick';
import Img from '../werbenet_slide_default.png';
import { API_URL } from 'Env';
import { useT } from '../translations';

export const Carousel: React.FC<{ slides: Slide[] }> = ({ slides }) => {
  const t = useT();
  const [autoplaySpeed, setAutoplaySpeed] = useState(5000);
  const [pauseAutoplay, setPauseAutoplay] = useState(false);
  const [mouseMoved, setMouseMoved] = useState(false);
  const [percent, setPercent] = useState(0);
  const [currentSlider, setCurrentSlider] = useState<Slide | undefined>();
  const refSlider = useRef<Slider>(null);
  useEffect(() => {
    setAutoplaySpeed(slides[0] ? (slides[0].timer || 5) * 1000 : 5000);
    setCurrentSlider(slides[0]);
  }, [slides]);

  useEffect(() => {
    pauseAutoplay ? refSlider.current?.slickPause() : refSlider.current?.slickPlay();
  }, [pauseAutoplay]);

  useEffect(() => {
    if (currentSlider) {
      const timeIntervalOnePercent = ((currentSlider.timer || 5) * 1000) / 100;
      if (!pauseAutoplay) {
        const interval = setInterval(() => {
          setPercent((percent) => percent + 1);
        }, timeIntervalOnePercent);
        return () => clearInterval(interval);
      } else {
        setAutoplaySpeed(autoplaySpeed - (autoplaySpeed / 100) * percent);
      }
    }
  }, [currentSlider, pauseAutoplay]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    pauseOnFocus: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    adaptiveHeight: false,
    appendDots: (dots: Array<React.ReactElement>) => {
      return (
        <ul className="slick-dots">
          {dots.map((item, i) => {
            const { className } = item.props;
            const isActive: boolean = typeof className === 'string' && className.includes('slick-active');
            return (
              <li key={`slick-dot-${i}`} {...item.props} {...(isActive && { 'aria-current': 'step' })}>
                <button
                  className="relative !text-gray-50 hover:!text-primary-500"
                  aria-label={`${isActive ? t.active_page : t.page} ${i + 1} ${slides[i].headline ? `: ${slides[i].headline}` : ''}`}
                  title={`${isActive ? t.active_page : t.page} ${i + 1} ${slides[i].headline ? `: ${slides[i].headline}` : ''}`}
                  onClick={() => {
                    if (isActive) {
                      setPauseAutoplay(!pauseAutoplay);
                    } else {
                      refSlider.current?.slickGoTo(i);
                    }
                  }}
                >
                  {isActive ? (
                    <>
                      <svg className="absolute top-0 left-0 tops-1/2 lefts-1/2 opacity-95 h-full w-full" viewBox="0 0 120 120">
                        <circle className="progress-ring stroke-gray-10" strokeWidth="9" fill="none" r="52" cx="60" cy="60"></circle>
                        <circle
                          className="progress-ring__circle stroke-primary-500"
                          strokeWidth="7"
                          fill="none"
                          r="52"
                          cx="60"
                          cy="60"
                          strokeDasharray={(52 * 2 * Math.PI).toString()}
                          strokeDashoffset={(52 * 2 * Math.PI - (percent / 100) * 52 * 2 * Math.PI).toString()}
                          strokeLinecap="round"
                          transform="rotate(-90 60 60)"
                        ></circle>
                      </svg>
                      <div className="absolute flex justify-center items-center top-0 left-0 h-full w-full">
                        {!pauseAutoplay ? (
                          <FaPause className="pause_icon opacity-95 h-auto w-[10px]" />
                        ) : (
                          <FaPlay className="play_icon opacity-95 h-auto w-[10px] ml-[2px]" />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="absolute flex justify-center items-center top-0 left-0 w-full h-full bg-gray-20 rounded-full"></div>
                  )}
                  <span className="opacity-0">{i}</span>
                </button>
              </li>
            );
          })}
        </ul>
      );
    },
  };

  return (
    <Container>
      <Slider
        {...settings}
        ref={refSlider}
        beforeChange={(currentSlide: number, nextSlide: number) => {
          const nextSlideTimer = (slides[nextSlide]?.timer || 5) * 1000;
          setAutoplaySpeed(autoplaySpeed === nextSlideTimer ? autoplaySpeed + 1 : nextSlideTimer);
          setPercent(0);
          setCurrentSlider(slides[nextSlide]);
        }}
        autoplaySpeed={autoplaySpeed}
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="h-[25vw] min-h-[300px]"
            onMouseDown={() => {
              setMouseMoved(false);
            }}
            onMouseMove={() => {
              if (!mouseMoved) {
                setMouseMoved(true);
              }
            }}
            onMouseUp={() => {
              if (!mouseMoved) {
                setPauseAutoplay(!pauseAutoplay);
              }
            }}
          >
            <div className="flex flex-row h-[25vw] min-h-[300px] relative">
              <div className="flex-1 h-[25vw] min-h-[300px]">
                {slide.image ? (
                  <img
                    className="m-auto h-full w-full object-cover"
                    src={`${API_URL.slice(0, -8)}${slide.image}`}
                    onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                      if (e.target.src !== Img) {
                        e.target.onerror = null;
                        e.target.src = Img;
                        e.target.style.objectFit = 'cover';
                      }
                    }}
                    alt={`${slide.headline || `${t.slider} ${slide.order || slide.id}`}`}
                  />
                ) : (
                  <img
                    className="m-auto h-full w-full object-cover"
                    src={Img}
                    alt={`${slide.headline || `${t.slider} ${slide.order || slide.id}`}`}
                  />
                )}
              </div>

              {(slide.headline || slide.body || slide.redirect_url) && (
                <div className="w-[40%] min-w-[350px] bg-green-50 rounded-l-2xl relative">
                  <div className="absolute h-full -left-6 right-0 top-0  bg-primary-500 rounded-l-2xl flex flex-col pl-12 pr-4 py-4 justify-center text-white gap-y-6">
                    {!!slide.headline && <h3 className="text-3xl font-extrabold md:text-5xl">{slide.headline}</h3>}
                    {!!slide.body && (
                      <p className="text-base font-bold line-clamp-3 2xl:line-clamp-none xl:line-clamp-6 lg:line-clamp-5 md:line-clamp-4 md:text-xl">
                        {slide.body}
                      </p>
                    )}
                    {!!slide.redirect_url && (
                      <a
                        className="text-sm border-[1px] border-white rounded w-fit px-3 py-2.5 md:text-lg"
                        title={t.learn_more}
                        href={slide.redirect_url}
                        target="_blank"
                        rel="noreferrer"
                        tabIndex={-1}
                      >
                        {t.learn_more}
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

const Container = styled.div`
  .slick-initialized .slick-slide {
    margin-bottom: -7px;
  }
  .slick-dots {
    bottom: -25px;
    width: 200px;
    margin: 0px calc(50% - 100px) -6px;

    > li > button::before {
      content: unset;
    }
    > li {
      border: none;
      background-color: unset;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      margin: 0px;
      padding: 9px;

      > button {
        border-radius: 100%;
        padding: 2px;
        width: 100%;
        height: 100%;

        &:focus {
          box-shadow: 0 0 0 1px #fff, 0 0 0 1px #fff, 0 0 0 4px var(--magenta_light) !important;
        }
      }
    }
    > li.slick-active {
      background-color: unset;
      width: 30px;
      height: 30px;
      padding: 0px;
      > button {
        width: 30px;
        height: 30px;

        &:focus {
          box-shadow: 0 0 0 1px #fff0, 0 0 0 1px #fff0, 0 0 0 2px var(--magenta_light) !important;
        }
      }
    }
  }
`;
