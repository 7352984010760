import '@reach/dialog/styles.css';
import './index.css';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import styled from 'styled-components';
import { BsX } from 'react-icons/bs';
import { useT } from '../translations';

interface ModalProps {
  visible: boolean;
  title?: string;
  onRequestClose: (extrainfo: any) => void;
  children: any;
  headerClasses?: string;
  bodyClasses?: string;
  isPreviewImage?: boolean;
}

export function Modal({ visible, onRequestClose, children, title, headerClasses, bodyClasses, isPreviewImage = false }: ModalProps) {
  const t = useT();
  const close = () => onRequestClose(false);

  return (
    <DialogOverlayWrapper
      className="overflow-x-hidden overflow-y-auto fixed inset-0 z-101 outline-none focus:outline-none justify-center items-center"
      isOpen={visible}
      onDismiss={close}
      ispreviewimage={isPreviewImage.toString()}
    >
      <DialogContent
        className="
        border
        overflow-auto
        rounded-lg
        shadow-lg
        relative
        flex flex-col
        bg-white
        outline-none
        focus:outline-none
      "
        aria-label={title || 'Modal'}
      >
        <div
          className="modal-header
          flex
          gap-4
          items-start
          justify-between
          pb-5
          border-b border-solid border-gray-200
          rounded-t
        "
        >
          <h3 className={`text-3xl font-semibold break-all ${headerClasses}`}>{title}</h3>
          <button
            title={t.modal.close}
            aria-label={t.modal.close}
            className="
            p-1
            m-1
            bg-transparent
            border-0
            text-gray-50
            float-right
            text-3xl
            font-semibold
            outline-none
            focus:outline-none
          "
            onClick={close}
          >
            <span
              className="
              bg-transparent
              text-3xl
              block
              outline-none
              focus:outline-none
            "
            >
              <BsX />
            </span>
          </button>
        </div>

        <div className={`modal-body ${bodyClasses}`}>{children}</div>
      </DialogContent>
    </DialogOverlayWrapper>
  );
}

const DialogOverlayWrapper = styled(DialogOverlay)<{ ispreviewimage: string }>`
  > [data-reach-dialog-content] {
    ${({ ispreviewimage }) => ispreviewimage === 'true' && 'max-height: unset !important;'}
  }
`;
