import { AppAsyncThunk } from 'Config/store';
import { actions } from './model';
import { client } from 'Config';

export const fetch = (): AppAsyncThunk => (dispatch, _getState) =>
  client.GETstatic_pages().then(({ data }) => {
    dispatch(actions.reset(data));
  });

export const fetchPageDetails =
  (slug: string): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.GETPage(slug).then(({ data }) => {
      dispatch(actions.fetchDetails(data));
    });
