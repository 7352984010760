const eyeCatcher = {
  breadcrumbs: {
    linkGenerator: 'Eye-Catcher Generator',
    linkGenerate: 'Generate Eye-Catcher',
    linkEditGenerator: 'Edit Eye-Catcher',
    linkViewGenerator: 'View Eye-Catcher',
  },
  title_generate: 'Generate Eye-Catcher',
  title_edit: 'Edit Eye-Catcher',
  title_display: 'Show Eye-Catcher',
  subtitle: 'Please fill in the requiered fields. The fields marked with an asterisk are mandatory.',
  generalInfoSection: {
    title: 'General Information',
    eyeCatcherNumber: 'Eye-Catcher number (automatically generated):',
    eyeCatcherName: 'Eye-Catcher Name',
    assetNumber: 'Asset number',
    eyeCatcherNamePlaceholder: 'Enter a file name',
    copyTemplate: 'Save a copy as template',
    newVersionText: 'If you want to adjust the EyeCatcher, a new version must be created. To do this, click the following button:',
  },
  headSection: {
    title: 'Head',
    preHeadline: 'Pre Headline',
    headline: 'Headline',
    subline: 'Sublline',
  },
  bulletPointsSection: {
    title: 'Bullet points',
    note: 'Note: To ensure a consistent design, if a magenta background is selected, the uploaded icons will be automatically colored white. For this reason, the uploaded icon should be single-colored and placed on a transparent background. If no such icon is present, an icon can be selected from the icon library.',
    bgColor: {
      name: 'Background color',
      magenta: 'magenta',
      white: 'white',
    },
    addButton: 'Add bullet point',
  },
  legalTextSection: {
    title: 'Legal Text',
  },
  priceSection: {
    title: 'Price',
    bgColor: {
      name: 'Background color',
      magenta: 'magenta',
      white: 'white',
    },
    textLineAbovePrice: 'Text line above price',
    textBeforePrice: 'Text before price',
    price: 'Price',
    canceledPrice: 'Canceled price',
    textAfterPrice: 'Text after price',
    textLineBelowPrice: 'Text line below price',
  },
  assetInfoSection: {
    title: 'Asset information',
    category: 'Category*',
  },
  logoSection: {
    title: 'Logo',
    claimLang: 'Claim-Language*',
    englishValue: 'English',
    germanValue: 'German',
  },
  optionalInfoSection: {
    title: 'Optional information',
    note: 'Note',
    keywords: 'Keywords',
    startPeriod: 'Start of usage period',
    endPeriod: 'End of usage period',
    publicationToPeriod: 'Visible in Werbenet to',
    publicationFromPeriod: 'Visible in Werbenet from',
  },
  buttons: {
    cancel: 'Cancel',
    save: 'Save',
    saveAsDraft: 'Save as draft',
    publish: 'Require Appoval',
    update: 'Update',
    newVersion: 'Create new version',
    edit: 'Edit',
  },
  assetStatus: {
    published: 'Published',
    not_published: 'Not published',
    publication_expired: 'Publication expired',
    publication_stopped: 'Publication stopped',
    publication_planned: 'Publication planned',
    archived: 'Archived',
    require_approval: 'Require approval',
    draft: 'Draft',
  },
  succesMessageCreation: 'The eyecatcher was successfully created!',
  succesMessageOnEdit: 'The Eyecatcher has been successfully updated!',
  successMessageGenerateVersion: 'a new version was successfully created',
  field_required: 'This field is required!',
  field_required_asset: 'field is required!',
  field_bullets: {
    generalMessage: 'Mindestens ein Aufzählungspunkt ist erforderlich!',
    text: 'Text and icon are required!',
  },
  approve_modal: {
    approve: 'Approve',
    reject: 'Reject',
    confirm: 'Confirm',
    cancel: 'Cancel',
    confirm_approval: 'Confirmation of approval',
    reject_approval: 'Rejection of approval',
    message: 'Are you sure you want to continue with this action?',
    reason: 'Please state your reason!',
    reason_placeholder: 'Reason for rejection',
  },
} as const;

export default eyeCatcher;
