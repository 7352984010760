// import { useState } from 'react';
import { Modal, PrimaryButton } from 'Components';
import { useT } from '../translations';
import { API_URL } from 'Env';
import { FormatDate, zeroFill } from 'Helpers';
import defaultImgSrc from 'Assets/nopreview.png';
import styled from 'styled-components';
import { BsZoomIn } from 'react-icons/bs';
import { useState } from 'react';
import AssetPreview from './AssetPreview';

interface ModalProps {
  data?: Asset;
  visible: boolean;
  onRequestClose: () => void;
  onAddToCart: () => void;
  onRemoveFromCart: () => void;
  hasTermsOfUse: boolean;
  canBeAddedToCart: boolean;
  isInCart: boolean;
}

export function AssetInfoModal({
  data: showDialog,
  visible,
  onRequestClose,
  onAddToCart,
  onRemoveFromCart,
  hasTermsOfUse,
  canBeAddedToCart,
  isInCart,
}: ModalProps) {
  const t = useT();
  const [assetPreview, setAssetPreview] = useState<Asset>();

  return (
    <Modal
      headerClasses={'text-[20px] font-extrabold'}
      bodyClasses="pt-5 px-1 overflow-auto"
      title={showDialog?.name}
      visible={visible}
      onRequestClose={() => onRequestClose()}
    >
      {assetPreview && <AssetPreview assetPreview={assetPreview} onClose={() => setAssetPreview(undefined)}></AssetPreview>}
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="img-container relative grow-0 shrink-0 w-[150px] h-[150px] m-auto md:m-0">
          <ImgContainer>
            {showDialog?.alternative_image_url || showDialog?.file_preview_url ? (
              <img
                className="absolute max-h-full max-w-full inset-0 m-auto rounded shadow"
                src={`${API_URL.slice(0, -8)}${showDialog?.alternative_image_url ?? showDialog?.file_preview_url}`}
                onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                  if (e.target.src !== defaultImgSrc) {
                    e.target.onerror = null;
                    e.target.src = defaultImgSrc;
                  }
                }}
                alt={showDialog?.name}
              />
            ) : (
              <img className="absolute max-h-full max-w-full inset-0 m-auto rounded shadow" src={defaultImgSrc} alt={showDialog?.name} />
            )}

            {(!!showDialog?.alternative_image_url || !!showDialog?.file_preview_url) && (
              <button
                className="button-zoom z-20 absolute inset-1/4 m-auto text-primary-500 opacity-0 bg-white rounded-lg p-2"
                title={t.zoom_image}
                aria-label={t.zoom_image}
                onClick={() => setAssetPreview(showDialog)}
              >
                <BsZoomIn size={50} />
              </button>
            )}
          </ImgContainer>
        </div>

        <div className="overflow-auto font-thin flex-1 px-2 pb-2">
          <p className="font-extrabold pb-3 mb-3 border-b border-gray-100">
            {t.as_nummer}: {zeroFill(showDialog?.as_number, 6) || '----'}
          </p>
          <div className="flex flex-col">
            <span className="block my-2">
              {t.usage_period_start}: <time>{showDialog?.usable_from ? FormatDate(showDialog?.usable_from || '', true) : '--.--.----'}</time>
            </span>
            <span className="block my-2">
              {t.usage_period_end}: <time>{showDialog?.usable_to ? FormatDate(showDialog?.usable_to || '', true) : '--.--.----'}</time>
            </span>
          </div>
          {!hasTermsOfUse && canBeAddedToCart && (
            <div className="flex justify-end mt-12">
              {isInCart ? (
                <PrimaryButton
                  className="float-right"
                  title={t.remove_from_shopping_cart}
                  aria-label={t.remove_from_shopping_cart}
                  onClick={() => {
                    onRemoveFromCart();
                    onRequestClose();
                  }}
                >
                  {t.remove_from_shopping_cart}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  className="float-right"
                  title={t.add_to_shopping_cart}
                  aria-label={t.add_to_shopping_cart}
                  onClick={() => {
                    onAddToCart();
                    onRequestClose();
                  }}
                >
                  {t.add_to_shopping_cart}
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const ImgContainer = styled.div`
  .button-zoom {
    width: fit-content;
    height: max-content;
    padding: 10px;
    opacity: 0;

    &:focus,
    &:hover {
      opacity: 0.9 !important;
    }
  }

  &:hover {
    .button-zoom {
      cursor: zoom-in;
      opacity: 0.6;
    }
  }
`;
