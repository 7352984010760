/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { client } from 'Config';
import { breakpoint } from 'Helpers';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useT } from './translations';
import { BreadCrumb, IconButton, Page, ProgressBarProps, DownloadProgress, Spinner } from 'Components';
import Trans from 'Components/Trans';
import { Helmet } from 'react-helmet';
import { BsDownload } from 'react-icons/bs';
import { BACKEND_BASE_URL } from 'Env';
import { getAssetCustomDimensionsDownload } from 'Components/MatomoGateway/customDimensions';

export default function DownloadPage({ isLoggedIn = true }) {
  const params = useParams<{ uuid: string; contact_uuid: string }>();
  const { uuid, contact_uuid } = params;
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setDownloadLoading] = useState(false);
  const [loadedSize, setLoadedSize] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [progressBarProps, setProgressBarProps] = useState<ProgressBarProps>();
  const [responseData, setResponseData] = useState<{
    email: string;
    byte_size: number;
    assets: AssetDownloaded[];
    statusCode: number;
  }>({
    email: '',
    byte_size: 0,
    assets: [],
    statusCode: 200,
  });
  const t = useT();

  useEffect(() => {
    if (params.uuid && params.contact_uuid) {
      setLoading(true);
      client
        .GETshareByUUID(params.uuid, params.contact_uuid)
        .then((response) => {
          setResponseData({ ...response.data, statusCode: 200 });
        })
        .catch((error) => {
          const code = error.response?.status && error.response.status === 404 ? 404 : 500;
          setResponseData({ ...responseData, statusCode: code });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params]);

  const onDownloadProgress = useCallback(
    (progressEvent) => {
      progressEvent.loaded && setLoadedSize(progressEvent.loaded);
    },
    [setLoadedSize]
  );

  const downloadAssets = () => {
    if (!uuid || !contact_uuid) return;
    setDownloadLoading(true);
    setShowProgress(true);
    setLoadedSize(0);
    setProgressBarProps({ ...progressBarProps, inProgress: true, complete: false, error: false, message: undefined });
    client
      .GETshareDownload(uuid, contact_uuid, onDownloadProgress)
      .then(({ data }) => {
        setProgressBarProps({ ...progressBarProps, inProgress: false, complete: true, message: t.progressBar.download_is_finished });
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `werbenet-download-assets-${format(Date.now(), 'yyyy-MM-dd_HH-mm-ss')}.zip`); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();

        var _paq = (window._paq = window._paq || []);
        responseData.assets.forEach((asset) => {
          const customDimensions = getAssetCustomDimensionsDownload(asset.as_number, asset.name, asset.category_name, asset.fields);
          let dimensions = {};
          customDimensions.forEach((dim) => {
            dimensions[`dimension${dim.id}`] = dim.value;
          });
          _paq.push(['trackEvent', 'Download via E-Mail', `${BACKEND_BASE_URL}/admin/assets/${asset.id}`, asset.name, 1, { ...dimensions }]);
        });
      })
      .catch((error) => {
        setProgressBarProps({ ...progressBarProps, inProgress: false, error: true, message: t.progressBar.download_failed });
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const pageContainer = () => (
    <PageContainer>
      <HeaderContainer>
        <h2>{t.title[responseData.statusCode]}</h2>
      </HeaderContainer>
      <div className="info font-bold">
        <p>
          {responseData.statusCode === 200 && (
            <Trans id="download-body" {...{ value: t.body[200], size: (responseData.byte_size / 1048576).toFixed(2) }} />
          )}
          {responseData.statusCode === 500 && t.body[500]}
        </p>
      </div>
      {responseData.statusCode === 200 && (
        <React.Fragment>
          <ul>
            {responseData.assets.map((asset) => (
              <li className="list-item" key={asset.id}>
                {asset.name}
              </li>
            ))}
          </ul>
          {!progressBarProps?.inProgress && (
            <IconButton
              id={'button-download'}
              disabled={loadingDownload || responseData.assets.length === 0}
              title={t.download}
              aria-label={t.download}
              className="w-40"
              onClick={downloadAssets}
            >
              {t.download} {loadingDownload ? <Spinner size={25} /> : <BsDownload className="ml-2" />}
            </IconButton>
          )}
          <DownloadProgress
            loadedSize={loadedSize}
            progressBarProps={progressBarProps}
            showProgress={showProgress}
            hideDownloadProgress={() => setShowProgress(false)}
          ></DownloadProgress>
        </React.Fragment>
      )}
    </PageContainer>
  );

  return (
    <Page key={'page-download'}>
      <Helmet defer={false}>
        <title>{t.page_title}</title>
      </Helmet>
      <BreadCrumb
        style={{ width: 'var(--page-width)', margin: '1rem auto 1rem auto' }}
        links={
          [
            {
              title: isLoggedIn ? 'Home' : 'Werbenet Login',
              link: '/',
            },
            {
              title: t.page_title,
              link: `/download/${uuid}/${contact_uuid}`,
            },
          ].filter(Boolean) as { title: string; link: string }[]
        }
      />
      {loading ? <Spinner className="pt-8 pb-4 text-primary-500" size={60} /> : pageContainer()}
    </Page>
  );
}

const PageContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  max-width: 940px;

  .info {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  li::before {
    content: '●';
    color: var(--primary);
    font-size: 150%;
    padding: 0 2rem;
  }

  li:last-child {
    margin-bottom: 1.5rem;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    .info {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    #button-download {
      width: 100%;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    h2 {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
`;
