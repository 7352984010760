import React, { useState } from 'react';
import { Carousel } from './views/Carousel';
import { homepageStore } from './lib';
import { useAppDispatch, useAppSelector } from 'Config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Helmet } from 'react-helmet';
import { GETassets } from 'Config/client';
import { BsCartPlus, BsCartDash, BsZoomIn } from 'react-icons/bs';
import styled from 'styled-components';
import { cart } from 'Features/Cart/lib';
import { MdOutlinePrivacyTip } from 'react-icons/md';
import { useT as useTShop } from 'Features/Shop/translations';
import { useT } from './translations';
import { API_URL } from 'Env';
import defaultImgSrc from 'Assets/nopreview.png';
import { categoryStore } from 'Features/Categories';
import { useNavigate } from 'react-router-dom';
import { formattCategoryNameRoute } from 'Helpers';
import AssetPreview from 'Features/Shop/components/AssetPreview';

export default function HomePage() {
  const dispatch = useAppDispatch();
  const slides = useAppSelector(homepageStore.selectSlides);
  const [loaded, setLoaded] = useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [assets2, setAssets2] = useState<Asset[]>([]);
  const [assets3, setAssets3] = useState<Asset[]>([]);
  const [assetPreview, setAssetPreview] = useState<Asset>();
  const cartIds = useAppSelector(cart.selectAssetIdsInCart);
  const navigate = useNavigate();
  const categories = useAppSelector(categoryStore.selectCategories);

  const tShop = useTShop();
  const t = useT();
  const onSelect = (data) => {
    const assetCategory = categories.find((category) => category.id === data.category_id);
    if (assetCategory) navigate(`/shop/${formattCategoryNameRoute(assetCategory.title)}?id=${data.id}`);
  };

  React.useEffect(() => {
    setLoaded(false);
    dispatch(homepageStore.runGetSliderSlides()).finally(() => setLoaded(true));
    GETassets({ sort: '-updated_at' }).then((res) => setAssets(res.data.assets));
    GETassets({ sort: '-created_at' }).then((res) => setAssets2(res.data.assets));
    GETassets({ sort: '-downloads_count' }).then((res) => setAssets3(res.data.assets));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ArticleElem = (r: Asset): JSX.Element => (
    <Article
      data-state={getCode(r.state.code, t.status_batch)}
      color={r.state.color}
      key={r.id}
      className="snap-center shrink-0 relative rounded-lg max-w-md w-[208px] m-4 hover:shadow-2xl transition duration-300"
    >
      {r.category?.terms_of_use && (
        <MdOutlinePrivacyTip
          size={20}
          className="absolute z-20 text-primary-500 right-4 top-4"
          title={tShop.terms_of_use.special_rules}
        ></MdOutlinePrivacyTip>
      )}
      <div className="h-44 overflow-hidden relative w-full">
        <div className="asset-preview h-44 overflow-hidden relative w-full">
          {r.alternative_image_url || r.file_preview_url ? (
            <img
              className="rounded-t-lg object-contain w-full h-full"
              src={`${API_URL.slice(0, -8)}${r.alternative_image_url ?? r.file_preview_url}`}
              onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                if (e.target.src !== defaultImgSrc) {
                  e.target.onerror = null;
                  e.target.src = defaultImgSrc;
                  e.target.style.objectFit = 'cover';
                }
              }}
              alt={`Bild ${r.name}`}
            />
          ) : (
            <img className="rounded-t-lg w-full h-full object-cover" src={defaultImgSrc} alt={`Bild ${r.name}`} />
          )}

          {(!!r.alternative_image_url || !!r.file_preview_url) && (
            <button
              className="button-zoom z-20 absolute inset-1/4 m-auto text-primary-500 opacity-0 bg-white rounded-lg p-2"
              title={tShop.zoom_image}
              aria-label={tShop.zoom_image}
              onClick={() => setAssetPreview(r)}
            >
              <BsZoomIn size={50} />
            </button>
          )}
        </div>
      </div>
      <div className="p-3 h-[80px]">
        <button
          onClick={() => onSelect(r)}
          className="w-full text-left lg:text-1xl hover:text-primary-500 cursor-pointer transition duration-200  font-bold text-gray-900 break-words"
        >
          <span className="line-clamp-3">{r.name}</span>
        </button>
      </div>
      <div className="flex z-20 justify-end items-center p-6 h-[80px]">
        {!r.category?.terms_of_use &&
          r.state.code === 'published' &&
          (cartIds.has(String(r.id)) ? (
            <button
              title={tShop.remove_from_shopping_cart}
              aria-label={tShop.remove_from_shopping_cart}
              onClick={() => dispatch(cart.removeItems([r.id]))}
              className="flex text-primary-500 bg-white justify-center text-center items-center rounded-xl p-3 border-2 border-primary-500 hover:text-primary-600"
            >
              <BsCartDash size={22} />
            </button>
          ) : (
            <button
              title={tShop.add_to_shopping_cart}
              aria-label={tShop.add_to_shopping_cart}
              onClick={() => dispatch(cart.addItems([r]))}
              className="flex text-white bg-primary-500 justify-center text-center items-center rounded-xl p-3 border-2 border-primary-500 hover:text-gray-20"
            >
              <BsCartPlus size={22} />
            </button>
          ))}
      </div>
    </Article>
  );
  return (
    <>
      <Helmet defer={false}>
        <title>Telekom Werbenet</title>
      </Helmet>
      {loaded && (slides.length === 0 ? <Carousel slides={[{ id: 1 }]} /> : <Carousel slides={slides} />)}
      <div className="container page">
        {assetPreview && <AssetPreview assetPreview={assetPreview} onClose={() => setAssetPreview(undefined)}></AssetPreview>}
        <div className="flex gap-6 flex-wrap md:flex-nowrap">
          <div className="w-3/6 flex-1">
            <h2 className="font-extrabold my-12 mx-4">{t.last_edited}</h2>
            <div className="snap-mandatory snap-x relative w-full flex gap-2 scroll-mandatory overflow-x-auto pb-14">
              {assets.map((r) => ArticleElem(r))}
            </div>
          </div>
          <div className="w-3/6 flex-1">
            <h2 className="font-extrabold my-12 mx-4">{t.last_added}</h2>
            <div className="snap-mandatory snap-x relative w-full flex gap-2 scroll-mandatory overflow-x-auto pb-14">
              {assets2.map((r) => ArticleElem(r))}
            </div>
          </div>
        </div>

        <h2 className="font-extrabold my-12 mx-4">{t.most_used}</h2>
        <div className="snap-mandatory snap-x relative w-full flex gap-2 scroll-mandatory overflow-x-auto pb-14">
          {assets3.map((r) => ArticleElem(r))}
        </div>
      </div>
    </>
  );
}

const Article = styled.article<{ color: string; state?: string }>`
  --d: 4px; /* folded part */
  --c: ${() => 'gray'}; /* color */
  --f: 12px; /* ribbon font-size */
  position: relative;
  overflow: hidden;
  box-shadow: 2px 9px 16px 5px rgba(225, 225, 225, 1);
  &[data-state]::before {
    content: attr(data-state);
    z-index: 1;
    font-size: var(--f);
    /* I : position & coloration */
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
    color: white;
    text-align: center;
    transform: translate(29.29%, -100%) rotate(45deg);
    transform-origin: bottom left;
    padding: 5px 35px calc(var(--d) + 5px);
    background: rgb(142, 142, 142);
  }

  .asset-preview {
    .button-zoom {
      width: fit-content;
      height: max-content;
      padding: 10px;
      opacity: 0;

      &:focus,
      &:hover {
        opacity: 0.9 !important;
      }
    }

    &:hover {
      .button-zoom {
        cursor: zoom-in;
        opacity: 0.6;
      }
    }
  }
`;

function getCode(r: string, t: Record<string, string>) {
  switch (r) {
    case 'publication_stopped':
      return t.publication_stopped;
    case 'publication_expired':
      return t.publication_expired;
    case 'not_published':
      return t.not_published;
    case 'draft':
      return t.draft;
    case 'require_approval':
      return t.require_approval;
    default:
      return r.includes('archived') ? t.archived : undefined;
  }
}
