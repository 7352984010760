import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import * as auth from 'Features/Authentication/lib/model';
import * as categorySlice from 'Features/Categories/lib/model';
import * as cartSlice from 'Features/Cart/lib/reducer';
import * as emailSlice from 'Features/EmailPage/lib/model';
import * as home from 'Features/HomePage/lib/model';
import * as staticPages from 'Features/StaticPages/lib/model';
import * as faqs from 'Features/Faq/lib/reducer';
import { RootState } from './store';

const combinedReducers = combineReducers({
  [auth.name]: auth.reducer,
  [home.name]: home.reducer,
  [categorySlice.name]: categorySlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [emailSlice.name]: emailSlice.reducer,
  [staticPages.name]: staticPages.reducer,
  [faqs.name]: faqs.reducer,
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    state = {};
  }

  return combinedReducers(state, action);
};
