/* eslint-disable react-hooks/exhaustive-deps */
import { CardSkeleton } from 'Components';
import { BreadCrumb } from 'Components/BreadCrumb';
import { client, useAppSelector } from 'Config';
import { categoryStore } from 'Features/Categories';
import { useQueryParams } from 'Helpers';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AssetDetails } from './AssetDetails';
import AssetsGrid, { superAdminFilter } from './AssetsGrid';
import { useT } from './translations';

interface SearchFilterParams {
  as_number?: string;
  query?: string;
  page?: number;
  per_page?: number;
  sort?: string;
  states?: string[];
}

export default function SearchPage() {
  const t = useT();
  const navigate = useNavigate();

  const asNumber = useQueryParams().get('as_number')?.toUpperCase();
  const searchAll = useQueryParams().get('query');

  const categoriesById = useAppSelector(categoryStore.selectCategoriesById);

  const [filterParams, setFilterParams] = useState<SearchFilterParams>({});
  const queryParams = new URLSearchParams(window.location.search);
  const [updateFiltersFromUrl, setUpdateFiltersFromUrl] = useState(false);
  const [assets, setAssets] = useState<AssetsResponse>();
  const [selected, setSelectedAsset] = useState<Asset | { category_id: string; id: string; _loading: true } | null>();

  const [sort, setSort] = useState<[keyof Asset | '', 'ASC' | 'DESC', string]>(['created_at', 'DESC', 'Date']);
  const [adminFilter, setAdminFilter] = useState<keyof typeof superAdminFilter>('Alle');

  const [applyNewFilter, toggleApplyNewFilter] = useState(false);

  useEffect(() => {
    const newfilterParams: SearchFilterParams = {};
    queryParams.forEach((value, key) => {
      switch (key) {
        case 'sort':
          newfilterParams.sort = value;
          setSort([value.toString().includes('created_at') ? 'created_at' : 'name', value.toString().startsWith('-') ? 'DESC' : 'ASC', 'Date']);
          break;
        case 'page':
        case 'per_page':
          newfilterParams[key] = +value;
          break;
        case 'query':
        case 'as_number':
          newfilterParams[key] = value;
          break;
        case 'states':
          if (!newfilterParams.states) {
            newfilterParams.states = [];
          }
          if (!newfilterParams.states.includes(value)) {
            newfilterParams.states.push(value);
          }
          break;
        default:
      }
    });

    const newAdminFilter = Object.entries(superAdminFilter).find(
      ([key, value]) => value && newfilterParams.states && value?.sort().join(',') === newfilterParams.states.sort().join(',')
    );
    if (newAdminFilter === undefined) {
      setAdminFilter('Alle');
    } else {
      setAdminFilter(newAdminFilter[0] as keyof typeof superAdminFilter);
    }

    setFilterParams(newfilterParams);
    setUpdateFiltersFromUrl(true);
  }, []);

  useEffect(() => {
    if (updateFiltersFromUrl) {
      fetchAssets();
    }
  }, [updateFiltersFromUrl]);

  useEffect(() => {
    if (updateFiltersFromUrl && (asNumber || searchAll)) {
      setSort(['created_at', 'DESC', 'Date']);
      setAdminFilter('Alle');

      setNewFilterParams({ ...(asNumber && { as_number: asNumber }), ...(searchAll && { query: searchAll }), sort: '-created_at', page: 1 });
    }
  }, [asNumber, searchAll]);

  useEffect(() => {
    if (applyNewFilter) {
      fetchAssets();
    }
  }, [applyNewFilter]);

  const getAssets = useCallback(
    (filter) => {
      client
        .GETassets(filter)
        .then((response) => {
          const assetCategory = {
            ...response.data,
            assets: response.data.assets.map((asset) =>
              asset.category ? asset : Object.assign({}, asset, { category: categoriesById[asset.category_id] })
            ),
          };
          setAssets(assetCategory);
        })
        .finally(() => toggleApplyNewFilter(false));
    },
    [categoriesById]
  );

  const fetchAssets = useCallback(async () => {
    const filter = {};

    if (filterParams.query) {
      filter['query'] = filterParams.query;
    }

    if (filterParams.as_number && filterParams.as_number.replace(/^0+/, '').length > 1) {
      filter['as_number'] = filterParams.as_number.replace(/^0+/, '');
    }

    if (filterParams.sort) {
      filter['sort'] = filterParams.sort;
    }

    if (filterParams.page && filterParams.page !== -1) {
      filter['page'] = filterParams.page;
    } else {
      filter['page'] = 1;
    }

    if (filterParams.states) {
      filter['states'] = filterParams.states;
    }
    setAssets(undefined);
    getAssets(filter);
    updateUrlPath();
  }, [sort, adminFilter, getAssets, filterParams]);

  const updateUrlPath = () => {
    const searchParams = new URLSearchParams();

    if (selected?.id) {
      searchParams.append('id', selected.id.toString());
    }
    Object.entries(filterParams).forEach(([key, value]) => {
      if (value === undefined || !value.length) {
        console.warn(key, value);
      } else if (Array.isArray(value)) {
        value.forEach((v) => {
          searchParams.append(key, v.toString());
        });
      } else {
        searchParams.append(key, value.toString());
      }
    });

    navigate({
      pathname: `/search`,
      search: searchParams.toString(),
    });
  };

  const setNewSelectedAsset = (asset: Asset | null | undefined) => {
    setSelectedAsset(asset);
    if (!asset) {
      queryParams.delete('id');
      navigate({
        search: queryParams.toString(),
      });
    }
  };

  const setNewFilterParams = (newFilterParams: SearchFilterParams) => {
    setFilterParams(newFilterParams);
    toggleApplyNewFilter(true);
  };

  const setPage = (newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setNewFilterParams({ ...filterParams, page: newPage });
  };

  const setNewSort = (newSort: ['' | keyof Asset, 'DESC' | 'ASC', string]) => {
    setSort(newSort);
    setNewFilterParams({ ...filterParams, sort: newSort[0] ? `${newSort[1] === 'DESC' ? '-' : ''}${newSort[0]}` : '' });
  };

  const setNewAdminFilter = (newAdminFilter: keyof typeof superAdminFilter) => {
    setAdminFilter(newAdminFilter);
    const newValue: string[] = newAdminFilter !== 'Alle' && superAdminFilter[newAdminFilter] ? Object.values(superAdminFilter[newAdminFilter]) : [];
    setNewFilterParams({ ...filterParams, states: newValue, page: 1 });
  };

  if (!asNumber && !searchAll) {
    navigate('/');
    return <></>;
  }

  return (
    <>
      <Helmet defer={false}>
        <title>{`${t.all_categories} - Werbenet`}</title>
      </Helmet>
      <div className="page main-page">
        <BreadCrumb
          className="px-4 mt-4 mb-8"
          links={
            [
              {
                title: 'Home',
                link: '/',
              },
              {
                title: t.all_categories,
                link: searchAll ? `/search?query=${searchAll}` : `/search?as_number=${asNumber}`,
              },
            ].filter(Boolean) as { title: string; link: string }[]
          }
        />
        <div className="flex w-full">
          {assets ? (
            selected ? (
              <AssetDetails onSelect={setNewSelectedAsset} data={selected} />
            ) : (
              <AssetsGrid
                title={`${t.search_for} '${searchAll || asNumber}'`}
                onSelect={setSelectedAsset}
                onAdminFilter={setNewAdminFilter}
                adminFilter={adminFilter}
                onSortChange={setNewSort}
                sort={sort}
                data={assets}
                hasFilterApplied={true}
                setPage={setPage}
              />
            )
          ) : (
            <CardSkeleton count={6} />
          )}
        </div>
      </div>
    </>
  );
}
