import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { actions } from './reducer';

export const fetch = (): AppAsyncThunk => (dispatch, _getState) =>
  client.GETfaqs().then(({ data }) => {
    dispatch(actions.setFaqs(data));
  });

export const fetchFaqCategories = (): AppAsyncThunk => (dispatch, _getState) =>
  client.GETfaq_categories().then(({ data }) => {
    dispatch(actions.setFaqCategories(data));
  });
