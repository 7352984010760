import styled from 'styled-components';
import tw from 'twin.macro';

// Language: typescript
// React component: Button
// reusable button component in react following Telecom's design system guidelines
export function Button(props) {
  return <ButtonContainer {...props}>{props.children}</ButtonContainer>;
}
export function OutlineButton(props) {
  return <OutlineButtonContainer {...props}>{props.children}</OutlineButtonContainer>;
}
export const IconButton = styled.button<{ bg?: string; color?: string; border?: string }>`
  --bgColor: ${({ bg }) => bg || 'var(--primary)'};
  --color: ${({ color }) => color || 'var(--white)'};
  --border: ${({ border }) => border || 'var(--primary)'};
  align-items: center;
  background-color: var(--bgColor);
  border: 1px solid var(--border);
  border-radius: 8px;
  color: var(--color);
  display: flex;
  flex-flow: row nowrap;
  font-size: 16px;
  height: 38px;
  justify-content: center;
  outline: none;

  &:disabled {
    ${tw`bg-primary-300 border-primary-300`}
    cursor: unset;
  }

  > svg {
    position: relative;
  }
`;

export const PrimaryButton = styled.button<{ flat?: boolean; bg?: string; color?: string }>`
  ${({ flat, bg, color }) =>
    flat
      ? `color:${bg || 'var(--primary)'};
    background-color:${color || '#FFF'};
    border: 1px solid ${color || '#FFF'};`
      : `color: ${color || '#FFF'};
    background-color:${bg || 'var(--primary)'};
    border: 1px solid ${bg || 'var(--primary)'};`}
  text-align: center;
  font-weight: 600;
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  outline: none;
  padding: 0.5em 1em;
  text-decoration: none;

  &:disabled {
    ${tw`bg-primary-300 border-primary-300`}
    cursor: unset;
  }

  > svg {
    margin-left: 0.5em;
    position: relative;
  }
`;

export const ButtonContainer = styled.button`
  background-color: var(--primary);
  padding: 0.5em 1rem;
  margin: 1em 0.5em 0 0;
  color: white;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
`;

export const OutlineButtonContainer = styled.button`
  background-color: white;
  color: var(--primary);
  padding: 0.5rem 1rem;
  margin: 1em 0 0 0;
  border-color: var(--primary);
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
`;
